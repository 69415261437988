import React from 'react';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from './ui/select';
import { useCurrency } from '@/contexts/CurrencyContext';
import { currencies } from '@/lib/currencies';

export const CurrencySelect = () => {
  const { currentCurrency, setCurrentCurrency } = useCurrency();

  const handleCurrencyChange = React.useCallback((value: string) => {
    try {
      const currency = currencies.find(c => c.code === value);
      if (!currency) {
        console.error('Invalid currency code selected:', value);
        return;
      }
      setCurrentCurrency(currency);
    } catch (error) {
      console.error('Error changing currency:', error);
    }
  }, [setCurrentCurrency]);

  return (
    <Select
      value={currentCurrency?.code || ''}
      onValueChange={handleCurrencyChange}
      defaultValue={currentCurrency?.code}
    >
      <SelectTrigger className="w-[120px] min-w-[120px] touch-manipulation">
        <SelectValue 
          placeholder="Select currency" 
          aria-label="Select currency"
        />
      </SelectTrigger>
      <SelectContent className="max-h-[300px]">
        {currencies.map((currency) => (
          <SelectItem 
            key={currency.code} 
            value={currency.code}
            className="touch-manipulation"
          >
            {currency.symbol} {currency.code}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};