import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@/hooks/useAuth';
import { Loader2 } from 'lucide-react';
import { supabase } from '@/lib/supabase';
import toast from 'react-hot-toast';

export function MagicLinkHandler() {
  const navigate = useNavigate();
  const { setUser, setSession } = useAuth();

  useEffect(() => {
    const verifyAndRedirect = async () => {
      try {
        // Get the hash fragment from the URL
        const hashParams = new URLSearchParams(window.location.hash.substring(1));
        const access_token = hashParams.get('access_token');
        const refresh_token = hashParams.get('refresh_token');

        if (!access_token || !refresh_token) {
          throw new Error('Tokens not found in URL');
        }

        // Set the session with Supabase
        const { data, error } = await supabase.auth.setSession({
          access_token,
          refresh_token,
        });

        if (error) throw error;

        // Update user and session in the auth context
        setUser(data.user);
        setSession(data.session);

        const user = data.user;
        const isAdmin = user?.email?.toLowerCase() === 'bakri@pay-souq.com';

        if (isAdmin) {
          navigate('/admin', { replace: true });
        } else {
          navigate('/dashboard', { replace: true });
        }

        toast.success('Successfully signed in');
      } catch (error) {
        console.error('Verification error:', error);
        toast.error('Authentication failed. Please try again.');
        navigate('/login', { replace: true });
      }
    };

    verifyAndRedirect();
  }, [navigate, setUser, setSession]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-b from-gray-950 via-gray-900 to-gray-950">
      <div className="text-center">
        <Loader2 className="w-8 h-8 animate-spin text-blue-500 mx-auto" />
        <p className="mt-4 text-gray-400">Verifying your credentials...</p>
      </div>
    </div>
  );
}
