import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { 
  Send, MessageSquare, 
  Building2, Users, Globe, ArrowRight, 
  Loader2
} from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { Card, CardContent } from '@/components/ui/card';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { supabase } from '@/lib/supabase';
import toast from 'react-hot-toast';

interface ContactFormData {
  name: string;
  email: string;
  company: string;
  category: string;
  message: string;
}

const initialFormData: ContactFormData = {
  name: '',
  email: '',
  company: '',
  category: '',
  message: ''
};

const supportCategories = [
  { value: 'sales', label: 'Sales Inquiry' },
  { value: 'support', label: 'Technical Support' },
  { value: 'partnership', label: 'Partnership Opportunity' },
  { value: 'billing', label: 'Billing Question' },
  { value: 'media', label: 'Media Inquiry' },
  { value: 'other', label: 'Other' }
];

export const Contact = () => {
  const [formData, setFormData] = useState<ContactFormData>(initialFormData);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    // Form validation
    if (!formData.name || !formData.email || !formData.category || !formData.message) {
      toast.error('Please fill in all required fields');
      return;
    }

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      toast.error('Please enter a valid email address');
      return;
    }

    setIsSubmitting(true);
    try {
      const { error } = await supabase
        .from('contact_messages')
        .insert({
          ...formData,
          status: 'new',
          created_at: new Date().toISOString()
        });

      if (error) throw error;

      toast.success('Message sent successfully! We\'ll get back to you soon.');
      setFormData(initialFormData);
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error('Failed to send message. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen py-8 md:py-16 bg-gray-950">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="max-w-4xl mx-auto"
        >
          <div className="text-center mb-12">
            <h1 className="text-3xl md:text-4xl font-bold mb-4 text-white">
              Let&apos;s Connect
            </h1>
            <p className="text-lg text-gray-300">
              Get in touch with our team for personalized assistance with your payment needs
            </p>
          </div>

          <div className="grid lg:grid-cols-2 gap-12">
            {/* Contact Form */}
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.2 }}
            >
              <Card className="bg-gray-900 border-gray-800">
                <CardContent className="p-6">
                  <h2 className="text-xl font-semibold mb-6 text-white">Send Us a Message</h2>
                  <form onSubmit={handleSubmit} className="space-y-6">
                    <div className="grid md:grid-cols-2 gap-4">
                      <div className="space-y-2">
                        <Input
                          placeholder="Your Name *"
                          value={formData.name}
                          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                          className="bg-gray-800 border-gray-700 text-white placeholder:text-gray-500"
                          disabled={isSubmitting}
                          required
                        />
                      </div>
                      <div className="space-y-2">
                        <Input
                          type="email"
                          placeholder="Email Address *"
                          value={formData.email}
                          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                          className="bg-gray-800 border-gray-700 text-white placeholder:text-gray-500"
                          disabled={isSubmitting}
                          required
                        />
                      </div>
                    </div>

                    <div className="grid md:grid-cols-2 gap-4">
                      <div className="space-y-2">
                        <Input
                          placeholder="Company Name"
                          value={formData.company}
                          onChange={(e) => setFormData({ ...formData, company: e.target.value })}
                          className="bg-gray-800 border-gray-700 text-white placeholder:text-gray-500"
                          disabled={isSubmitting}
                        />
                      </div>
                      <div className="space-y-2">
                        <Select
                          value={formData.category}
                          onValueChange={(value) => setFormData({ ...formData, category: value })}
                          disabled={isSubmitting}
                        >
                          <SelectTrigger className="bg-gray-800 border-gray-700 text-white">
                            <SelectValue placeholder="Select Category *" />
                          </SelectTrigger>
                          <SelectContent className="bg-gray-800 border-gray-700">
                            {supportCategories.map((category) => (
                              <SelectItem 
                                key={category.value} 
                                value={category.value}
                                className="text-white hover:bg-gray-700"
                              >
                                {category.label}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </div>
                    </div>

                    <div className="space-y-2">
                      <Textarea
                        placeholder="Your Message *"
                        value={formData.message}
                        onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                        className="min-h-[150px] bg-gray-800 border-gray-700 text-white placeholder:text-gray-500"
                        disabled={isSubmitting}
                        required
                      />
                    </div>

                    <Button 
                      type="submit" 
                      className="w-full bg-blue-600 hover:bg-blue-700 text-white disabled:bg-gray-700"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? (
                        <>
                          <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                          Sending...
                        </>
                      ) : (
                        <>
                          Send Message
                          <Send className="ml-2 w-4 h-4" />
                        </>
                      )}
                    </Button>

                    <p className="text-sm text-gray-400 text-center">
                      * Required fields
                    </p>
                  </form>
                </CardContent>
              </Card>
            </motion.div>

            {/* Contact Information */}
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.2 }}
              className="space-y-6"
            >
              <div className="grid gap-6">
                {/* Add your office locations here */}
              </div>

              <Card>
                <CardContent className="p-6">
                  <h3 className="font-semibold mb-4 text-white">Need Immediate Assistance?</h3>
                  <p className="text-gray-300 mb-4">
                    Our support team is available 24/7 for urgent matters.
                  </p>
                  <Button variant="outline" className="w-full text-white hover:text-white">
                    Start Live Chat
                    <MessageSquare className="ml-2 w-4 h-4" />
                  </Button>
                </CardContent>
              </Card>
            </motion.div>
          </div>

          {/* Additional Support Options */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4 }}
            className="mt-12 grid md:grid-cols-3 gap-6"
          >
            <Card>
              <CardContent className="p-6">
                <Users className="w-8 h-8 text-blue-400 mb-4" />
                <h3 className="font-semibold mb-2 text-white">Enterprise Support</h3>
                <p className="text-gray-300 mb-4">
                  Dedicated account management for enterprise clients
                </p>
                <Button variant="outline" className="w-full text-white hover:text-white">
                  Contact Enterprise Team
                  <ArrowRight className="ml-2 w-4 h-4" />
                </Button>
              </CardContent>
            </Card>

            <Card>
              <CardContent className="p-6">
                <Building2 className="w-8 h-8 text-purple-400 mb-4" />
                <h3 className="font-semibold mb-2 text-white">Partnership Inquiries</h3>
                <p className="text-gray-300 mb-4">
                  Explore partnership opportunities with us
                </p>
                <Button variant="outline" className="w-full text-white hover:text-white">
                  Partner With Us
                  <ArrowRight className="ml-2 w-4 h-4" />
                </Button>
              </CardContent>
            </Card>

            <Card>
              <CardContent className="p-6">
                <Globe className="w-8 h-8 text-green-400 mb-4" />
                <h3 className="font-semibold mb-2 text-white">Global Support</h3>
                <p className="text-gray-300 mb-4">
                  Support available in multiple languages
                </p>
                <Button variant="outline" className="w-full text-white hover:text-white">
                  View Support Hours
                  <ArrowRight className="ml-2 w-4 h-4" />
                </Button>
              </CardContent>
            </Card>
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
};