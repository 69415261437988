import React from 'react';
import { motion } from 'framer-motion';
import { 
  Calendar, 
  Clock, 
  User, 
  ArrowRight,
  Search,
  Filter
} from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Card, CardContent } from '@/components/ui/card';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';

const blogPosts = [
  {
    id: 1,
    title: 'The Future of Digital Payments in 2024',
    excerpt: 'Explore emerging trends in digital payments and what they mean for your business.',
    image: 'https://images.unsplash.com/photo-1563013544-824ae1b704d3?w=800',
    category: 'Industry Trends',
    author: 'Sarah Johnson',
    date: '2024-03-01',
    readTime: '5 min read',
    tags: ['Payments', 'Technology', 'Future']
  },
  {
    id: 2,
    title: 'Choosing the Right Payment Provider for Your E-commerce Business',
    excerpt: 'A comprehensive guide to selecting the perfect payment solution for your online store.',
    image: 'https://images.unsplash.com/photo-1556742049-0cfed4f6a45d?w=800',
    category: 'Guides',
    author: 'Michael Chen',
    date: '2024-02-28',
    readTime: '8 min read',
    tags: ['E-commerce', 'Guide', 'Payment Providers']
  },
  {
    id: 3,
    title: 'Understanding Payment Security Standards',
    excerpt: 'Everything you need to know about PCI DSS compliance and payment security.',
    image: 'https://images.unsplash.com/photo-1563986768609-322da13575f3?w=800',
    category: 'Security',
    author: 'Alex Thompson',
    date: '2024-02-25',
    readTime: '6 min read',
    tags: ['Security', 'Compliance', 'PCI DSS']
  }
];

const categories = [
  'All',
  'Industry Trends',
  'Guides',
  'Security',
  'Case Studies',
  'Product Updates'
];

export const Blog = () => {
  const [selectedCategory, setSelectedCategory] = React.useState('All');
  const [searchQuery, setSearchQuery] = React.useState('');

  return (
    <div className="min-h-screen py-16">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-12"
        >
          <h1 className="text-3xl md:text-4xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400">
            Payment Industry Insights
          </h1>
          <p className="text-gray-100 max-w-2xl mx-auto">
            Stay updated with the latest trends, guides, and best practices in the payment industry
          </p>
        </motion.div>

        {/* Search and Filter */}
        <div className="max-w-4xl mx-auto mb-12">
          <div className="grid md:grid-cols-2 gap-4">
            <div className="relative">
              <Search className="absolute left-3 top-1/2 -translate-y-1/2 w-4 h-4 text-gray-400" />
              <Input
                placeholder="Search articles..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="pl-10 bg-gray-900 text-gray-100 placeholder:text-gray-400"
              />
            </div>
            <Select value={selectedCategory} onValueChange={setSelectedCategory}>
              <SelectTrigger>
                <Filter className="w-4 h-4 mr-2" />
                <SelectValue placeholder="Select category" />
              </SelectTrigger>
              <SelectContent>
                {categories.map((category) => (
                  <SelectItem key={category} value={category}>
                    {category}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        </div>

        {/* Featured Post */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="mb-12"
        >
          <Card className="relative overflow-hidden bg-gray-900/50">
            <div className="absolute inset-0">
              <img
                src={blogPosts[0].image}
                alt={blogPosts[0].title}
                className="w-full h-full object-cover opacity-20"
              />
              <div className="absolute inset-0 bg-gradient-to-r from-black via-black/95 to-black/90" />
            </div>
            <CardContent className="relative p-8 md:p-12">
              <div className="grid md:grid-cols-2 gap-8 items-center">
                <div>
                  <div className="flex items-center gap-4 mb-4">
                    <span className="bg-blue-500/20 text-blue-300 px-3 py-1 rounded-full text-sm">
                      {blogPosts[0].category}
                    </span>
                    <span className="text-gray-100 text-sm flex items-center gap-1">
                      <Clock className="w-4 h-4" />
                      {blogPosts[0].readTime}
                    </span>
                  </div>
                  <h2 className="text-2xl md:text-3xl font-bold mb-4 text-gray-100">
                    {blogPosts[0].title}
                  </h2>
                  <p className="text-gray-100 mb-6">
                    {blogPosts[0].excerpt}
                  </p>
                  <div className="flex items-center gap-4 mb-6">
                    <div className="flex items-center gap-2 text-sm text-gray-100">
                      <User className="w-4 h-4" />
                      {blogPosts[0].author}
                    </div>
                    <div className="flex items-center gap-2 text-sm text-gray-100">
                      <Calendar className="w-4 h-4" />
                      {new Date(blogPosts[0].date).toLocaleDateString()}
                    </div>
                  </div>
                  <Button>
                    Read Article
                    <ArrowRight className="ml-2 w-4 h-4" />
                  </Button>
                </div>
                <div className="hidden md:block">
                  <img
                    src={blogPosts[0].image}
                    alt={blogPosts[0].title}
                    className="rounded-lg shadow-lg"
                  />
                </div>
              </div>
            </CardContent>
          </Card>
        </motion.div>

        {/* Recent Posts */}
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
          {blogPosts.slice(1).map((post, index) => (
            <motion.div
              key={post.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
            >
              <Card className="h-full bg-gray-900/50">
                <CardContent className="p-6">
                  <img
                    src={post.image}
                    alt={post.title}
                    className="w-full h-48 object-cover rounded-lg mb-6"
                  />
                  <div className="flex items-center gap-4 mb-4">
                    <span className="bg-blue-500/20 text-blue-300 px-3 py-1 rounded-full text-sm">
                      {post.category}
                    </span>
                    <span className="text-gray-100 text-sm flex items-center gap-1">
                      <Clock className="w-4 h-4" />
                      {post.readTime}
                    </span>
                  </div>
                  <h3 className="text-xl font-bold mb-2 text-gray-100">{post.title}</h3>
                  <p className="text-gray-100 mb-4">{post.excerpt}</p>
                  <div className="flex items-center gap-2 text-sm text-gray-100">
                    <User className="w-4 h-4" />
                    {post.author}
                  </div>
                </CardContent>
              </Card>
            </motion.div>
          ))}
        </div>

        {/* Newsletter */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="mt-16"
        >
          <Card className="bg-gray-900/50">
            <CardContent className="p-8 text-center">
              <h3 className="text-2xl font-bold mb-4 text-gray-100">Stay Updated</h3>
              <p className="text-gray-100 mb-6 max-w-xl mx-auto">
                Subscribe to our newsletter for the latest insights and updates in the payment industry
              </p>
              <div className="flex flex-col sm:flex-row gap-4 max-w-md mx-auto">
                <Input
                  type="email"
                  placeholder="Enter your email"
                  className="flex-grow bg-gray-800 text-gray-100 placeholder:text-gray-400"
                />
                <Button>Subscribe</Button>
              </div>
            </CardContent>
          </Card>
        </motion.div>
      </div>
    </div>
  );
};