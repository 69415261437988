import { supabase } from './supabase';

// Type definitions
interface FoloosiConfig {
  reference_token: string;
  merchant_key: string;
  onSuccess: (response: FoloosiResponse) => void;
  onError: (error: FoloosiError) => void;
  onClose: () => void;
}

interface PaymentRequest {
  amount: number;
  currency: string;
  customer_name: string;
  customer_email: string;
  customer_phone?: string;
  redirect_url: string;
}

interface FoloosiResponse {
  reference_token: string;
  transaction_id: string;
  status: string;
}

interface FoloosiError {
  message: string;
  code?: string;
}

interface VerificationData {
  status: string;
  transaction_id: string;
  amount: number;
  currency: string;
  payment_method?: string;
  customer_details?: {
    name?: string;
    email?: string;
    phone?: string;
  };
  metadata?: Record<string, unknown>;
}

// Global type declaration
declare global {
  interface Window {
    Foloosi?: {
      init: (config: FoloosiConfig) => void;
    };
  }
}

// Instead, use direct API calls
const FOLOOSI_API_BASE = 'https://api.foloosi.com/aggregatorapi/web';
const MERCHANT_KEY = import.meta.env.VITE_FOLOOSI_MERCHANT_KEY;
const SECRET_KEY = import.meta.env.VITE_FOLOOSI_SECRET_KEY;

if (!MERCHANT_KEY || !SECRET_KEY) {
  console.error('Foloosi credentials are not properly configured');
}

export const foloosiPayment = {
  createPaymentIntent: async (data: PaymentRequest) => {
    try {
      // Use Supabase Edge Function with anonymous access
      const { data: result, error } = await supabase.functions.invoke('create-foloosi-reference', {
        body: {
          amount: data.amount,
          currency: data.currency,
          customer_name: data.customer_name,
          customer_email: data.customer_email,
          customer_unique_identifier: data.customer_phone,
          // Add metadata to track anonymous transactions
          metadata: {
            is_anonymous: true,
            source: 'provider_selection'
          }
        }
      });

      if (error) throw error;
      if (!result?.reference_token) {
        throw new Error('Failed to get reference token');
      }

      return result;
    } catch (error) {
      console.error('Payment intent creation failed:', error);
      throw new Error(error instanceof Error ? error.message : 'Payment intent creation failed');
    }
  },

  verifyPayment: async (referenceToken: string) => {
    if (!referenceToken) {
      throw new Error('Reference token is required');
    }

    try {
      const response = await fetch(`${FOLOOSI_API_BASE}/verify-payment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'secret_key': SECRET_KEY
        },
        body: JSON.stringify({ reference_token: referenceToken })
      });

      const result = await response.json();

      if (!response.ok) {
        throw new Error(result.message || 'Payment verification failed');
      }

      return result.data;
    } catch (error) {
      console.error('Payment verification failed:', error);
      throw new Error(error instanceof Error ? error.message : 'Payment verification failed');
    }
  },

  initializePaymentWidget: (config: FoloosiConfig) => {
    if (typeof window === 'undefined') return;

    const loadScript = (): Promise<void> => {
      return new Promise((resolve, reject) => {
        if (window.Foloosi) {
          resolve();
          return;
        }

        const script = document.createElement('script');
        script.src = 'https://www.foloosi.com/js/foloosi-checkout.min.js';
        script.async = true;
        script.onerror = () => reject(new Error('Failed to load Foloosi script'));
        script.onload = () => resolve();
        document.body.appendChild(script);
      });
    };

    loadScript()
      .then(() => {
        window.Foloosi?.init(config);
      })
      .catch((error) => {
        console.error('Failed to initialize Foloosi widget:', error);
        config.onError({ message: 'Failed to initialize payment widget' });
      });
  },

  handlePaymentSuccess: async (response: FoloosiResponse, userId?: string) => {
    if (!response.reference_token) {
      throw new Error('Invalid payment response');
    }

    try {
      const verificationResult = await foloosiPayment.verifyPayment(response.reference_token);

      if (!verificationResult.success) {
        throw new Error('Payment verification failed');
      }

      // Create payment record without requiring user_id
      const { error } = await supabase
        .from('merchant_payments')
        .update({
          status: 'completed',
          transaction_id: response.transaction_id,
          payment_date: new Date().toISOString(),
          verification_data: verificationResult.data as VerificationData,
          updated_at: new Date().toISOString(),
          user_id: userId || null // Make user_id optional
        })
        .eq('reference_token', response.reference_token);

      if (error) {
        throw new Error(`Database update failed: ${error.message}`);
      }

      return { success: true, data: verificationResult.data };
    } catch (error) {
      console.error('Payment success handling failed:', error);
      throw new Error(error instanceof Error ? error.message : 'Payment success handling failed');
    }
  },

  // Add a new method to create initial payment record
  createInitialPaymentRecord: async (data: PaymentRequest & { reference_token: string }) => {
    try {
      const { error } = await supabase
        .from('merchant_payments')
        .insert({
          amount: data.amount,
          currency: data.currency,
          customer_name: data.customer_name,
          customer_email: data.customer_email,
          customer_phone: data.customer_phone,
          status: 'pending',
          reference_token: data.reference_token,
          metadata: {
            is_anonymous: true,
            source: 'provider_selection'
          }
        });

      if (error) throw error;
    } catch (error) {
      console.error('Failed to create initial payment record:', error);
      throw new Error(error instanceof Error ? error.message : 'Failed to create payment record');
    }
  }
};