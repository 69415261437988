import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import { CheckCircle2, XCircle, Loader2, Mail } from 'lucide-react';
import { Card, CardContent } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { handlePaymentCallback } from '@/lib/payment';
import { supabase } from '@/lib/supabase';
import toast from 'react-hot-toast';

export const PaymentCallback = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [status, setStatus] = useState<'processing' | 'success' | 'failed'>('processing');
  const [error, setError] = useState<string | null>(null);
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const processPayment = async () => {
      try {
        const result = await handlePaymentCallback(searchParams);
        
        if (result.status === 'success') {
          setStatus('success');
        } else {
          throw new Error('Payment verification failed');
        }
      } catch (error) {
        console.error('Payment callback error:', error);
        setStatus('failed');
        setError('Payment verification failed. Please contact support.');
      }
    };

    processPayment();
  }, [searchParams, navigate]);

  const handleMagicLink = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!email) {
      toast.error('Please enter your email');
      return;
    }

    try {
      setIsSubmitting(true);

      // Check if the email has a successful payment
      const { data: payments, error: paymentError } = await supabase
        .from('merchant_payments')
        .select('id, status')
        .eq('customer_email', email)
        .eq('status', 'completed')
        .limit(1);

      if (paymentError) throw paymentError;

      if (!payments || payments.length === 0) {
        toast.error("You haven't made a purchase with this email address yet.");
        return;
      }

      const { error } = await supabase.auth.signInWithOtp({
        email,
        options: {
          emailRedirectTo: 'https://cosmic-puppy-fa9917.netlify.app/business-details'
        }
      });

      if (error) throw error;

      toast.success('Magic link sent! Please check your email to continue.');
    } catch (error) {
      console.error('Magic link error:', error);
      toast.error('Failed to send magic link. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen py-16">
      <div className="container max-w-lg mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
        >
          <Card>
            <CardContent className="p-6 text-center">
              {status === 'processing' && (
                <>
                  <Loader2 className="w-16 h-16 mx-auto mb-4 text-blue-400 animate-spin" />
                  <h2 className="text-2xl font-bold mb-2">Processing Payment</h2>
                  <p className="text-gray-400">Please wait while we verify your payment...</p>
                </>
              )}

              {status === 'success' && (
                <>
                  <CheckCircle2 className="w-16 h-16 mx-auto mb-4 text-green-400" />
                  <h2 className="text-2xl font-bold mb-2">Payment Successful!</h2>
                  <p className="text-gray-400 mb-6">
                    Please enter your email to continue with the setup process.
                  </p>
                  <form onSubmit={handleMagicLink} className="space-y-4">
                    <div className="space-y-2">
                      <Label htmlFor="email">Email Address</Label>
                      <Input
                        id="email"
                        type="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                    <Button 
                      type="submit" 
                      className="w-full"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? (
                        <>
                          <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                          Sending...
                        </>
                      ) : (
                        <>
                          <Mail className="w-4 h-4 mr-2" />
                          Send Magic Link
                        </>
                      )}
                    </Button>
                  </form>
                </>
              )}

              {status === 'failed' && (
                <>
                  <XCircle className="w-16 h-16 mx-auto mb-4 text-red-400" />
                  <h2 className="text-2xl font-bold mb-2">Payment Failed</h2>
                  <p className="text-gray-400 mb-6">{error}</p>
                  <div className="space-y-2">
                    <Button 
                      variant="outline" 
                      className="w-full"
                      onClick={() => navigate('/checkout')}
                    >
                      Try Again
                    </Button>
                    <Button 
                      variant="ghost" 
                      className="w-full"
                      onClick={() => navigate('/contact')}
                    >
                      Contact Support
                    </Button>
                  </div>
                </>
              )}
            </CardContent>
          </Card>
        </motion.div>
      </div>
    </div>
  );
};