import { useEffect, useState } from 'react';
import { useAuth } from './useAuth';
import { supabase } from '@/lib/supabase';

export function useAdminAuth() {
  const { user, loading: authLoading } = useAuth();
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAdminStatus = async () => {
      if (authLoading) {
        // Wait until auth loading is finished
        return;
      }

      if (!user) {
        setLoading(false);
        setIsAdmin(false);
        return;
      }

      // First check if it's the super admin email
      if (user.email?.toLowerCase() === 'bakri@pay-souq.com') {
        setIsAdmin(true);
        setLoading(false);
        return;
      }

      // If not super admin, check admin_users table
      const { data, error } = await supabase
        .from('admin_users')
        .select('role')
        .eq('user_id', user.id)
        .single();

      if (error || !data || data.role !== 'admin') {
        setIsAdmin(false);
      } else {
        setIsAdmin(true);
      }

      setLoading(false);
    };

    checkAdminStatus();
  }, [user, authLoading]);

  return { isAdmin, loading };
}
