import { ProviderList } from '../types/index';

export const providersData: ProviderList[] = [
  {
    id: 'stripe',
    name: 'Stripe',
    rating: 'A+',
    logo: '/stripe-logo.svg',
    status: 'Active',
    region: 'Global',
    transactionFee: {
      standard: '2.9% + $0.30',
      international: '3.9% + $0.30',
      inPerson: '2.7% + $0.05',
      recurring: '2.9% + $0.30', // Added recurring fee
      fixed: '$0.30',            // Added fixed fee
      variable: '2.9%',          // Added variable fee
    },
    monthlyFee: '$0',
    features: {
      included: [
        'Global Payments',
        'Fraud Protection',
        'Instant Payouts',
        'Recurring Billing'
      ],
      optional: [
        'Advanced Fraud Tools (+0.05%)',
        'Virtual Terminal (+0.4%)'
      ]
    },
    paymentMethods: [
      'Credit/Debit Cards',
      'ACH Direct Debit',
      'Digital Wallets',
      'Buy Now Pay Later'
    ],
    setupTime: '10 minutes',
    payoutTime: '2 business days',
    supportHours: '24/7',
    cardTypes: ['Visa', 'Mastercard', 'Amex', 'Discover'],
    hasApi: true,
    hasDashboard: true,
    hasRecurringBilling: true,
    hasDisputes: true,
    idealFor: 'Tech & SaaS',
    currencies: 135,
    countries: 45,
    products: {
      pos: true,
      ecommerce: true,
      paymentLinks: true,
      invoicing: true,
      subscriptions: true,
      virtualTerminal: true
    },
    customPricing: {
      available: true,
      threshold: '$50,000'
    }
  },

  {
    id: 'square',
    name: 'Square',
    rating: 'A',
    logo: '/square-logo.svg',
    status: 'Active',
    region: 'Global',
    transactionFee: {
      standard: '2.9% + $0.30',
  international: '3.9% + $0.30',
  inPerson: '2.7% + $0.05',
  recurring: '2.9% + $0.30', // Added recurring fee
  fixed: '$0.30',            // Added fixed fee
  variable: '2.9%',          // Added variable fee
},
    monthlyFee: '$0',
    features: {
      included: [
        'POS Integration',
        'Inventory Management',
        'Team Management',
        'Invoicing'
      ],
      optional: [
        'Advanced Reporting ($15/mo)',
        'Loyalty Program ($25/mo)'
      ]
    },
    paymentMethods: [
      'Credit/Debit Cards',
      'Digital Wallets',
      'Cash App Pay',
      'Gift Cards'
    ],
    setupTime: '5 minutes',
    payoutTime: '1-2 business days',
    supportHours: '24/7',
    cardTypes: ['Visa', 'Mastercard', 'Amex', 'Discover'],
    hasApi: true,
    hasDashboard: true,
    hasRecurringBilling: true,
    hasDisputes: true,
    idealFor: 'Retail & Restaurants',
    currencies: 120,
    countries: 30,
    products: {
      pos: true,
      ecommerce: true,
      paymentLinks: true,
      invoicing: true,
      subscriptions: false,
      virtualTerminal: true
    },
    customPricing: {
      available: true,
      threshold: '$250,000'
    }
  },
  {
    id: 'paypal',
    name: 'PayPal',
    rating: 'A',
    logo: '/paypal-logo.svg',
    status: 'Active',
    region: 'Global',
    transactionFee: {
      standard: '2.9% + $0.30',
      international: '4.4% + $0.30',
      inPerson: '2.7% + $0.30',
      recurring: '2.9% + $0.30', // Added recurring fee
      fixed: '$0.30',            // Added fixed fee
      variable: '2.9%'           // Added variable fee
    },
    monthlyFee: '$0',
    features: {
      included: [
        'Buyer Protection',
        'Seller Protection',
        'Mass Payouts',
        'Subscription Management'
      ],
      optional: [
        'Chargeback Protection (1%)',
        'Advanced Fraud Tools ($10/mo)'
      ]
    },
    paymentMethods: [
      'PayPal Balance',
      'Credit/Debit Cards',
      'Bank Transfer',
      'Venmo'
    ],
    setupTime: '15 minutes',
    payoutTime: '3-4 business days',
    supportHours: '24/7',
    cardTypes: ['Visa', 'Mastercard', 'Amex'],
    hasApi: true,
    hasDashboard: true,
    hasRecurringBilling: true,
    hasDisputes: true,
    idealFor: 'E-commerce',
    currencies: 200,
    countries: 200,
    products: {
      pos: false,
      ecommerce: true,
      paymentLinks: true,
      invoicing: true,
      subscriptions: true,
      virtualTerminal: false
    },
    customPricing: {
      available: true,
      threshold: '$100,000'
    }
  }
];
