import { useEffect, useState, useCallback, useRef } from 'react';
import { PostgrestError, SupabaseClient } from '@supabase/supabase-js';
import { supabase } from '@/lib/supabase';
import { Database } from '@/types/supabase';

type TableName = keyof Database['public']['Tables'];

interface UseSupabaseOptions<T extends TableName, R> {
  table: T;
  query: (
    query: ReturnType<SupabaseClient<Database>['from']>
  ) => Promise<{
    data: R;
    error: PostgrestError | null;
  }>;
  deps?: unknown[];
}

export function useSupabase<T extends TableName, R>({
  table,
  query,
  deps = [],
}: UseSupabaseOptions<T, R>) {
  const [data, setData] = useState<R | null>(null);
  const [error, setError] = useState<PostgrestError | null>(null);
  const [loading, setLoading] = useState(true);
  
  // Store the query function in a ref to prevent unnecessary re-renders
  const queryRef = useRef(query);
  queryRef.current = query;

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      const queryBuilder = supabase.from(table);
      const { data, error } = await queryRef.current(queryBuilder);
      if (error) throw error;
      setData(data);
    } catch (error) {
      setError(error as PostgrestError);
      // Ensure data is cleared on error
      setData(null);
    } finally {
      setLoading(false);
    }
  }, [table]); // Now deps only include table

  useEffect(() => {
    let mounted = true;

    const execute = async () => {
      if (mounted) {
        await fetchData();
      }
    };

    execute();

    return () => {
      mounted = false;
    };
  }, [fetchData, ...deps]);

  return { data, error, loading, refetch: fetchData };
}
