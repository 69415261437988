import React, { useState } from 'react';
import {
  Users,
  ArrowUpRight,
  ArrowDownRight,
  DollarSign,
  CreditCard,
  Building2,
  RefreshCw,
  BarChart3,
  Settings,
  Search,
  Filter,
  MessageSquare,
  FileText,
  Loader2,
  AlertCircle
} from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '@/components/ui/tabs';
import { AdminProviderList } from '@/components/admin/AdminProviderList';
import { AdminPurchaseList } from '@/components/admin/AdminPurchaseList';
import { AdminAnalytics } from '@/components/admin/AdminAnalytics';
import { AdminSubmissions } from '@/components/admin/AdminSubmissions';
import { AdminMessages } from '@/components/admin/AdminMessages';
import { BlogList } from '@/components/admin/BlogList';
import { BlogEditor } from '@/components/admin/BlogEditor';
import { DailyAnalyticsData, useGetDailyAnalyticsQuery } from '@/store/api/rootApi';
import { BlogPost, useCreateBlogPostMutation, useUpdateBlogPostMutation } from '@/store/api/rootApi';
import toast from 'react-hot-toast';

export const AdminDashboard = () => {
  const [activeTab, setActiveTab] = useState('overview');
  const [searchQuery, setSearchQuery] = useState('');
  const [dateFilter, setDateFilter] = useState('all');
  const [editingPostId, setEditingPostId] = useState<string | null>(null);
  const [isCreatingPost, setIsCreatingPost] = useState(false);

  const [createPost] = useCreateBlogPostMutation();
  const [updatePost] = useUpdateBlogPostMutation();

  const { data: analytics, isLoading, error } = useGetDailyAnalyticsQuery({
    start: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
    end: new Date().toISOString().split('T')[0]
  });

  const stats = React.useMemo(() => {
    if (!analytics || !Array.isArray(analytics)) return {
      totalRevenue: 0,
      newBusinesses: 0,
      providerSwitches: 0,
      activeProviders: 0
    };

    return {
      totalRevenue: analytics.reduce((sum: number, day: DailyAnalyticsData) => 
        sum + (Number(day.total_revenue) || 0), 0),
      newBusinesses: analytics.reduce((sum: number, day: DailyAnalyticsData) => 
        sum + (Number(day.new_businesses) || 0), 0),
      providerSwitches: analytics.reduce((sum: number, day: DailyAnalyticsData) => 
        sum + (Number(day.provider_switches) || 0), 0),
      activeProviders: analytics[analytics.length - 1]?.active_providers || 0
    };
  }, [analytics]);

  const handleBlogSubmit = async (data: Partial<BlogPost>) => {
    const loadingToastId = toast.loading('Saving post...');
    
    try {
      if (editingPostId) {
        await updatePost({ id: editingPostId, data }).unwrap();
        toast.success('Post updated successfully', { id: loadingToastId });
      } else {
        await createPost(data).unwrap();
        toast.success('Post created successfully', { id: loadingToastId });
      }
      
      setEditingPostId(null);
      setIsCreatingPost(false);
    } catch (error) {
      toast.error(
        error instanceof Error 
          ? error.message 
          : 'Failed to save post. Please try again.',
        { id: loadingToastId }
      );
      console.error('Blog submission error:', error);
    }
  };

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gray-950 flex items-center justify-center">
        <div className="text-center space-y-4">
          <Loader2 className="w-8 h-8 animate-spin text-blue-400 mx-auto" />
          <p className="text-gray-300">Loading dashboard data...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-950 flex items-center justify-center">
        <div className="text-center space-y-4 max-w-md mx-auto px-4">
          <AlertCircle className="w-12 h-12 text-red-400 mx-auto" />
          <h2 className="text-xl font-semibold text-white">Failed to Load Dashboard</h2>
          <p className="text-gray-300">
            {error instanceof Error 
              ? error.message 
              : 'There was an error loading the dashboard data. Please try again later.'}
          </p>
          <Button 
            onClick={() => window.location.reload()}
            className="bg-blue-600 hover:bg-blue-700 text-white"
          >
            <RefreshCw className="w-4 h-4 mr-2" />
            Retry
          </Button>
        </div>
      </div>
    );
  }

  if (!analytics) {
    return (
      <div className="min-h-screen bg-gray-950 flex items-center justify-center">
        <div className="text-center space-y-4 max-w-md mx-auto px-4">
          <AlertCircle className="w-12 h-12 text-yellow-400 mx-auto" />
          <h2 className="text-xl font-semibold text-white">No Data Available</h2>
          <p className="text-gray-300">
            There is currently no analytics data available to display.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen py-4 md:py-8 bg-gray-950">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-6 md:mb-8 gap-4">
          <div>
            <h1 className="text-2xl md:text-3xl font-bold mb-2 text-white">Admin Dashboard</h1>
            <p className="text-sm md:text-base text-gray-300">Manage providers and monitor platform activity</p>
          </div>
          <Button className="w-full md:w-auto bg-blue-600 hover:bg-blue-700 text-white">
            <Settings className="w-4 h-4 mr-2" />
            Settings
          </Button>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 md:gap-6 mb-6 md:mb-8">
          <Card className="bg-gray-900 border-gray-800">
            <CardContent className="p-4 md:p-6">
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-sm text-gray-300 mb-1">Total Revenue</p>
                  <h3 className="text-xl md:text-2xl font-bold text-white">
                    ${typeof stats.totalRevenue === 'number' ? stats.totalRevenue.toFixed(2) : '0.00'}
                  </h3>
                </div>
                <div className="bg-blue-500/10 p-2 md:p-3 rounded-full">
                  <DollarSign className="w-5 h-5 md:w-6 md:h-6 text-blue-400" />
                </div>
              </div>
              <div className="flex items-center mt-3 md:mt-4 text-xs md:text-sm">
                <ArrowUpRight className="w-3 h-3 md:w-4 md:h-4 text-green-400 mr-1" />
                <span className="text-green-400">12%</span>
                <span className="text-gray-300 ml-2">vs last month</span>
              </div>
            </CardContent>
          </Card>

          <Card className="bg-gray-900 border-gray-800">
            <CardContent className="p-4 md:p-6">
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-sm text-gray-300 mb-1">New Businesses</p>
                  <h3 className="text-xl md:text-2xl font-bold text-white">
                    {typeof stats.newBusinesses === 'number' ? stats.newBusinesses : 0}
                  </h3>
                </div>
                <div className="bg-green-500/10 p-2 md:p-3 rounded-full">
                  <Building2 className="w-5 h-5 md:w-6 md:h-6 text-green-400" />
                </div>
              </div>
              <div className="flex items-center mt-3 md:mt-4 text-xs md:text-sm">
                <ArrowUpRight className="w-3 h-3 md:w-4 md:h-4 text-green-400 mr-1" />
                <span className="text-green-400">8%</span>
                <span className="text-gray-300 ml-2">vs last month</span>
              </div>
            </CardContent>
          </Card>

          <Card className="bg-gray-900 border-gray-800">
            <CardContent className="p-4 md:p-6">
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-sm text-gray-300 mb-1">Provider Switches</p>
                  <h3 className="text-xl md:text-2xl font-bold text-white">
                    {typeof stats.providerSwitches === 'number' ? stats.providerSwitches : 0}
                  </h3>
                </div>
                <div className="bg-yellow-500/10 p-2 md:p-3 rounded-full">
                  <RefreshCw className="w-5 h-5 md:w-6 md:h-6 text-yellow-400" />
                </div>
              </div>
              <div className="flex items-center mt-3 md:mt-4 text-xs md:text-sm">
                <ArrowDownRight className="w-3 h-3 md:w-4 md:h-4 text-red-400 mr-1" />
                <span className="text-red-400">3%</span>
                <span className="text-gray-300 ml-2">vs last month</span>
              </div>
            </CardContent>
          </Card>

          <Card className="bg-gray-900 border-gray-800">
            <CardContent className="p-4 md:p-6">
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-sm text-gray-300 mb-1">Active Providers</p>
                  <h3 className="text-xl md:text-2xl font-bold text-white">{stats.activeProviders}</h3>
                </div>
                <div className="bg-purple-500/10 p-2 md:p-3 rounded-full">
                  <CreditCard className="w-5 h-5 md:w-6 md:h-6 text-purple-400" />
                </div>
              </div>
              <div className="flex items-center mt-3 md:mt-4 text-xs md:text-sm">
                <ArrowUpRight className="w-3 h-3 md:w-4 md:h-4 text-green-400 mr-1" />
                <span className="text-green-400">2</span>
                <span className="text-gray-300 ml-2">new this month</span>
              </div>
            </CardContent>
          </Card>
        </div>

        <Card className="bg-gray-900 border-gray-800">
          <CardHeader>
            <div className="flex flex-col md:flex-row items-start md:items-center justify-between gap-4">
              <CardTitle className="text-white">Platform Management</CardTitle>
              <div className="flex flex-col sm:flex-row items-stretch sm:items-center gap-4 w-full md:w-auto">
                <div className="relative flex-grow md:flex-grow-0">
                  <Search className="absolute left-3 top-1/2 -translate-y-1/2 w-4 h-4 text-gray-400" />
                  <Input
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="pl-10 w-full md:w-[200px] bg-gray-800 border-gray-800 text-white placeholder:text-gray-400"
                  />
                </div>
                <Select value={dateFilter} onValueChange={setDateFilter}>
                  <SelectTrigger className="w-full md:w-[150px] bg-gray-800 border-gray-800 text-white">
                    <Filter className="w-4 h-4 mr-2" />
                    <SelectValue placeholder="Filter by date" />
                  </SelectTrigger>
                  <SelectContent className="bg-gray-800 border-gray-800">
                    <SelectItem value="all" className="text-white">All Time</SelectItem>
                    <SelectItem value="today" className="text-white">Today</SelectItem>
                    <SelectItem value="week" className="text-white">This Week</SelectItem>
                    <SelectItem value="month" className="text-white">This Month</SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </div>
          </CardHeader>
          <CardContent>
            <Tabs value={activeTab} onValueChange={setActiveTab} className="w-full">
              <TabsList className="bg-gray-800 w-full overflow-x-auto flex-nowrap whitespace-nowrap">
                <TabsTrigger value="overview" className="text-white data-[state=active]:bg-gray-700">
                  <BarChart3 className="w-4 h-4 mr-2" />
                  <span className="hidden sm:inline">Overview</span>
                </TabsTrigger>
                <TabsTrigger value="providers" className="text-white data-[state=active]:bg-gray-700">
                  <CreditCard className="w-4 h-4 mr-2" />
                  <span className="hidden sm:inline">Providers</span>
                </TabsTrigger>
                <TabsTrigger value="purchases" className="text-white data-[state=active]:bg-gray-700">
                  <DollarSign className="w-4 h-4 mr-2" />
                  Purchases
                </TabsTrigger>
                <TabsTrigger value="submissions" className="text-white data-[state=active]:bg-gray-700">
                  <Users className="w-4 h-4 mr-2" />
                  Submissions
                </TabsTrigger>
                <TabsTrigger value="messages" className="text-white data-[state=active]:bg-gray-700">
                  <MessageSquare className="w-4 h-4 mr-2" />
                  Messages
                </TabsTrigger>
                <TabsTrigger value="blog" className="text-white data-[state=active]:bg-gray-700">
                  <FileText className="w-4 h-4 mr-2" />
                  Blog
                </TabsTrigger>
              </TabsList>

              <div className="mt-4">
                <TabsContent value="overview">
                  <AdminAnalytics />
                </TabsContent>

                <TabsContent value="providers">
                  <AdminProviderList searchQuery={searchQuery} />
                </TabsContent>

                <TabsContent value="purchases">
                  <AdminPurchaseList
                    searchQuery={searchQuery}
                    dateFilter={dateFilter}
                  />
                </TabsContent>

                <TabsContent value="submissions">
                  <AdminSubmissions
                    searchQuery={searchQuery}
                    dateFilter={dateFilter}
                  />
                </TabsContent>

                <TabsContent value="messages">
                  <AdminMessages
                    searchQuery={searchQuery}
                    dateFilter={dateFilter}
                  />
                </TabsContent>

                <TabsContent value="blog">
                  {editingPostId || isCreatingPost ? (
                    <BlogEditor
                      onSubmit={(data: Partial<BlogPost>) => handleBlogSubmit(data)}
                      onCancel={() => {
                        setEditingPostId(null);
                        setIsCreatingPost(false);
                      }}
                    />
                  ) : (
                    <BlogList
                      onEdit={setEditingPostId}
                      onNew={() => setIsCreatingPost(true)}
                    />
                  )}
                </TabsContent>
              </div>
            </Tabs>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default AdminDashboard;