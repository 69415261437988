import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { CreditCard, Shield, Lock, Loader2, RefreshCw, HeadphonesIcon } from 'lucide-react';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Button } from '@/components/ui/button';
import { useCurrency } from '@/contexts/CurrencyContext';
import { convertAmount, formatCurrency } from '@/lib/currencies';
import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import Foloosi from 'react-foloosi-widget';
import { supabase } from '@/lib/supabase';
interface Provider {
  id: string;
  name: string;
}

interface LocationState {
  provider?: Provider;
  isNewBusiness?: boolean;
}

interface CustomerInfo {
  name: string;
  email: string;
  phone?: string;
}

export const Checkout: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { currentCurrency } = useCurrency();

  const locationState = location.state as LocationState | undefined;
  const provider = locationState?.provider;
  const isNewBusiness = locationState?.isNewBusiness ?? false;

  // Redirect if no provider is selected
  useEffect(() => {
    if (!provider) {
      toast.error('No provider selected. Redirecting to providers page.');
      navigate('/providers');
    }
  }, [provider, navigate]);

  const basePrice = isNewBusiness ? 29 : 49;

  const [price, setPrice] = useState<number>(basePrice);
  const [isLoadingPrice, setIsLoadingPrice] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const [referenceToken, setReferenceToken] = useState<string>('');
  const [foloosiOpen, setFoloosiOpen] = useState<boolean>(false);

  useEffect(() => {
    const fetchPrice = async () => {
      setIsLoadingPrice(true);
      try {
        const convertedPrice = await convertAmount(
          basePrice,
          'USD',
          currentCurrency.code
        );
        setPrice(convertedPrice);
      } catch (error) {
        console.error('Error converting price:', error);
        toast.error('Failed to calculate price. Please try again.');
      } finally {
        setIsLoadingPrice(false);
      }
    };

    fetchPrice();
  }, [basePrice, currentCurrency.code]);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm<CustomerInfo>({
    mode: 'onChange'
  });

  const onSubmit = async (formData: CustomerInfo) => {
    try {
      setIsProcessing(true);
      
      const payload = {
        amount: price,  // No need to convert to cents in this case
        currency: currentCurrency.code,
        customer_name: formData.name,
        customer_email: formData.email,
        customer_mobile: formData.phone || '',
        customer_unique_identifier: formData.email // Added this from your previous project
      };

      console.log('Sending payload:', payload);

      const { data, error } = await supabase.functions.invoke('create-payment', {
        body: payload
      });

      if (error) throw error;

      if (!data?.reference_token) {
        throw new Error('No reference token received');
      }

      setReferenceToken(data.reference_token);
      setFoloosiOpen(true);

    } catch (error) {
      console.error('Payment failed:', error);
      toast.error('Payment failed. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  };

  // Add success and failure handlers
  const onSuccess = () => {
    toast.success('Payment successful!');
    // Navigate to success page or handle success case
    navigate('/success');
  };

  const onFailure = (error: any) => {
    toast.error('Payment failed. Please try again.');
    console.error('Payment error:', error);
  };

  if (!provider) {
    toast.error('No provider selected. Redirecting to providers page.');
    navigate('/providers');
    return null;
  }

  return (
    <div className="min-h-screen py-8 md:py-16 bg-gray-950">
      <div className="container max-w-4xl mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-12"
        >
          <h1 className="text-3xl md:text-4xl font-bold mb-4 text-white">
            Complete Your Payment
          </h1>
          <p className="text-gray-400">
            {isNewBusiness
              ? 'Setup fee for new business consultation'
              : 'Provider switching consultation fee'}
          </p>
        </motion.div>

        <Card className="bg-gray-900 border-gray-800">
          <CardHeader>
            <CardTitle className="text-white">Payment Details</CardTitle>
            <CardDescription className="text-gray-400">
              Selected Provider: <span className="text-blue-400">{provider.name}</span>
            </CardDescription>
          </CardHeader>
          <CardContent>
            {isLoadingPrice ? (
              <div className="flex items-center justify-center py-8 text-gray-400">
                <Loader2 className="w-6 h-6 animate-spin mr-2" />
                <span>Calculating price...</span>
              </div>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="space-y-6">
                  <div className="space-y-4">
                    <div>
                      <Label htmlFor="name" className="text-gray-200">Full Name *</Label>
                      <Input
                        id="name"
                        {...register('name', {
                          required: 'Full Name is required',
                          minLength: {
                            value: 2,
                            message: 'Name must be at least 2 characters',
                          },
                        })}
                        className="bg-gray-800 border-gray-700 text-white placeholder:text-gray-500 focus:ring-blue-600 focus:border-blue-600"
                        placeholder="Enter your full name"
                      />
                      {errors.name && (
                        <p className="text-red-400 text-sm mt-1">
                          {errors.name.message}
                        </p>
                      )}
                    </div>

                    <div>
                      <Label htmlFor="email" className="text-gray-200">Email Address *</Label>
                      <Input
                        id="email"
                        type="email"
                        {...register('email', {
                          required: 'Email is required',
                          pattern: {
                            value: /\S+@\S+\.\S+/,
                            message: 'Please enter a valid email address',
                          },
                        })}
                        className="bg-gray-800 border-gray-700 text-white placeholder:text-gray-500 focus:ring-blue-600 focus:border-blue-600"
                        placeholder="Enter your email address"
                      />
                      {errors.email && (
                        <p className="text-red-400 text-sm mt-1">
                          {errors.email.message}
                        </p>
                      )}
                    </div>

                    <div>
                      <Label htmlFor="phone" className="text-gray-200">Phone Number (Optional)</Label>
                      <Input
                        id="phone"
                        type="tel"
                        {...register('phone')}
                        className="bg-gray-800 border-gray-700 text-white placeholder:text-gray-500 focus:ring-blue-600 focus:border-blue-600"
                        placeholder="Enter your phone number"
                      />
                    </div>
                  </div>

                  <div className="border-t border-gray-800 pt-6">
                    <div className="flex justify-between items-center mb-6">
                      <span className="text-lg text-gray-300">Total Amount:</span>
                      <span className="text-2xl font-bold text-white">
                        {formatCurrency(price, currentCurrency.code)}
                      </span>
                    </div>

                    <div className="space-y-4 mb-6">
                      <div className="flex items-center gap-2 text-sm text-gray-400">
                        <Shield className="w-4 h-4 text-blue-400" />
                        <span>Secure Payment Processing</span>
                      </div>
                      <div className="flex items-center gap-2 text-sm text-gray-400">
                        <Lock className="w-4 h-4 text-blue-400" />
                        <span>256-bit SSL Encryption</span>
                      </div>
                      <div className="flex items-center gap-2 text-sm text-gray-400">
                        <CreditCard className="w-4 h-4 text-blue-400" />
                        <span>Multiple Payment Methods Accepted</span>
                      </div>
                    </div>

                    <Button 
                      type="submit"
                      className="w-full bg-blue-600 hover:bg-blue-700 text-white disabled:bg-gray-700 disabled:text-gray-400"
                      disabled={!isValid || isProcessing}
                    >
                      {isProcessing ? (
                        <>
                          <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                          Processing...
                        </>
                      ) : (
                        'Pay Now'
                      )}
                    </Button>

                    <p className="text-sm text-gray-400 text-center mt-4">
                      By proceeding, you agree to our terms of service and privacy policy
                    </p>
                  </div>
                </div>
              </form>
            )}
          </CardContent>
        </Card>

        {/* Additional Information */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
          className="mt-8 grid md:grid-cols-3 gap-6"
        >
          <Card className="bg-gray-900 border-gray-800">
            <CardContent className="p-6">
              <div className="flex items-center gap-3 mb-3">
                <Shield className="w-5 h-5 text-blue-400" />
                <h3 className="text-white font-semibold">Secure Process</h3>
              </div>
              <p className="text-gray-400 text-sm">
                Your payment and personal information are protected by industry-standard security measures
              </p>
            </CardContent>
          </Card>

          <Card className="bg-gray-900 border-gray-800">
            <CardContent className="p-6">
              <div className="flex items-center gap-3 mb-3">
                <RefreshCw className="w-5 h-5 text-blue-400" />
                <h3 className="text-white font-semibold">Money-Back Guarantee</h3>
              </div>
              <p className="text-gray-400 text-sm">
                If we can't find you a better deal, we'll refund your consultation fee
              </p>
            </CardContent>
          </Card>

          <Card className="bg-gray-900 border-gray-800">
            <CardContent className="p-6">
              <div className="flex items-center gap-3 mb-3">
                <HeadphonesIcon className="w-5 h-5 text-blue-400" />
                <h3 className="text-white font-semibold">24/7 Support</h3>
              </div>
              <p className="text-gray-400 text-sm">
                Our team is available around the clock to assist you with any questions
              </p>
            </CardContent>
          </Card>
        </motion.div>
        {referenceToken && (
          <Foloosi
            foloosiOpen={foloosiOpen}
            foloosiClose={true}
            onClose={() => {
              setFoloosiOpen(false);
              setReferenceToken('');
            }}
            reference_token={referenceToken}
            merchant_key='test_$2y$10$7fm8LGQW0NQuC7KrwUyeWuTXp48qB7LLXKX5xFs7ryMTd1DGZLr0m'
            redirect={false}
            onSuccess={onSuccess}
            onFailure={onFailure}
            // Add state to track payment status
            state="payment_page"
          />
        )}
      </div>
    </div>
  );
};