import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useLocation, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { 
  CreditCard, 
  CheckCircle2, 
  DollarSign, 
  Plus,
  X,
  HelpCircle
} from 'lucide-react';

import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from '@/components/ui/card';
import { Badge } from '@/components/ui/badge';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '@/components/ui/tabs';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from '@/components/ui/dialog';
import { Alert, AlertTitle, AlertDescription } from '@/components/ui/alert';
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from '@/components/ui/select';
import { ComparisonTable } from '@/components/ComparisonTable';
import { providersData } from '../data/ProvidersData';
import { ProviderList } from '../types';

export const Providers: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedProvider, setSelectedProvider] = useState<ProviderList | null>(null);
  const [selectedProviders, setSelectedProviders] = useState<ProviderList[]>([]);
  const [sortCriteria, setSortCriteria] = useState('name');
  const businessData = location.state?.businessData;
  const fromOnboarding = location.state?.fromOnboarding;
  const onboardingData = location.state?.onboardingData;

  const handleProviderSelect = (provider: ProviderList, isNewBusiness: boolean = false) => {
    if (!provider?.id) {
      toast.error('Invalid provider selected');
      return;
    }

    navigate('/checkout', {
      state: {
        provider: {
          id: provider.id,
          name: provider.name
        },
        isNewBusiness,
        fromOnboarding,
        onboardingData
      }
    });
  };

  const toggleProviderComparison = (provider: ProviderList) => {
    if (selectedProviders.find(p => p.id === provider.id)) {
      setSelectedProviders(selectedProviders.filter(p => p.id !== provider.id));
    } else if (selectedProviders.length < 3) {
      setSelectedProviders([...selectedProviders, provider]);
    }
  };

  const sortedProviders = [...providersData].sort((a, b) => {
    if (sortCriteria === 'lowestFee') {
      const feeA = parseFloat(a.transactionFee.standard);
      const feeB = parseFloat(b.transactionFee.standard);
      return feeA - feeB;
    } else if (sortCriteria === 'fastestSetup') {
      const timeA = parseInt(a.setupTime);
      const timeB = parseInt(b.setupTime);
      return timeA - timeB;
    } else {
      return a.name.localeCompare(b.name);
    }
  });

  return (
    <div className="min-h-screen py-8 md:py-16 bg-gray-950">
      <div className="container mx-auto px-4">
        {fromOnboarding && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="mb-8 flex items-center gap-2"
          >
            <Alert className="bg-blue-900/20 border-blue-800 text-blue-100 flex-1">
              <AlertTitle className="text-blue-100 flex items-center gap-2">
                Personalized Recommendations
                <div className="relative" title="These are suggested based on your onboarding info.">
                  <HelpCircle className="w-4 h-4 text-blue-100" />
                  <span className="sr-only">Help information</span>
                </div>
              </AlertTitle>
              <AlertDescription className="text-blue-200">
                Based on your business profile, we've selected the best payment providers that match your needs.
              </AlertDescription>
            </Alert>
          </motion.div>
        )}

        {selectedProviders.length > 0 && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="mb-8"
          >
            <Card className="bg-gray-900 border-gray-800">
              <CardHeader>
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <CardTitle className="text-white">Selected for Comparison</CardTitle>
                    <div className="relative" title="Compare rates and features across providers">
                      <HelpCircle className="w-4 h-4 text-gray-400" />
                      <span className="sr-only">Compare information</span>
                    </div>
                  </div>
                  <Button 
                    variant="outline"
                    onClick={() => setSelectedProviders([])}
                    className="bg-gray-800 text-white border-gray-700 hover:bg-gray-700"
                  >
                    Clear All
                  </Button>
                </div>
                <CardDescription className="text-gray-400">
                  {selectedProviders.length === 3 
                    ? "Maximum providers selected" 
                    : `Select up to ${3 - selectedProviders.length} more providers to compare`}
                </CardDescription>
              </CardHeader>
              <CardContent>
                <div className="flex flex-wrap gap-4">
                  {selectedProviders.map((provider) => (
                    <div
                      key={provider.id}
                      className="flex items-center gap-2 bg-gray-800 rounded-lg px-3 py-2"
                    >
                      <span className="text-white">{provider.name}</span>
                      <button
                        onClick={() => toggleProviderComparison(provider)}
                        className="text-gray-400 hover:text-white transition-colors"
                      >
                        <X className="w-4 h-4" />
                      </button>
                    </div>
                  ))}
                </div>

                {selectedProviders.length >= 2 && (
                  <div className="mt-6">
                    <ComparisonTable providers={selectedProviders} />
                  </div>
                )}
              </CardContent>
            </Card>
          </motion.div>
        )}

        <div className="flex justify-between items-center mb-8">
          <Tabs defaultValue="all" className="w-full">
            <TabsList className="w-full justify-start mb-6 bg-gray-900">
              <TabsTrigger 
                value="all"
                className="data-[state=active]:bg-blue-600 data-[state=active]:text-white text-gray-400"
              >
                All Providers
              </TabsTrigger>
              <TabsTrigger 
                value="ecommerce"
                className="data-[state=active]:bg-blue-600 data-[state=active]:text-white text-gray-400"
              >
                E-commerce
              </TabsTrigger>
              <TabsTrigger 
                value="retail"
                className="data-[state=active]:bg-blue-600 data-[state=active]:text-white text-gray-400"
              >
                Retail
              </TabsTrigger>
              <TabsTrigger 
                value="saas"
                className="data-[state=active]:bg-blue-600 data-[state=active]:text-white text-gray-400"
              >
                SaaS
              </TabsTrigger>
            </TabsList>

            <TabsContent value="all">
              <div className="flex items-center justify-end mb-4">
                <Select value={sortCriteria} onValueChange={setSortCriteria}>
                  <SelectTrigger className="bg-gray-800 border-gray-700 text-white mr-4">
                    <SelectValue placeholder="Sort by..." />
                  </SelectTrigger>
                  <SelectContent className="bg-gray-900 border-gray-700">
                    <SelectItem value="name">Name</SelectItem>
                    <SelectItem value="lowestFee">Lowest Transaction Fee</SelectItem>
                    <SelectItem value="fastestSetup">Fastest Setup Time</SelectItem>
                  </SelectContent>
                </Select>
              </div>

              <div className="grid gap-6">
                {sortedProviders.map((provider: ProviderList) => (
                  <Card key={provider.id} className="bg-gray-900 border-gray-800 overflow-hidden">
                    <CardContent className="p-6">
                      <div className="grid md:grid-cols-6 gap-6 items-center">
                        <div className="md:col-span-2">
                          <div className="flex items-center gap-4">
                            <div className="w-12 h-12 bg-gray-800 rounded-lg flex items-center justify-center">
                              <CreditCard className="w-6 h-6 text-blue-400" />
                            </div>
                            <div>
                              <div className="flex items-center gap-2">
                                <h3 className="text-lg font-semibold text-white">{provider.name}</h3>
                                <Badge variant="outline" className="bg-blue-600/10 text-blue-400 border-blue-500">
                                  {provider.rating}
                                </Badge>
                              </div>
                              <p className="text-sm text-gray-400">Best for {provider.idealFor}</p>
                            </div>
                          </div>
                        </div>

                        <div className="md:col-span-2">
                          <div className="space-y-1">
                            <div className="flex items-center gap-2">
                              <DollarSign className="w-4 h-4 text-green-400" />
                              <span className="text-sm text-gray-400">Standard Rate:</span>
                            </div>
                            <p className="font-semibold text-white">{provider.transactionFee.standard}</p>
                          </div>
                        </div>

                        <div className="md:col-span-1">
                          <div className="flex flex-col gap-1">
                            {provider.features.included.slice(0, 2).map((feature: string) => (
                              <div key={feature} className="flex items-center gap-2 text-sm">
                                <CheckCircle2 className="w-4 h-4 text-green-400" />
                                <span className="text-gray-300">{feature}</span>
                              </div>
                            ))}
                            {provider.features.included.length > 2 && (
                              <span className="text-sm text-gray-500">
                                +{provider.features.included.length - 2} more
                              </span>
                            )}
                          </div>
                        </div>

                        <div>
                          <Button 
                            onClick={() => handleProviderSelect(provider, !businessData?.existingProvider)}
                            className="w-full mb-2 bg-blue-600 hover:bg-blue-700 text-white"
                          >
                            Select Provider
                          </Button>
                          <Button 
                            variant="outline" 
                            className="w-full bg-gray-800 text-white border-gray-700 hover:bg-gray-700"
                            onClick={() => toggleProviderComparison(provider)}
                            disabled={selectedProviders.length >= 3 && !selectedProviders.find(p => p.id === provider.id)}
                          >
                            {selectedProviders.find(p => p.id === provider.id) ? (
                              <>
                                <X className="w-4 h-4 mr-2" />
                                Remove
                              </>
                            ) : (
                              <>
                                <Plus className="w-4 h-4 mr-2" />
                                Compare
                              </>
                            )}
                          </Button>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                ))}
              </div>
            </TabsContent>
          </Tabs>
        </div>
      </div>

      <Dialog open={!!selectedProvider} onOpenChange={() => setSelectedProvider(null)}>
        <DialogContent className="bg-gray-900 border-gray-800">
          <DialogHeader>
            <DialogTitle className="text-white">{selectedProvider?.name} Details</DialogTitle>
            <DialogDescription className="text-gray-400">
              Complete information about rates, features, and requirements
            </DialogDescription>
          </DialogHeader>

          {selectedProvider && (
            <div className="grid gap-6">
              <div>
                <h4 className="font-semibold mb-4 text-white">Transaction Rates</h4>
                <div className="grid md:grid-cols-3 gap-4">
                  {Object.entries(selectedProvider.transactionFee).map(([type, rate]) => (
                    <Card key={type} className="bg-gray-800 border-gray-700">
                      <CardContent className="p-4">
                        <h5 className="text-sm text-gray-400 mb-1">
                          {type.charAt(0).toUpperCase() + type.slice(1)}
                        </h5>
                        <p className="font-semibold text-white">{rate}</p>
                      </CardContent>
                    </Card>
                  ))}
                </div>
              </div>

              <div>
                <h4 className="font-semibold mb-4 text-white">Features</h4>
                <div className="grid md:grid-cols-2 gap-6">
                  <div>
                    <h5 className="text-sm text-gray-400 mb-2">Included</h5>
                    <div className="space-y-2">
                      {selectedProvider.features.included.map((feature) => (
                        <div key={feature} className="flex items-center gap-2">
                          <CheckCircle2 className="w-4 h-4 text-green-400" />
                          <span className="text-gray-300">{feature}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex justify-end gap-4">
                <Button 
                  variant="outline" 
                  onClick={() => setSelectedProvider(null)}
                  className="bg-gray-800 text-white border-gray-700 hover:bg-gray-700"
                >
                  Close
                </Button>
                <Button 
                  onClick={() => {
                    setSelectedProvider(null);
                    handleProviderSelect(selectedProvider, !businessData?.existingProvider);
                  }}
                  className="bg-blue-600 hover:bg-blue-700 text-white"
                >
                  Get Started
                </Button>
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};