import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { 
  Facebook, 
  Twitter, 
  Instagram, 
  Linkedin, 
  CreditCard,
  Shield,
  Globe,
  Mail
} from 'lucide-react';
import { Button } from './ui/button';
import { Input } from './ui/input';
import { toast } from 'react-hot-toast';

const footerSections = [
  {
    title: 'Partners',
    links: [
      { name: 'Payment Providers', href: '/providers' },
      { name: 'Become a Partner', href: '/partners' }
    ]
  },
  {
    title: 'Resources',
    links: [
      { name: 'Blog', href: '/blog' },
      { name: 'Help Center', href: '/help' }
    ]
  },
  {
    title: 'Company',
    links: [
      { name: 'Contact', href: '/contact' },
      { name: 'Pricing', href: '/pricing' }
    ]
  },
  {
    title: 'Legal',
    links: [
      { name: 'Privacy Policy', href: '/privacy' },
      { name: 'Terms of Service', href: '/terms' }
    ]
  }
];

const socialLinks = [
  { id: 'facebook', icon: Facebook, href: 'https://facebook.com', label: 'Facebook' },
  { id: 'twitter', icon: Twitter, href: 'https://twitter.com', label: 'Twitter' },
  { id: 'instagram', icon: Instagram, href: 'https://instagram.com', label: 'Instagram' },
  { id: 'linkedin', icon: Linkedin, href: 'https://linkedin.com', label: 'LinkedIn' }
];

export const Footer = () => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubscribe = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!email) {
      toast.error('Please enter your email address');
      return;
    }

    setIsLoading(true);
    try {
      await fetch('/api/subscribe', {
        method: 'POST',
        body: JSON.stringify({ email }),
      });
      toast.success('Successfully subscribed to newsletter');
      setEmail('');
    } catch (error) {
      toast.error('Failed to subscribe. Please try again later');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <footer className="relative overflow-hidden bg-gray-950">
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-gradient-to-t from-gray-950 via-gray-900/20 to-gray-950" />
        <div className="absolute inset-0 bg-grid-white/[0.02]" />
      </div>

      <div className="container mx-auto px-4 relative">
        {/* Newsletter Section */}
        <div className="py-12 md:py-16 border-b border-gray-800">
          <div className="max-w-4xl mx-auto text-center px-4">
            <motion.h3
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              className="text-xl md:text-3xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400"
            >
              Stay Updated with Payment Industry Trends
            </motion.h3>
            
            <form onSubmit={handleSubscribe} className="w-full">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: 0.1 }}
                className="flex flex-col sm:flex-row gap-3 justify-center items-center"
              >
                <Input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email"
                  className="w-full sm:max-w-sm bg-gray-900 border-gray-800 text-gray-100 placeholder:text-gray-500"
                  required
                  aria-label="Email address"
                />
                <Button 
                  type="submit" 
                  disabled={isLoading}
                  className="w-full sm:w-auto bg-blue-600 hover:bg-blue-700 text-white"
                >
                  {isLoading ? 'Subscribing...' : 'Subscribe'}
                  <Mail className="ml-2 w-4 h-4" />
                </Button>
              </motion.div>
            </form>
          </div>
        </div>

        {/* Main Footer Content */}
        <div className="py-12 grid md:grid-cols-2 lg:grid-cols-6 gap-8">
          {/* Brand Section */}
          <div className="lg:col-span-2">
            <Link to="/" className="block mb-6">
              <img 
                src="/images/paysouqlogo.png" 
                alt="PaySouq Logo" 
                className="h-12 w-auto"
              />
            </Link>
            <p className="text-gray-400 mb-6">
              Connecting businesses with the perfect payment solutions. Save money and grow your business with exclusive deals.
            </p>
            <div className="flex gap-4">
              {socialLinks.map(({ id, icon: Icon, href, label }) => (
                <a
                  key={id}
                  href={href}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label={label}
                  className="w-10 h-10 rounded-full bg-gray-900 flex items-center justify-center hover:bg-gray-800 transition-colors"
                >
                  <Icon className="w-5 h-5 text-gray-400" />
                </a>
              ))}
            </div>
          </div>

          {/* Links Sections */}
          {footerSections.map((section) => (
            <div key={section.title}>
              <h4 className="font-semibold mb-4 text-gray-200">{section.title}</h4>
              <ul className="space-y-3">
                {section.links.map((link) => (
                  <li key={link.name}>
                    <Link
                      to={link.href}
                      className="text-gray-400 hover:text-white transition-colors"
                    >
                      {link.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        {/* Bottom Bar */}
        <div className="py-6 border-t border-gray-800">
          <div className="flex flex-col md:flex-row justify-between items-center gap-6">
            <div className="text-gray-400 text-sm text-center md:text-left">
              © {new Date().getFullYear()} PaySouq. All rights reserved.
            </div>
            <div className="flex flex-col sm:flex-row items-center gap-4 sm:gap-6">
              <div className="flex items-center gap-2 text-gray-400">
                <Shield className="w-4 h-4" aria-hidden="true" />
                <span className="text-sm">PCI Compliant</span>
              </div>
              <div className="flex items-center gap-2 text-gray-400">
                <Globe className="w-4 h-4" aria-hidden="true" />
                <span className="text-sm">Global Coverage</span>
              </div>
              <div className="flex items-center gap-2 text-gray-400">
                <CreditCard className="w-4 h-4" aria-hidden="true" />
                <span className="text-sm">Secure Payments</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};