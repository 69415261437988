import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { 
  ChevronDown,
  MessageSquare,
  Mail,
  Phone,
  FileQuestion,
  CreditCard,
  Settings,
  Shield,
  DollarSign,
  ArrowRight
} from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';

const categories = [
  {
    icon: CreditCard,
    title: 'Payment Solutions',
    description: 'Find the best payment provider for your business needs',
    articles: [
      'Compare rates from multiple payment providers',
      'Exclusive deals for new businesses - rates from 1%',
      'Access to global payment providers and solutions'
    ]
  },
  {
    icon: Settings,
    title: 'Getting Started',
    description: 'How to find and switch payment providers',
    articles: [
      'Free consultation and provider matching',
      'Understanding provider requirements',
      'Comparing provider features'
    ]
  },
  {
    icon: Shield,
    title: 'Security & Compliance',
    description: 'Understanding payment provider security',
    articles: [
      'Provider security comparisons',
      'PCI compliance requirements',
      'Fraud protection features'
    ]
  },
  {
    icon: DollarSign,
    title: 'Pricing & Deals',
    description: 'Compare rates and special offers',
    articles: [
      'Current provider offers',
      'Special rates for new businesses',
      'Switching provider incentives'
    ]
  }
];

const faqs = [
  {
    question: 'What special offers can I get through PaySouq?',
    answer: 'Through our partnerships with leading payment providers, we secure exclusive deals for businesses. New businesses can access rates starting from 1% and potential fee waivers. We negotiate special terms based on your business needs and volume.'
  },
  {
    question: 'Which payment providers do you work with?',
    answer: 'We partner with major global and local payment providers who support all payment methods including cards, digital wallets, bank transfers, and BNPL solutions. We help you find providers that best match your business needs and location.'
  },
  {
    question: 'How do you help me get better rates?',
    answer: 'We leverage our partnerships and collective bargaining power to negotiate better rates than you might get directly. We compare offers from multiple providers and help secure special rates based on your business type and volume.'
  },
  {
    question: 'Can you help with international payments?',
    answer: 'Yes, we partner with providers offering global payment solutions. We help you find providers that support your target markets, handle multiple currencies, and meet local compliance requirements.'
  },
  {
    question: 'How does switching providers work?',
    answer: 'We make switching easy by handling the provider comparison and negotiation process. We help you find providers offering better rates and features, and many of our partners offer switching incentives and migration support. The process typically takes 1-3 business days.'
  }
];

export const HelpCentre: React.FC = () => {
  const [expandedFaq, setExpandedFaq] = useState<number | null>(null);

  return (
    <div className="min-h-screen py-16">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-12"
        >
          <h1 className="text-3xl md:text-4xl font-bold mb-4 text-white">
            How Can We Help?
          </h1>
          <p className="text-white max-w-2xl mx-auto mb-8">
            Find answers about finding the best payment provider for your business
          </p>
        </motion.div>

        {/* Help Categories */}
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6 mb-16">
          {categories.map((category, index) => (
            <motion.div
              key={category.title}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
            >
              <Card className="h-full">
                <CardContent className="p-6">
                  <div className="bg-gradient-to-r from-blue-500/10 to-purple-500/10 w-12 h-12 rounded-lg flex items-center justify-center mb-4">
                    <category.icon className="w-6 h-6 text-blue-400" />
                  </div>
                  <h3 className="font-semibold mb-2 text-white">{category.title}</h3>
                  <p className="text-sm text-white mb-4">{category.description}</p>
                  <ul className="space-y-2">
                    {category.articles.map((article) => (
                      <li key={article} className="flex items-center text-sm text-white">
                        <FileQuestion className="w-4 h-4 mr-2 text-blue-400" />
                        {article}
                      </li>
                    ))}
                  </ul>
                </CardContent>
              </Card>
            </motion.div>
          ))}
        </div>

        {/* FAQs */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="max-w-3xl mx-auto mb-16"
        >
          <Card>
            <CardHeader>
              <CardTitle className="text-white">Frequently Asked Questions</CardTitle>
            </CardHeader>
            <CardContent className="space-y-4">
              {faqs.map((faq, index) => (
                <div
                  key={index}
                  className="border-b border-white/10 last:border-0"
                >
                  <button
                    className="w-full py-4 flex items-center justify-between text-left"
                    onClick={() => setExpandedFaq(expandedFaq === index ? null : index)}
                  >
                    <span className="font-medium text-white">{faq.question}</span>
                    <ChevronDown
                      className={`w-5 h-5 text-white transition-transform ${
                        expandedFaq === index ? 'rotate-180' : ''
                      }`}
                    />
                  </button>
                  {expandedFaq === index && (
                    <motion.div
                      initial={{ height: 0, opacity: 0 }}
                      animate={{ height: 'auto', opacity: 1 }}
                      exit={{ height: 0, opacity: 0 }}
                      className="pb-4 text-white"
                    >
                      {faq.answer}
                    </motion.div>
                  )}
                </div>
              ))}
            </CardContent>
          </Card>
        </motion.div>

        {/* Contact Options */}
        <div className="grid md:grid-cols-3 gap-6 max-w-4xl mx-auto">
          {[
            {
              icon: MessageSquare,
              title: 'Live Chat',
              description: 'Chat with our support team',
              action: 'Start Chat',
              available: true
            },
            {
              icon: Mail,
              title: 'Email Support',
              description: 'Get help via email',
              action: 'Send Email',
              available: true
            },
            {
              icon: Phone,
              title: 'Phone Support',
              description: '24/7 phone assistance',
              action: 'Call Now',
              available: true
            }
          ].map((option, index) => (
            <motion.div
              key={option.title}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
            >
              <Card>
                <CardContent className="p-6 text-center">
                  <option.icon className="w-8 h-8 mx-auto mb-4 text-blue-400" />
                  <h3 className="font-semibold mb-2 text-white">{option.title}</h3>
                  <p className="text-sm text-white mb-4">{option.description}</p>
                  <Button variant="outline" className="w-full text-white">
                    {option.action}
                    <ArrowRight className="ml-2 w-4 h-4" />
                  </Button>
                </CardContent>
              </Card>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};