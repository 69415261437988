import React from 'react';
import { format } from 'date-fns';
import { Edit, Trash2, Eye, Plus, Loader2 } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import { Badge } from '@/components/ui/badge';
import { useGetBlogPostsQuery, useDeleteBlogPostMutation } from '@/store/api/rootApi';
import toast from 'react-hot-toast';

interface BlogListProps {
  onEdit: (id: string) => void;
  onNew: () => void;
}

export const BlogList: React.FC<BlogListProps> = ({ onEdit, onNew }) => {
  const { data: posts, isLoading } = useGetBlogPostsQuery();
  const [deletePost] = useDeleteBlogPostMutation();

  const handleDelete = async (id: string) => {
    if (window.confirm('Are you sure you want to delete this post?')) {
      try {
        await deletePost(id).unwrap();
        toast.success('Post deleted successfully');
      } catch (error) {
        toast.error('Failed to delete post');
      }
    }
  };

  if (isLoading) {
    return <div>Loading blog posts...</div>;
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold text-zinc-100">Blog Posts</h2>
        <Button 
          onClick={onNew}
          className="bg-zinc-900 hover:bg-zinc-800 text-zinc-100 border-zinc-800"
        >
          <Plus className="w-4 h-4 mr-2" />
          New Post
        </Button>
      </div>

      <div className="grid gap-4">
        {posts?.map((post) => (
          <Card key={post.id} className="bg-zinc-900 border-zinc-800">
            <CardContent className="p-6">
              <div className="flex items-center justify-between">
                <div>
                  <div className="flex items-center gap-4 mb-2">
                    <h3 className="font-semibold text-zinc-100">{post.title}</h3>
                    <Badge
                      variant={post.status === 'published' ? 'default' : 'secondary'}
                      className="bg-zinc-800 text-zinc-100 hover:bg-zinc-700"
                    >
                      {post.status}
                    </Badge>
                  </div>
                  <p className="text-sm text-zinc-400 mb-2">{post.excerpt}</p>
                  <div className="flex items-center gap-4 text-sm text-zinc-400">
                    <span>{post.category}</span>
                    <span>•</span>
                    <span>
                      {format(new Date(post.created_at), 'MMM d, yyyy')}
                    </span>
                  </div>
                </div>

                <div className="flex items-center gap-2">
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() => window.open(`/blog/${post.slug}`, '_blank')}
                    className="bg-zinc-900 hover:bg-zinc-800 text-zinc-100 border-zinc-800"
                  >
                    <Eye className="w-4 h-4" />
                  </Button>
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() => onEdit(post.id)}
                    className="bg-zinc-900 hover:bg-zinc-800 text-zinc-100 border-zinc-800"
                  >
                    <Edit className="w-4 h-4" />
                  </Button>
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() => handleDelete(post.id)}
                    className="bg-zinc-900 hover:bg-zinc-800 text-zinc-100 border-zinc-800"
                  >
                    <Trash2 className="w-4 h-4" />
                  </Button>
                </div>
              </div>
            </CardContent>
          </Card>
        ))}

        {!posts?.length && (
          <div className="text-center py-8 text-zinc-400">
            No blog posts found
          </div>
        )}
      </div>

      {isLoading && (
        <div className="flex justify-center py-8">
          <Loader2 className="w-6 h-6 animate-spin text-zinc-400" />
        </div>
      )}
    </div>
  );
};