import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Hero } from '../components/Hero';
import { Calculator } from '../components/Calculator';
import { ProviderCard } from '../components/ProviderCard';
import { Features } from '../components/Features';
import { Testimonials } from '../components/Testimonials';
import { providersData } from '../data/ProvidersData';
import { ProviderList } from '../types';

export const Home: React.FC = () => {
  const navigate = useNavigate();
  
  const handleCalculatorSubmit = (data: { email: string; volume: string; savings: number }) => {
    console.log('Calculator data:', data);
  };

  const handleProviderSelect = (provider: ProviderList) => {
    navigate('/providers', { 
      state: { 
        selectedProvider: provider.id,
        fromHome: true
      }
    });
  };

  return (
    <>
      <Hero />
      <Features />
      <Calculator onSubmit={handleCalculatorSubmit} />
      
      <section className="py-16 relative overflow-hidden">
        <div className="absolute inset-0">
          <div className="absolute inset-0 bg-gradient-to-b from-black via-blue-950/20 to-black" />
          <div className="absolute inset-0 bg-grid-white/[0.02]" />
        </div>
        
        <div className="container mx-auto px-4 relative">
          <h2 className="text-3xl md:text-4xl font-bold text-center mb-4 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400">
            Featured Payment Providers
          </h2>
          <p className="text-gray-300 text-center max-w-2xl mx-auto mb-12">
            Compare top payment providers and get your exclusive deals.
          </p>
          <div className="grid md:grid-cols-3 gap-6 md:gap-8">
            {providersData.slice(0, 3).map((provider: ProviderList, index: number) => (
              <ProviderCard
                key={provider.id}
                provider={provider}
                onSelect={handleProviderSelect}
                recommended={index === 0}
              />
            ))}
          </div>
        </div>
      </section>
      <Testimonials />
    </>
  );
};