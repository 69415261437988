import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Calendar, Clock, User, ArrowLeft } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { useGetBlogPostBySlugQuery } from '@/store/api/rootApi';
import ReactMarkdown from 'react-markdown';
import { format } from 'date-fns';
import { Helmet } from 'react-helmet-async';

export const BlogPost: React.FC = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const { data: post, isLoading, error } = useGetBlogPostBySlugQuery(slug || '');

  if (isLoading) {
    return (
      <div className="min-h-screen py-16 container mx-auto px-4">
        <div className="animate-pulse space-y-8">
          <div className="h-8 bg-zinc-800 rounded w-2/3"></div>
          <div className="h-64 bg-zinc-800 rounded"></div>
          <div className="space-y-4">
            <div className="h-4 bg-zinc-800 rounded w-full"></div>
            <div className="h-4 bg-zinc-800 rounded w-5/6"></div>
          </div>
        </div>
      </div>
    );
  }

  if (error || !post) {
    return (
      <div className="min-h-screen py-16 container mx-auto px-4 text-center">
        <h1 className="text-2xl text-zinc-100 mb-4">Blog post not found</h1>
        <Button 
          onClick={() => navigate('/blog')}
          variant="outline"
          className="bg-zinc-900 hover:bg-zinc-800"
        >
          <ArrowLeft className="w-4 h-4 mr-2" />
          Back to Blog
        </Button>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>{post.title} | PaySouq Blog</title>
        <meta name="description" content={post.excerpt} />
        <meta property="og:title" content={post.title} />
        <meta property="og:description" content={post.excerpt} />
        <meta property="og:image" content={post.cover_image} />
        <meta property="og:type" content="article" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <div className="min-h-screen py-16">
        <div className="container mx-auto px-4">
          <motion.article
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="max-w-4xl mx-auto"
          >
            <Button 
              onClick={() => navigate('/blog')}
              variant="outline"
              className="mb-8 bg-zinc-900 hover:bg-zinc-800"
            >
              <ArrowLeft className="w-4 h-4 mr-2" />
              Back to Blog
            </Button>

            <img
              src={post.cover_image}
              alt={post.title}
              className="w-full h-[400px] object-cover rounded-lg mb-8"
            />

            <div className="space-y-6">
              <div className="flex items-center gap-4">
                <span className="bg-blue-500/20 text-blue-300 px-3 py-1 rounded-full text-sm">
                  {post.category}
                </span>
                <span className="text-zinc-400 text-sm flex items-center gap-1">
                  <Clock className="w-4 h-4" />
                  5 min read
                </span>
              </div>

              <h1 className="text-4xl font-bold text-zinc-100">{post.title}</h1>

              <div className="flex items-center gap-4 text-zinc-400 text-sm">
                <div className="flex items-center gap-2">
                  <User className="w-4 h-4" />
                  Author Name
                </div>
                <div className="flex items-center gap-2">
                  <Calendar className="w-4 h-4" />
                  {format(new Date(post.created_at), 'MMMM d, yyyy')}
                </div>
              </div>

              <div className="prose prose-invert max-w-none">
                <ReactMarkdown>{post.content}</ReactMarkdown>
              </div>

              {post.tags.length > 0 && (
                <div className="flex flex-wrap gap-2 pt-8">
                  {post.tags.map((tag, index) => (
                    <span
                      key={index}
                      className="bg-zinc-800 text-zinc-100 px-3 py-1 rounded-full text-sm"
                    >
                      {tag}
                    </span>
                  ))}
                </div>
              )}
            </div>
          </motion.article>
        </div>
      </div>
    </>
  );
};
