// src/store/api/rootApi.ts
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { ProviderList } from '@/types';

// Define your data models (interfaces)
export interface DailyAnalyticsData {
  id: string;
  date: Date | string;
  total_revenue: number;
  new_businesses: number;
  provider_switches: number;
  active_providers: number;
  total_transactions: number;
  conversion_rate: number;
  created_at: string;
}

export interface ProviderAnalyticsData {
  id: string;
  created_at: string;
  provider_id: string;
  provider_name: string;
  total_revenue: number;
}

export interface Features {
    pos_machine: boolean;
    ecommerce: boolean;
    mobile_payments: boolean;
    recurring_payments: boolean;
    invoice_payments: boolean;
    payment_links: boolean;
    virtual_terminal: boolean;
    qr_payments: boolean;
    // Add other features as needed
  }
  
  export interface Provider {
    id: string;
    name: string;
    description: string;
    logo_url: string;
    transaction_fee_standard: number;
    transaction_fee_international: number;
    transaction_fee_inperson: number;
    monthly_fee: number;
    setup_time: string;
    payout_time: string;
    support_hours: string;
    countries_supported: number;
    currencies_supported: number;
    features: Features;
    payment_methods: string[];
    card_types: string[];
    ideal_for: string;
    status: string;
    api_documentation_url: string;
    custom_pricing_available: boolean;
    custom_pricing_threshold: number;
    percentage_discount_rate: number;
  created_at?: string;
}

interface Merchant {
  id: string;
  name: string;
  email: string;
  full_name: string;
  company_name: string;
  business_type: string;
}

interface UpdateMerchantData {
  name?: string;
  // Add other optional fields for updating a merchant
}

export interface MerchantPayment {
  id: string;
  created_at: string;
  merchant_id: string;
  provider_id: string;
  provider_name: string;
  amount: number;
  currency: string;
  status: string;
  reference_token: string;
  transaction_id: string;
  payment_date: string;
  is_new_business: boolean;
  customer_name: string;
  customer_email: string;
  customer_phone: string;
  metadata?: Record<string, any>;
  merchants: {
    company_name?: string;
    full_name: string;
  };
}

export interface Document {
  id: string;
  created_at: string;
  merchant_id: string;
  payment_id?: string;
  type: string;
  name: string;
  url: string;
  status: 'pending' | 'verified' | 'rejected';
  verification_date?: string;
  verified_by?: string;
  rejection_reason?: string;
  merchant: {
    full_name: string;
    company_name?: string;
    business_type: string;
  };
}



interface Contact {
  id: string;
  name: string;
  email: string;
  // Add other fields as per your API response
}

interface UpdateContactData {
  name?: string;
  email?: string;
  // Add other optional fields for updating a contact
}

export interface BlogPost {
  id: string;
  title: string;
  slug: string;
  content: string;
  excerpt: string;
  cover_image: string;
  category: string;
  tags: string[];
  status: 'draft' | 'published';
  created_at: string;
  updated_at: string;
  author_id: string;
}

// Add Message interface
export interface Message {
  id: string;
  subject: string;
  content: string;
  sender_email: string;
  sender_name: string;
  status: 'unread' | 'read' | 'archived';
  created_at: string;
}

// Add ContactMessage interface
export interface ContactMessage {
  id: string;
  name: string;
  email: string;
  message: string;
  category: string;
  status: 'new' | 'in_progress' | 'resolved';
  created_at: string;
}

// Environment variables and validation
const SUPABASE_URL = import.meta.env.VITE_SUPABASE_URL as string;
const SUPABASE_ANON_KEY = import.meta.env.VITE_SUPABASE_ANON_KEY as string;

if (!SUPABASE_URL || !SUPABASE_ANON_KEY) {
  throw new Error('Missing Supabase environment variables');
}

// Create the API slice
export const rootApi = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: SUPABASE_URL,
    prepareHeaders: (headers) => {
      headers.set('apikey', SUPABASE_ANON_KEY);
      headers.set('Authorization', `Bearer ${SUPABASE_ANON_KEY}`);
      headers.set('Content-Type', 'application/json');
      headers.set('Prefer', 'return=minimal');
      return headers;
    },
  }),
  tagTypes: ['Analytics', 'Merchants', 'Purchases', 'Documents', 'Contacts', 'Blog', 'Providers', 'Messages'],
  endpoints: (builder) => ({
    // Updated Analytics endpoint with proper error handling
    getDailyAnalytics: builder.query<DailyAnalyticsData[], { start: string; end: string }>({
      query: ({ start, end }) => ({
        url: '/rest/v1/analytics_daily',
        params: {
          select: '*',
          and: `(date.gte.${start},date.lte.${end})`,
          order: 'date.asc'
        }
      }),
      transformResponse: (response: unknown) => {
        if (!Array.isArray(response)) {
          console.error('Invalid analytics response format');
          return [];
        }
        return response;
      },
      transformErrorResponse: (response: { status: number; data: unknown }) => {
        console.error('Analytics Error:', response);
        return {
          status: response.status,
          message: 'Failed to fetch analytics data',
          data: response.data
        };
      }
    }),
    getProviderAnalytics: builder.query<ProviderAnalyticsData[], { start: string; end: string }>({
      query: ({ start, end }) => ({
        url: '/rest/v1/analytics_daily',
        params: {
          select: '*',
          and: `(created_at.gte.${start},created_at.lte.${end})`,
          order: 'created_at.asc'
        },
        headers: {
          Prefer: 'return=representation'
        }
      }),
      transformResponse: (response: any) => {
        return Array.isArray(response) ? response : [];
      },
      transformErrorResponse: (response: { status: number, data: any }) => {
        console.error('Provider Analytics Error:', response);
        return {
          status: response.status,
          message: 'Failed to fetch provider analytics',
          data: response.data
        };
      }
    }),
    
    // Merchant Endpoints
    getMerchant: builder.query<Merchant[], string>({
      query: (email) => ({
        url: '/rest/v1/merchants',
        params: {
          select: '*',
          email: `eq.${email}`,
        },
      }),
      providesTags: ['Merchants'],
    }),
    updateMerchant: builder.mutation<void, { id: string; data: UpdateMerchantData }>({
      query: ({ id, data }) => ({
        url: '/rest/v1/merchants',
        params: {
          id: `eq.${id}`,
        },
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['Merchants'],
    }),

    // Updated Purchases endpoint with proper date filtering
    getPurchases: builder.query<MerchantPayment[], { start: string; end: string }>({
      query: ({ start, end }) => ({
        url: '/rest/v1/merchant_payments',
        params: {
          select: `
            id,
            created_at,
            amount,
            status,
            currency,
            provider_name,
            merchant_id,
            customer_name,
            customer_email,
            transaction_id,
            merchants(company_name,full_name)
          `,
          // Only add date filtering if not "all"
          ...(start !== 'all' && end !== 'all' ? {
            and: `(created_at.gte.${start},created_at.lte.${end})`
          } : {}),
          order: 'created_at.desc'
        }
      }),
      transformErrorResponse: (response: { status: number; data: unknown }) => {
        console.error('Purchase Query Error Details:', response.data);
        return response;
      }
    }),

    // Simplified Documents query
    getDocuments: builder.query<Document[], void>({
      query: () => ({
        url: '/rest/v1/merchant_documents',
        params: {
          select: `
            id,
            created_at,
            merchant_id,
            payment_id,
            type,
            name,
            url,
            status,
            verification_date,
            verified_by,
            rejection_reason,
            merchants(full_name,company_name,business_type)
          `,
          order: 'created_at.desc'
        }
      }),
      transformErrorResponse: (response: { status: number; data: unknown }) => {
        console.error('Documents Query Error Details:', response.data);
        return response;
      }
    }),
    updateDocument: builder.mutation<void, { id: string; data: Partial<Document> }>({
      query: ({ id, data }) => ({
        url: '/rest/v1/merchant_documents',
        params: {
          id: `eq.${id}`,
        },
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['Documents'],
    }),
    updateDocumentStatus: builder.mutation<void, { id: string; status: Document['status']; reason?: string }>({
      query: ({ id, status, reason }) => ({
        url: '/rest/v1/merchant_documents',
        params: {
          id: `eq.${id}`,
        },
        method: 'PATCH',
        body: { status, rejection_reason: reason },
      }),
      invalidatesTags: ['Documents'],
    }),

    // Blog Endpoints
    getBlogPosts: builder.query<BlogPost[], void>({
      query: () => ({
        url: '/rest/v1/blog_posts',
        params: {
          select: '*',
        },
      }),
      providesTags: ['Blog'],
    }),
    getBlogPost: builder.query<BlogPost, string>({
      query: (id) => ({
        url: '/rest/v1/blog_posts',
        params: {
          id: `eq.${id}`,
          select: '*',
        },
      }),
      providesTags: ['Blog'],
    }),
    createBlogPost: builder.mutation<BlogPost, Partial<BlogPost>>({
      query: (data) => ({
        url: '/rest/v1/blog_posts',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Blog'],
    }),
    updateBlogPost: builder.mutation<BlogPost, { id: string; data: Partial<BlogPost> }>({
      query: ({ id, data }) => ({
        url: '/rest/v1/blog_posts',
        params: {
          id: `eq.${id}`,
        },
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['Blog'],
    }),
    deleteBlogPost: builder.mutation<void, string>({
      query: (id) => ({
        url: '/rest/v1/blog_posts',
        params: {
          id: `eq.${id}`,
        },
        method: 'DELETE',
      }),
      invalidatesTags: ['Blog'],
    }),

    // Contacts Endpoints
    getContacts: builder.query<Contact[], void>({
      query: () => ({
        url: '/rest/v1/contacts',
        params: {
          select: '*',
        },
      }),
      providesTags: ['Contacts'],
    }),
    updateContact: builder.mutation<void, { id: string; data: UpdateContactData }>({
      query: ({ id, data }) => ({
        url: '/rest/v1/contacts',
        params: {
          id: `eq.${id}`,
        },
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['Contacts'],
    }),

    // Provider Endpoints
    getProviders: builder.query<Provider[], void>({
      query: () => ({
        url: '/rest/v1/providers',
        params: {
          select: '*',
          order: 'created_at.desc'
        },
        headers: {
          Prefer: 'return=representation'
        }
      }),
      transformErrorResponse: (response: { status: number; data: unknown }) => {
        console.error('Get Providers Error:', response);
        return {
          status: response.status,
          message: 'Failed to fetch providers',
          data: response.data
        };
      },
      providesTags: ['Providers'],
    }),
    getProviderById: builder.query<ProviderList, string>({
      query: (id) => ({
        url: '/rest/v1/providers',
        params: {
          id: `eq.${id}`,
          select: '*',
        },
      }),
      providesTags: ['Providers'],
    }),
    updateProvider: builder.mutation<Provider, { id: string; data: Partial<Provider> }>({
      query: ({ id, data }) => ({
        url: '/rest/v1/providers',
        method: 'PATCH',
        params: {
          id: `eq.${id}`
        },
        body: data,
        headers: {
          Prefer: 'return=representation'
        }
      }),
      transformErrorResponse: (response: { status: number; data: unknown }) => {
        console.error('Update Provider Error:', response);
        return {
          status: response.status,
          message: 'Failed to update provider',
          data: response.data
        };
      },
      invalidatesTags: ['Providers'],
    }),

    // Regular Messages
    getSystemMessages: builder.query<Message[], void>({
      query: () => ({
        url: '/rest/v1/messages',
        params: {
          select: '*',
          order: 'created_at.desc',
        },
      }),
      providesTags: ['Messages'],
    }),

    updateSystemMessageStatus: builder.mutation<void, { id: string; status: Message['status'] }>({
      query: ({ id, status }) => ({
        url: '/rest/v1/messages',
        params: {
          id: `eq.${id}`,
        },
        method: 'PATCH',
        body: { status },
      }),
      invalidatesTags: ['Messages'],
    }),

    // Contact Messages
    getContactMessages: builder.query<ContactMessage[], string>({
      query: (searchQuery) => ({
        url: '/rest/v1/contact_messages',
        params: {
          select: '*',
          order: 'created_at.desc',
          ...(searchQuery && {
            or: `name.ilike.*${searchQuery}*,email.ilike.*${searchQuery}*,message.ilike.*${searchQuery}*`
          })
        },
        headers: {
          Prefer: 'return=minimal'
        }
      }),
      transformErrorResponse: (response: { status: number; data: unknown }) => {
        console.error('Contact Messages Error Details:', response.data);
        return response;
      }
    }),

    updateContactMessageStatus: builder.mutation<void, { id: string; status: ContactMessage['status'] }>({
      query: ({ id, status }) => ({
        url: '/rest/v1/contact_messages',
        params: {
          id: `eq.${id}`,
        },
        method: 'PATCH',
        body: { status },
      }),
      invalidatesTags: ['Messages'],
    }),

    sendMessage: builder.mutation<void, Partial<ContactMessage>>({
      query: (data) => ({
        url: '/rest/v1/contact_messages',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Messages'],
    }),
    addProvider: builder.mutation<Provider, Partial<Provider>>({
      query: (data) => ({
        url: '/rest/v1/providers',
        method: 'POST',
        body: data,
        headers: {
          Prefer: 'return=representation'
        }
      }),
      transformErrorResponse: (response: { status: number; data: unknown }) => {
        console.error('Add Provider Error:', response);
        return {
          status: response.status,
          message: 'Failed to add provider',
          data: response.data
        };
      },
      invalidatesTags: ['Providers'],
    }),

    getBlogPostBySlug: builder.query<BlogPost, string>({
      query: (slug) => `/blog/posts/${slug}`,
    }),
  }),
});

// Export hooks for usage in functional components
export const {
  useGetDailyAnalyticsQuery,
  useGetProviderAnalyticsQuery,
  useGetMerchantQuery,
  useUpdateMerchantMutation,
  useGetPurchasesQuery,
  useGetDocumentsQuery,
  useUpdateDocumentMutation,
  useUpdateDocumentStatusMutation,
  useGetContactsQuery,
  useUpdateContactMutation,
  useGetBlogPostsQuery,
  useGetBlogPostQuery,
  useCreateBlogPostMutation,
  useUpdateBlogPostMutation,
  useDeleteBlogPostMutation,
  useGetProvidersQuery,
  useGetProviderByIdQuery,
  useUpdateProviderMutation,
  useGetSystemMessagesQuery,
  useUpdateSystemMessageStatusMutation,
  useGetContactMessagesQuery,
  useUpdateContactMessageStatusMutation,
  useSendMessageMutation,
  useAddProviderMutation,
  useGetBlogPostBySlugQuery,
} = rootApi;
