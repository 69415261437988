import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { 
  Rocket, 
  ArrowRight, 
  Check, 
  RefreshCw,
  Shield,
  Zap,
  Globe,
  Clock
} from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from '@/components/ui/card';
import { useCurrency } from '@/contexts/CurrencyContext';
import { convertAmount, formatCurrency } from '@/lib/currencies';
import { CurrencySelect } from '@/components/CurrencySelect';

const features = [
  'Compare rates from multiple payment providers',
  'Access to exclusive provider discounts',
  'Free consultation and provider matching',
  '24/7 support during transition',
  'Provider contract review and negotiation',
  'Dedicated switching support'
];

const switchingFeatures = [
  ...features,
  'Current provider cost analysis',
  'Better rates negotiation with new providers',
  'Seamless transition support',
  'Contract termination assistance'
];

export const Pricing: React.FC = () => {
  const navigate = useNavigate();
  const { currentCurrency } = useCurrency();

  const newBusinessPrice = convertAmount(29, 'USD', currentCurrency.code);
  const switchingPrice = convertAmount(49, 'USD', currentCurrency.code);

  const handleGetStarted = (isNewBusiness: boolean) => {
    navigate('/onboarding', { 
      state: { 
        isNewBusiness,
        selectedPlan: isNewBusiness ? 'new' : 'switch',
        price: isNewBusiness ? newBusinessPrice : switchingPrice
      }
    });
  };

  return (
    <div className="min-h-screen py-16">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-12"
        >
          <h1 className="text-3xl md:text-4xl font-bold mb-4 text-white">
            Find Your Perfect Payment Provider
          </h1>
          <p className="text-white max-w-3xl mx-auto mb-8">
            We help businesses secure better payment processing rates. Whether you're just starting or looking to switch providers, we'll find you the best deal.
          </p>
          
          <div className="flex justify-center items-center gap-4">
            <span className="text-white">Select your currency:</span>
            <CurrencySelect />
          </div>
        </motion.div>

        <div className="grid md:grid-cols-2 gap-8 max-w-5xl mx-auto">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.1 }}
          >
            <Card className="relative overflow-hidden h-full">
              <div className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-blue-500 to-purple-500" />
              <CardHeader>
                <CardTitle className="flex items-center gap-2 text-white">
                  <Rocket className="w-5 h-5 text-blue-400" />
                  New Business Package
                </CardTitle>
                <CardDescription className="text-gray-300">
                  Let us find you the best rates as you start your journey
                </CardDescription>
              </CardHeader>
              <CardContent>
                <div className="mb-6">
                  <div className="text-3xl font-bold mb-2 text-white">
                    {formatCurrency(newBusinessPrice, currentCurrency.code)}
                  </div>
                  <div className="text-sm text-gray-300">One-time service fee</div>
                  <p className="mt-2 text-sm text-blue-400">
                    We'll help you secure rates from as low as 1%
                  </p>
                </div>

                <div className="space-y-4 mb-8">
                  {features.map((feature) => (
                    <div key={feature} className="flex items-center gap-2">
                      <Check className="w-4 h-4 text-green-400 flex-shrink-0" />
                      <span className="text-sm text-gray-300">{feature}</span>
                    </div>
                  ))}
                </div>

                <Button 
                  className="w-full"
                  onClick={() => handleGetStarted(true)}
                >
                  Get Better Rates
                  <ArrowRight className="ml-2 w-4 h-4" />
                </Button>
              </CardContent>
            </Card>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.2 }}
          >
            <Card className="relative overflow-hidden h-full">
              <div className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-green-500 to-blue-500" />
              <CardHeader>
                <CardTitle className="flex items-center gap-2 text-white">
                  <RefreshCw className="w-5 h-5 text-green-400" />
                  Provider Switch Package
                </CardTitle>
                <CardDescription className="text-gray-300">
                  We'll find you a better deal than your current provider
                </CardDescription>
              </CardHeader>
              <CardContent>
                <div className="mb-6">
                  <div className="text-3xl font-bold mb-2 text-white">
                    {formatCurrency(switchingPrice, currentCurrency.code)}
                  </div>
                  <div className="text-sm text-gray-300">One-time service fee</div>
                  <p className="mt-2 text-sm text-green-400">
                    Average savings of 0.5-1.5% on transaction fees
                  </p>
                </div>

                <div className="space-y-4 mb-8">
                  {switchingFeatures.map((feature) => (
                    <div key={feature} className="flex items-center gap-2">
                      <Check className="w-4 h-4 text-green-400 flex-shrink-0" />
                      <span className="text-sm text-gray-300">{feature}</span>
                    </div>
                  ))}
                </div>

                <Button 
                  className="w-full"
                  onClick={() => handleGetStarted(false)}
                >
                  Find Better Provider
                  <ArrowRight className="ml-2 w-4 h-4" />
                </Button>
              </CardContent>
            </Card>
          </motion.div>
        </div>

        {/* Benefits Section */}
        <div className="mt-16 grid md:grid-cols-4 gap-6 max-w-5xl mx-auto">
          {[
            { icon: Shield, title: 'Trusted Partners', description: 'We only work with reliable, regulated payment providers' },
            { icon: Zap, title: 'Save Money', description: 'Access exclusive rates and better deals' },
            { icon: Globe, title: 'Global Network', description: 'Compare providers across 180+ countries' },
            { icon: Clock, title: 'Expert Support', description: 'Guidance throughout your journey' }
          ].map((benefit, index) => (
            <motion.div
              key={benefit.title}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
            >
              <Card>
                <CardContent className="p-6 text-center">
                  <benefit.icon className="w-8 h-8 mx-auto mb-4 text-blue-400" />
                  <h3 className="font-semibold mb-2 text-white">{benefit.title}</h3>
                  <p className="text-sm text-gray-300">{benefit.description}</p>
                </CardContent>
              </Card>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};