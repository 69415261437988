import React from 'react';
import { format } from 'date-fns';
import { Eye, Download, Loader2, AlertCircle } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import { Badge } from '@/components/ui/badge';
import { useGetPurchasesQuery, type MerchantPayment } from '@/store/api/rootApi';

interface AdminPurchaseListProps {
  searchQuery: string;
  dateFilter: string;
}

const getStatusVariant = (status: MerchantPayment['status']): "default" | "secondary" | "destructive" | "outline" => {
  switch (status) {
    case 'completed':
      return 'default';
    case 'pending':
      return 'secondary';
    case 'failed':
      return 'destructive';
    default:
      return 'outline';
  }
};

export const AdminPurchaseList: React.FC<AdminPurchaseListProps> = ({
  searchQuery,
  dateFilter,
}) => {
  const { data: purchaseData, isLoading, error } = useGetPurchasesQuery({
    start: dateFilter || format(new Date(), 'yyyy-MM-dd'),
    end: dateFilter || format(new Date(), 'yyyy-MM-dd'),
  });

  const filteredPurchases = purchaseData?.filter((purchase: MerchantPayment) => {
    const searchLowerCase = searchQuery.toLowerCase();
    const matchesSearch = 
      purchase.merchant_id.toLowerCase().includes(searchLowerCase) ||
      purchase.customer_name.toLowerCase().includes(searchLowerCase) ||
      purchase.customer_email.toLowerCase().includes(searchLowerCase) ||
      purchase.provider_name.toLowerCase().includes(searchLowerCase) ||
      purchase.transaction_id.toLowerCase().includes(searchLowerCase);

    const purchaseDate = format(new Date(purchase.created_at), 'yyyy-MM-dd');
    const matchesDate = !dateFilter || purchaseDate === dateFilter;

    return matchesSearch && matchesDate;
  });

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="text-center space-y-4">
          <Loader2 className="w-8 h-8 animate-spin text-blue-400 mx-auto" />
          <p className="text-gray-300">Loading purchases...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="text-center space-y-4">
          <AlertCircle className="w-8 h-8 text-red-400 mx-auto" />
          <p className="text-gray-300">Failed to load purchases</p>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h3 className="text-lg font-semibold text-white">Recent Purchases</h3>
        <Button 
          variant="outline" 
          className="text-gray-300 hover:text-white border-gray-700 hover:bg-gray-800"
        >
          <Download className="w-4 h-4 mr-2" />
          Export
        </Button>
      </div>

      <div className="space-y-4">
        {filteredPurchases && filteredPurchases.length > 0 ? (
          filteredPurchases.map((purchase) => (
            <Card key={purchase.id} className="bg-gray-900 border-gray-800">
              <CardContent className="p-6">
                <div className="flex items-center justify-between">
                  <div>
                    <div className="flex items-center gap-4 mb-2">
                      <h4 className="font-semibold text-white">
                        {purchase.merchants?.company_name || purchase.merchants?.full_name}
                      </h4>
                      <Badge
                        variant={getStatusVariant(purchase.status)}
                        className="capitalize"
                      >
                        {purchase.status}
                      </Badge>
                    </div>
                    <div className="text-sm text-gray-400">
                      {format(new Date(purchase.created_at), 'PPP')}
                    </div>
                  </div>

                  <div className="flex items-center gap-8">
                    <div className="text-right">
                      <div className="font-medium text-white">
                        {purchase.currency} {purchase.amount.toLocaleString()}
                      </div>
                      <div className="text-sm text-gray-400">
                        {purchase.provider_name}
                      </div>
                    </div>

                    <Button 
                      variant="outline" 
                      size="sm"
                      className="text-gray-300 hover:text-white border-gray-700 hover:bg-gray-800"
                    >
                      <Eye className="w-4 h-4 mr-2" />
                      View Details
                    </Button>
                  </div>
                </div>
              </CardContent>
            </Card>
          ))
        ) : (
          <div className="text-center py-8 text-gray-400 bg-gray-900 rounded-lg border border-gray-800">
            No purchases found matching your criteria
          </div>
        )}
      </div>
    </div>
  );
};