import React from 'react';
import { format, subDays, subMonths } from 'date-fns';
import { Mail, Check, MessageSquare, Clock, Loader2, AlertCircle } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import { Badge } from '@/components/ui/badge';
import {
  ContactMessage,
  useGetContactMessagesQuery,
  useUpdateContactMessageStatusMutation,
} from '@/store/api/rootApi';
import toast from 'react-hot-toast';

interface AdminMessagesProps {
  searchQuery: string;
  dateFilter: string;
}

export const AdminMessages: React.FC<AdminMessagesProps> = ({
  searchQuery,
  dateFilter,
}) => {
  const { data: messages, isLoading, error } = useGetContactMessagesQuery(searchQuery);
  const [updateStatus] = useUpdateContactMessageStatusMutation();

  const filteredMessages = messages?.filter((message: ContactMessage) => {
    const matchesSearch =
      message.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      message.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
      message.message.toLowerCase().includes(searchQuery.toLowerCase());

    if (!matchesSearch) return false;

    const messageDate = new Date(message.created_at);
    const now = new Date();

    switch (dateFilter) {
      case 'today': {
        return messageDate.toDateString() === now.toDateString();
      }
      case 'week': {
        const weekAgo = subDays(now, 7);
        return messageDate >= weekAgo;
      }
      case 'month': {
        const monthAgo = subMonths(now, 1);
        return messageDate >= monthAgo;
      }
      default: {
        return true;
      }
    }
  });

  const handleStatusUpdate = async (
    id: string,
    status: 'resolved' | 'in_progress'
  ) => {
    try {
      await updateStatus({ id, status }).unwrap();
      toast.success('Status updated successfully', {
        style: {
          background: '#1F2937',
          color: '#fff',
          borderRadius: '8px',
        },
      });
    } catch (error) {
      toast.error('Failed to update status', {
        style: {
          background: '#1F2937',
          color: '#fff',
          borderRadius: '8px',
        },
      });
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="text-center space-y-4">
          <Loader2 className="w-8 h-8 animate-spin text-blue-400 mx-auto" />
          <p className="text-gray-300">Loading messages...</p>
        </div>
      </div>
    );
  }

  if (error) {
    console.error('Messages Error:', { error });
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="text-center space-y-4">
          <AlertCircle className="w-8 h-8 text-red-400 mx-auto" />
          <p className="text-gray-300">Failed to load messages</p>
          <p className="text-sm text-gray-400">
            {(error as any)?.data?.message || 'Please try again later'}
          </p>
        </div>
      </div>
    );
  }

  if (!messages?.length) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="text-center space-y-4">
          <AlertCircle className="w-8 h-8 text-yellow-400 mx-auto" />
          <p className="text-gray-300">No messages available</p>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h3 className="text-lg font-semibold text-white">Contact Messages</h3>
        <Button variant="outline" className="text-gray-300 hover:text-white">
          Export Messages
        </Button>
      </div>

      <div className="space-y-4">
        {filteredMessages && filteredMessages.length > 0 ? (
          filteredMessages.map((message: ContactMessage) => (
            <Card key={message.id} className="bg-gray-900 border-gray-800">
              <CardContent className="p-6">
                <div className="flex items-center justify-between mb-4">
                  <div className="flex items-center gap-4">
                    <div className="bg-blue-500/10 p-2 rounded-full">
                      <MessageSquare className="w-5 h-5 text-blue-400" />
                    </div>
                    <div>
                      <h4 className="font-semibold text-white">{message.name}</h4>
                      <div className="flex items-center gap-2 text-gray-400">
                        <Mail className="w-4 h-4" />
                        <span className="text-gray-300">{message.email}</span>
                      </div>
                    </div>
                  </div>

                  <Badge
                    variant={
                      message.status === 'resolved'
                        ? 'default'
                        : message.status === 'in_progress'
                        ? 'secondary'
                        : 'outline'
                    }
                    className="text-white"
                  >
                    {message.status.replace('_', ' ')}
                  </Badge>
                </div>

                <div className="mb-4">
                  <div className="text-sm text-gray-400 mb-1">Category</div>
                  <div className="text-gray-200">{message.category}</div>
                </div>

                <div className="mb-4">
                  <div className="text-sm text-gray-400 mb-1">Message</div>
                  <p className="text-gray-300">{message.message}</p>
                </div>

                <div className="flex items-center justify-between">
                  <div className="flex items-center text-sm text-gray-400">
                    <Clock className="w-4 h-4 mr-2" />
                    Received {format(new Date(message.created_at), 'PPP')}
                  </div>

                  <div className="flex items-center gap-2">
                    {message.status === 'new' && (
                      <>
                        <Button
                          variant="outline"
                          size="sm"
                          className="text-gray-300 hover:text-white"
                          onClick={() =>
                            handleStatusUpdate(message.id, 'in_progress')
                          }
                        >
                          Start Processing
                        </Button>
                        <Button
                          variant="outline"
                          size="sm"
                          className="text-green-400 hover:text-green-500"
                          onClick={() =>
                            handleStatusUpdate(message.id, 'resolved')
                          }
                        >
                          <Check className="w-4 h-4" />
                        </Button>
                      </>
                    )}
                    {message.status === 'in_progress' && (
                      <Button
                        variant="outline"
                        size="sm"
                        className="text-green-400 hover:text-green-500"
                        onClick={() =>
                          handleStatusUpdate(message.id, 'resolved')
                        }
                      >
                        Mark as Resolved
                      </Button>
                    )}
                  </div>
                </div>
              </CardContent>
            </Card>
          ))
        ) : (
          <div className="text-center py-8 text-gray-400 bg-gray-900 rounded-lg border border-gray-800">
            No messages found matching your criteria
          </div>
        )}
      </div>
    </div>
  );
};
