import React from 'react';
import { Check, X, HelpCircle } from 'lucide-react';
import { motion } from 'framer-motion';
import { ProviderList } from '@/types';
import { useCurrency } from '@/contexts/CurrencyContext';
import { formatCurrency } from '@/lib/currencies';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';

interface ComparisonTableProps {
  providers: ProviderList[];
}

interface ComparisonSection {
  title: string;
  features: ComparisonFeature[];
}

interface ComparisonFeature {
  key: string;
  label: string;
  tooltip?: string;
  getValue: (provider: ProviderList) => React.ReactNode;
}

export const ComparisonTable: React.FC<ComparisonTableProps> = ({ providers }) => {
  const { currentCurrency } = useCurrency();

  const sections: ComparisonSection[] = [
    {
      title: 'Transaction Fees',
      features: [
        {
          key: 'standard_rate',
          label: 'Standard Rate',
          tooltip: 'Base transaction fee for domestic payments',
          getValue: (provider) => provider.transactionFee.standard
        },
        {
          key: 'international_rate',
          label: 'International Rate',
          tooltip: 'Fee for cross-border transactions',
          getValue: (provider) => provider.transactionFee.international
        },
        {
          key: 'in_person_rate',
          label: 'In-Person Rate',
          tooltip: 'Fee for point-of-sale transactions',
          getValue: (provider) => provider.transactionFee.inPerson
        },
        {
          key: 'monthly_fee',
          label: 'Monthly Fee',
          tooltip: 'Fixed monthly subscription cost',
          getValue: (provider) => formatCurrency(parseFloat(provider.monthlyFee), currentCurrency.code)
        }
      ]
    },
    {
      title: 'Coverage & Support',
      features: [
        {
          key: 'countries',
          label: 'Countries Supported',
          getValue: (provider) => `${provider.countries}+ countries`
        },
        {
          key: 'currencies',
          label: 'Currencies Supported',
          getValue: (provider) => `${provider.currencies}+ currencies`
        },
        {
          key: 'payout_time',
          label: 'Payout Time',
          tooltip: 'Average time to receive funds',
          getValue: (provider) => provider.payoutTime
        },
        {
          key: 'support_hours',
          label: 'Support Hours',
          getValue: (provider) => provider.supportHours
        }
      ]
    },
    {
      title: 'Features & Capabilities',
      features: [
        {
          key: 'api_access',
          label: 'API Access',
          tooltip: 'Access to developer APIs and documentation',
          getValue: (provider) => renderBoolean(provider.hasApi)
        },
        {
          key: 'dashboard',
          label: 'Dashboard',
          tooltip: 'Merchant dashboard for transaction management',
          getValue: (provider) => renderBoolean(provider.hasDashboard)
        },
        {
          key: 'recurring_billing',
          label: 'Recurring Billing',
          tooltip: 'Support for subscription and recurring payments',
          getValue: (provider) => renderBoolean(provider.hasRecurringBilling)
        },
        {
          key: 'dispute_management',
          label: 'Dispute Management',
          tooltip: 'Tools for handling chargebacks and disputes',
          getValue: (provider) => renderBoolean(provider.hasDisputes)
        }
      ]
    },
    {
      title: 'Payment Methods',
      features: [
        {
          key: 'card_types',
          label: 'Card Types',
          getValue: (provider) => provider.cardTypes.join(', ')
        },
        {
          key: 'payment_methods',
          label: 'Payment Methods',
          getValue: (provider) => provider.paymentMethods.join(', ')
        }
      ]
    }
  ];

  const renderBoolean = (value: boolean) => {
    return value ? (
      <Check className="w-5 h-5 text-emerald-500 mx-auto" />
    ) : (
      <X className="w-5 h-5 text-red-500 mx-auto" />
    );
  };

  const renderFeatureCell = (feature: ComparisonFeature, provider: ProviderList) => {
    const value = feature.getValue(provider);
    return (
      <td key={`${provider.id}-${feature.key}`} className="px-6 py-4 text-center text-gray-100">
        {value}
      </td>
    );
  };

  return (
    <div className="overflow-x-auto">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="min-w-full bg-gray-900/90 backdrop-blur-sm rounded-xl border border-gray-800"
      >
        <TooltipProvider>
          <table className="min-w-full divide-y divide-gray-800">
            <thead>
              <tr>
                <th className="px-6 py-4 text-left text-sm font-medium text-gray-100">
                  Features
                </th>
                {providers.map((provider) => (
                  <th
                    key={provider.id}
                    className="px-6 py-4 text-center text-sm font-medium text-gray-100"
                  >
                    {provider.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-800">
              {sections.map((section) => (
                <React.Fragment key={section.title}>
                  <tr>
                    <td
                      colSpan={providers.length + 1}
                      className="px-6 py-3 bg-gray-800/50 text-sm font-semibold text-gray-100"
                    >
                      {section.title}
                    </td>
                  </tr>
                  {section.features.map((feature) => (
                    <tr key={feature.key} className="hover:bg-gray-800/30 transition-colors">
                      <td className="px-6 py-4 text-sm font-medium text-gray-100 flex items-center gap-2">
                        {feature.label}
                        {feature.tooltip && (
                          <Tooltip>
                            <TooltipTrigger asChild>
                              <button className="focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-900 rounded-full">
                                <HelpCircle className="w-4 h-4 text-gray-400 hover:text-gray-300 transition-colors" />
                              </button>
                            </TooltipTrigger>
                            <TooltipContent 
                              className="bg-gray-800 text-gray-100 border border-gray-700"
                              sideOffset={5}
                            >
                              <p>{feature.tooltip}</p>
                            </TooltipContent>
                          </Tooltip>
                        )}
                      </td>
                      {providers.map((provider) => renderFeatureCell(feature, provider))}
                    </tr>
                  ))}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </TooltipProvider>
      </motion.div>
    </div>
  );
};