import React, { useState, useEffect, useMemo } from 'react';
import { motion } from 'framer-motion';
import { Globe, MapPin, Search, CreditCard, Loader2, HelpCircle } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Card, CardContent } from '@/components/ui/card';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { providersData } from '../data/ProvidersData';
import { ProviderList } from '../types';

const regions = [
  { id: 'worldwide', name: 'Worldwide' },
  { id: 'na', name: 'North America' },
  { id: 'eu', name: 'Europe' },
  { id: 'asia', name: 'Asia' },
  { id: 'mena', name: 'Middle East & North Africa' }
];

export const PaymentProviders: React.FC = () => {
  const [selectedRegion, setSelectedRegion] = useState('worldwide');
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [sortCriteria, setSortCriteria] = useState('name');

  useEffect(() => {
    const timer = setTimeout(() => setIsLoading(false), 1000);
    return () => clearTimeout(timer);
  }, []);

  const filteredProviders = useMemo(() => {
    let data = providersData.filter((provider) => {
      const matchesRegion = selectedRegion === 'worldwide' || provider.region.toLowerCase().includes(selectedRegion);
      const matchesSearch = provider.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        provider.idealFor.toLowerCase().includes(searchQuery.toLowerCase());
      return matchesRegion && matchesSearch;
    });

    if (sortCriteria === 'lowestFee') {
      data.sort((a, b) => parseFloat(a.transactionFee.standard) - parseFloat(b.transactionFee.standard));
    } else {
      data.sort((a, b) => a.name.localeCompare(b.name));
    }

    return data;
  }, [selectedRegion, searchQuery, sortCriteria]);

  return (
    <div className="min-h-screen py-8 sm:py-16">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-12"
        >
          <h1 className="text-3xl md:text-4xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400">
            Payment Providers
          </h1>
          <p className="text-white max-w-2xl mx-auto">
            Explore our network of trusted payment providers worldwide
          </p>
        </motion.div>

        <div className="max-w-4xl mx-auto mb-6 sm:mb-8">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-3 sm:gap-4 items-center">
            <div className="md:col-span-1">
              <Select value={selectedRegion} onValueChange={setSelectedRegion}>
                <SelectTrigger>
                  <SelectValue placeholder="Select region" />
                </SelectTrigger>
                <SelectContent>
                  {regions.map((region) => (
                    <SelectItem key={region.id} value={region.id}>
                      <div className="flex items-center gap-2">
                        {region.id === 'worldwide' ? (
                          <Globe className="w-4 h-4" />
                        ) : (
                          <MapPin className="w-4 h-4" />
                        )}
                        {region.name}
                      </div>
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <div className="relative md:col-span-1">
              <Search className="absolute left-3 top-1/2 -translate-y-1/2 w-4 h-4 text-gray-400" />
              <Input
                placeholder="Search providers..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="pl-10"
              />
              <div className="relative" title="Compare payment provider features and rates">
                <HelpCircle className="w-4 h-4 text-gray-400" />
                <span className="sr-only">Provider comparison information</span>
              </div>
            </div>
            <div className="md:col-span-1 flex justify-end">
              <Select value={sortCriteria} onValueChange={setSortCriteria}>
                <SelectTrigger className="bg-gray-800 border-gray-700 text-white">
                  <SelectValue placeholder="Sort by..." />
                </SelectTrigger>
                <SelectContent className="bg-gray-900 border-gray-700">
                  <SelectItem value="name">Name</SelectItem>
                  <SelectItem value="lowestFee">Lowest Transaction Fee</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>
        </div>

        {isLoading ? (
          <div className="flex justify-center items-center min-h-[200px]">
            <Loader2 className="w-8 h-8 animate-spin text-blue-400" />
          </div>
        ) : filteredProviders.length === 0 ? (
          <div className="text-center py-12">
            <p className="text-white">No payment providers found matching your criteria</p>
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6">
            {filteredProviders.map((provider: ProviderList) => (
              <motion.div
                key={provider.id}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.3 }}
              >
                <Card className="h-full hover:shadow-lg transition-shadow">
                  <CardContent className="p-4 sm:p-6">
                    <div className="flex items-center gap-4 mb-4">
                      <div className="w-12 h-12 bg-gradient-to-r from-blue-500/10 to-purple-500/10 rounded-lg flex items-center justify-center">
                        <CreditCard className="w-6 h-6 text-blue-400" />
                      </div>
                      <div>
                        <h3 className="font-semibold text-white">{provider.name}</h3>
                        <p className="text-sm text-white/70">{provider.idealFor}</p>
                      </div>
                    </div>

                    <div className="space-y-2 mb-4">
                      <div className="flex items-center justify-between text-sm">
                        <span className="text-white/70">Standard Rate:</span>
                        <span className="font-medium text-white">{provider.transactionFee.standard}</span>
                      </div>
                      <div className="flex items-center justify-between text-sm">
                        <span className="text-white/70">Currencies:</span>
                        <span className="font-medium text-white">{provider.currencies}+</span>
                      </div>
                      <div className="flex items-center justify-between text-sm">
                        <span className="text-white/70">Setup Time:</span>
                        <span className="font-medium text-white">{provider.setupTime}</span>
                      </div>
                    </div>

                    <Button variant="outline" className="w-full text-white hover:text-white">
                      Learn More
                    </Button>
                  </CardContent>
                </Card>
              </motion.div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};