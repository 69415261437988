import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import {
  FileText,
  CreditCard,
  LogOut,
  Mail,
  AlertCircle,
  MessageSquare,
  Loader2,
  CheckCircle2,
} from 'lucide-react';
import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from '@/components/ui/card';
import { Alert, AlertTitle, AlertDescription } from '@/components/ui/alert';
import { Textarea } from '@/components/ui/textarea';
import { useAuth } from '@/hooks/useAuth';
import { useSupabase } from '@/hooks/useSupabase';
import toast from 'react-hot-toast';
import { supabase } from '@/lib/supabase';
import { PostgrestError } from '@supabase/supabase-js';

// Define the Merchant interface
interface Merchant {
  id: string;
  created_at: string;
  created_by: string | null;
  email: string;
  full_name: string | null;
  company_name: string | null;
  business_type: string | null;
  monthly_volume: number | null;
  preferred_features: string[];
  onboarding_completed: boolean;
  selected_provider: string | null;
  // Add other properties as needed
}

// Define the Purchase interface
interface Purchase {
  id: string;
  created_at: string;
  provider_name: string;
  amount: number;
  currency: string;
  status: string;
  // Add other properties as needed
}

export const UserDashboard: React.FC = () => {
  const navigate = useNavigate();
  const { user, signOut } = useAuth();
  const [supportMessage, setSupportMessage] = React.useState('');
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const { data: merchant, loading: merchantLoading } = useSupabase<'merchants', Merchant | null>({
    table: 'merchants',
    query: async (query) => {
      if (!user?.email) {
        return { data: null, error: null };
      }

      try {
        const { data, error } = await query
          .select('*')
          .eq('email', user.email)
          .single();
          
        if (error) {
          if (error.code === 'PGRST116') {
            return { data: null, error: null };
          }
          throw error;
        }
        
        return { data, error: null };
      } catch (error) {
        console.error('Error fetching merchant:', error);
        toast.error('Failed to load merchant data');
        return { data: null, error: error as PostgrestError };
      }
    },
    deps: [user?.email],
  });

  const { data: purchases, loading: purchasesLoading } = useSupabase<'merchant_payments', Purchase[]>({
    table: 'merchant_payments',
    query: async (query) => {
      if (!user?.email) {
        return { data: [], error: null };
      }

      try {
        const { data, error } = await query
          .select(`
            id,
            created_at,
            provider_name,
            amount,
            currency,
            status
          `)
          .eq('customer_email', user.email)
          .order('created_at', { ascending: false });

        if (error) throw error;
        return { data: data || [], error: null };
      } catch (error) {
        console.error('Error fetching purchases:', error);
        toast.error('Failed to load purchase history');
        return { data: [], error: error as PostgrestError };
      }
    },
    deps: [user?.email],
  });

  const handleSignOut = async () => {
    try {
      await signOut();
      navigate('/login');
    } catch (error) {
      toast.error('Failed to sign out. Please try again.');
    }
  };

  const handleSupportSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!supportMessage.trim()) {
      toast.error('Please enter a message');
      return;
    }

    setIsSubmitting(true);
    try {
      const { error } = await supabase.from('contact_messages').insert({
        name: merchant?.full_name ?? user?.user_metadata?.full_name ?? 'Unknown User',
        email: user?.email,
        category: 'support',
        message: supportMessage,
      });

      if (error) throw error;

      toast.success('Message sent successfully!');
      setSupportMessage('');
    } catch (error) {
      console.error('Error sending message:', error);
      toast.error('Failed to send message. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const isBusinessDetailsComplete = merchant?.onboarding_completed;

  const renderEmptyPurchases = () => (
    <div className="text-center py-12">
      <CreditCard className="w-12 h-12 text-gray-600 mx-auto mb-4" />
      <h3 className="text-lg font-medium text-white mb-2">No Purchases Yet</h3>
      <p className="text-gray-400 mb-6">
        Start your journey by selecting a payment provider for your business
      </p>
      <Button 
        onClick={() => navigate('/providers')}
        className="bg-blue-600 hover:bg-blue-700 text-white"
      >
        <FileText className="w-4 h-4 mr-2" />
        View Providers
      </Button>
    </div>
  );

  const renderNoProviderSelected = () => (
    <Alert className="mb-8 bg-blue-900/20 border-blue-800">
      <AlertCircle className="h-4 w-4 text-blue-400" />
      <AlertTitle className="text-blue-100">Welcome to PaySouq</AlertTitle>
      <AlertDescription className="flex items-center justify-between text-blue-200">
        <div className="space-y-1">
          <p>
            Find the perfect payment provider for your business
          </p>
          <p className="text-sm text-blue-300">
            Compare rates and features from leading payment providers
          </p>
        </div>
        <Button 
          onClick={() => navigate('/providers')}
          className="bg-blue-600 hover:bg-blue-700 text-white whitespace-nowrap ml-4"
        >
          <FileText className="w-4 h-4 mr-2" />
          View Providers
        </Button>
      </AlertDescription>
    </Alert>
  );

  return (
    <div className="min-h-screen py-16 bg-gray-950">
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center mb-12">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
          >
            {merchantLoading ? (
              <div className="space-y-2">
                <div className="h-8 w-48 bg-gray-800 rounded animate-pulse"></div>
                <div className="h-4 w-64 bg-gray-800 rounded animate-pulse"></div>
              </div>
            ) : (
              <>
                <h1 className="text-3xl font-bold mb-2 text-white">
                  Welcome,{' '}
                  {merchant?.company_name ?? user?.user_metadata?.full_name ?? 'User'}
                </h1>
                <p className="text-gray-400">
                  Find and compare payment providers for your business
                </p>
              </>
            )}
          </motion.div>

          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
          >
            <Button 
              variant="outline" 
              onClick={handleSignOut}
              className="bg-gray-900 text-white border-gray-700 hover:bg-gray-800"
            >
              <LogOut className="w-4 h-4 mr-2" />
              Sign Out
            </Button>
          </motion.div>
        </div>

        {!merchantLoading && (
          <>
            {!merchant?.selected_provider ? (
              renderNoProviderSelected()
            ) : isBusinessDetailsComplete ? (
              <Alert className="mb-8 bg-emerald-900/20 border-emerald-800">
                <CheckCircle2 className="h-4 w-4 text-emerald-400" />
                <AlertTitle className="text-emerald-100">Provider Setup Complete</AlertTitle>
                <AlertDescription className="flex items-center justify-between text-emerald-200">
                  <div className="space-y-1">
                    <p>
                      Your business details have been submitted to {merchant.selected_provider}
                    </p>
                    <p className="text-sm text-emerald-300">
                      You will be notified once your application has been reviewed
                    </p>
                  </div>
                  <Button 
                    onClick={() => navigate('/business-details/view')}
                    className="bg-emerald-600 hover:bg-emerald-700 text-white whitespace-nowrap ml-4"
                  >
                    <FileText className="w-4 h-4 mr-2" />
                    View Details
                  </Button>
                </AlertDescription>
              </Alert>
            ) : (
              <Alert className="mb-8 bg-blue-900/20 border-blue-800">
                <AlertCircle className="h-4 w-4 text-blue-400" />
                <AlertTitle className="text-blue-100">Complete Your Provider Setup</AlertTitle>
                <AlertDescription className="flex items-center justify-between text-blue-200">
                  <div className="space-y-1">
                    <p>
                      Submit your business details to finalize your payment provider deal
                    </p>
                    <p className="text-sm text-blue-300">
                      This information will be shared with {merchant.selected_provider} to confirm your rates
                    </p>
                  </div>
                  <Button 
                    onClick={() => navigate('/business-details')}
                    className="bg-blue-600 hover:bg-blue-700 text-white whitespace-nowrap ml-4"
                  >
                    <FileText className="w-4 h-4 mr-2" />
                    Submit Details
                  </Button>
                </AlertDescription>
              </Alert>
            )}
          </>
        )}

        <div className="grid lg:grid-cols-3 gap-8">
          {/* Recent Purchases */}
          <div className="lg:col-span-2">
            <Card className="bg-gray-900 border-gray-800">
              <CardHeader>
                <CardTitle className="text-white">Your Purchases</CardTitle>
                <CardDescription className="text-gray-400">
                  Recent payment provider purchases and their status
                </CardDescription>
              </CardHeader>
              <CardContent>
                <div className="space-y-4">
                  {purchasesLoading ? (
                    <div className="text-center py-6 text-gray-400">
                      <Loader2 className="w-6 h-6 animate-spin mx-auto mb-2" />
                      Loading purchases...
                    </div>
                  ) : (!purchases || purchases.length === 0) ? (
                    renderEmptyPurchases()
                  ) : (
                    purchases.map((purchase) => (
                      <div
                        key={purchase.id}
                        className="flex items-center justify-between p-4 rounded-lg bg-gray-800/50 hover:bg-gray-800 transition-colors"
                      >
                        <div>
                          <div className="flex items-center gap-3 mb-1">
                            <CreditCard className="w-4 h-4 text-blue-400" />
                            <span className="font-medium text-white">
                              {purchase.provider_name}
                            </span>
                          </div>
                          <div className="text-sm text-gray-400">
                            {format(new Date(purchase.created_at), 'PPP')}
                          </div>
                        </div>
                        <div className="text-right">
                          <div className="font-medium text-white">
                            {purchase.currency} {purchase.amount}
                          </div>
                          <div className="text-sm text-green-400">
                            {purchase.status}
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </CardContent>
            </Card>
          </div>

          {/* Support Section */}
          <div>
            <Card className="bg-gray-900 border-gray-800">
              <CardHeader>
                <CardTitle className="text-white">Provider Setup Support</CardTitle>
                <CardDescription className="text-gray-400">
                  Need help with your payment provider setup?
                </CardDescription>
              </CardHeader>
              <CardContent>
                <form onSubmit={handleSupportSubmit} className="space-y-4">
                  <Textarea
                    placeholder="Questions about your provider setup or rates? Let us know..."
                    value={supportMessage}
                    onChange={(e) => setSupportMessage(e.target.value)}
                    className="min-h-[150px] bg-gray-800 border-gray-700 text-white placeholder:text-gray-500 focus:ring-blue-600 focus:border-blue-600"
                  />
                  <Button
                    type="submit"
                    className="w-full bg-blue-600 hover:bg-blue-700 text-white disabled:bg-gray-700"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <>
                        <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                        Sending...
                      </>
                    ) : (
                      <>
                        <Mail className="w-4 h-4 mr-2" />
                        Send Message
                      </>
                    )}
                  </Button>
                </form>

                <div className="mt-6 space-y-4">
                  <div className="flex items-center gap-3 p-4 rounded-lg bg-gray-800/50 hover:bg-gray-800 transition-colors cursor-pointer">
                    <MessageSquare className="w-4 h-4 text-blue-400" />
                    <div>
                      <div className="font-medium text-white">Provider Support</div>
                      <div className="text-sm text-gray-400">
                        Direct line to your provider's support team
                      </div>
                    </div>
                  </div>

                  <div className="flex items-center gap-3 p-4 rounded-lg bg-gray-800/50 hover:bg-gray-800 transition-colors cursor-pointer">
                    <FileText className="w-4 h-4 text-purple-400" />
                    <div>
                      <div className="font-medium text-white">Setup Guide</div>
                      <div className="text-sm text-gray-400">
                        Step-by-step provider integration guide
                      </div>
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDashboard;