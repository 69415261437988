import React from 'react';
import { motion } from 'framer-motion';
import { ArrowRight, Calculator as CalcIcon, DollarSign, Percent } from 'lucide-react';
import { Input } from './ui/input';
import { Label } from './ui/label';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from './ui/select';
import { Button } from './ui/button';
import { Card, CardContent } from './ui/card';
import { useCurrency } from '@/contexts/CurrencyContext';
import { currencies, formatCurrency, type Currency } from '@/lib/currencies';

// Add proper typing for our rates
type RatesByCountry = {
  [key in 'USD' | 'EUR' | 'GBP']: {
    percent: number;
    fixed: number;
  };
};

// Define our competitive rates based on volume ranges
const getOurRates = (volume: number, currency: keyof RatesByCountry): { percent: number; fixed: number } => {
  // Base rates for different volume tiers
  if (volume <= 20000) {
    return {
      USD: { percent: 0.019, fixed: 0.25 },
      EUR: { percent: 0.018, fixed: 0.23 },
      GBP: { percent: 0.017, fixed: 0.20 },
    }[currency] || { percent: 0.019, fixed: 0.25 };
  } else if (volume <= 50000) {
    return {
      USD: { percent: 0.017, fixed: 0.23 },
      EUR: { percent: 0.016, fixed: 0.21 },
      GBP: { percent: 0.015, fixed: 0.18 },
    }[currency] || { percent: 0.017, fixed: 0.23 };
  } else if (volume <= 100000) {
    return {
      USD: { percent: 0.015, fixed: 0.20 },
      EUR: { percent: 0.014, fixed: 0.18 },
      GBP: { percent: 0.013, fixed: 0.15 },
    }[currency] || { percent: 0.015, fixed: 0.20 };
  } else {
    // Best rates for high volume merchants
    return {
      USD: { percent: 0.012, fixed: 0.18 },
      EUR: { percent: 0.011, fixed: 0.16 },
      GBP: { percent: 0.010, fixed: 0.13 },
    }[currency] || { percent: 0.012, fixed: 0.18 };
  }
};

interface CalculatorProps {
  onSubmit: (data: { 
    email: string; 
    volume: string; 
    savings: number;
    currentRates: { percentage: string; fixed: string };
  }) => void;
}

const getExampleRate = (volume: string, currency: Currency) => {
  const volumeNum = parseInt(volume) || 20000; // Default to lowest tier
  const ourRate = getOurRates(volumeNum, currency.code as keyof RatesByCountry);
  return {
    percent: (ourRate.percent * 100).toFixed(2),
    fixed: formatCurrency(ourRate.fixed, currency.code)
  };
};

export const Calculator = ({ onSubmit }: CalculatorProps) => {
  const { currentCurrency, setCurrentCurrency } = useCurrency();
  const [formState, setFormState] = React.useState({
    email: '',
    volume: '',
    currentRate: {
      percentage: '',
      fixed: ''
    }
  });
  const [errors, setErrors] = React.useState<Record<string, string>>({});
  const [savings, setSavings] = React.useState({
    monthly: 0,
    yearly: 0,
    percentage: 0
  });
  const [isCalculating, setIsCalculating] = React.useState(false);
  const [showResults, setShowResults] = React.useState(false);

  const validateForm = (): boolean => {
    const newErrors: Record<string, string> = {};

    if (!formState.email) {
      newErrors.email = 'Email is required';
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formState.email)) {
      newErrors.email = 'Please enter a valid email';
    }

    if (!formState.volume) {
      newErrors.volume = 'Please select a volume range';
    }

    if (!formState.currentRate.percentage) {
      newErrors.percentage = 'Current rate percentage is required';
    } else if (parseFloat(formState.currentRate.percentage) <= 0) {
      newErrors.percentage = 'Rate must be greater than 0';
    }

    if (!formState.currentRate.fixed) {
      newErrors.fixed = 'Fixed rate is required';
    } else if (parseFloat(formState.currentRate.fixed) < 0) {
      newErrors.fixed = 'Fixed rate cannot be negative';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const getVolumeRanges = (currency: Currency) => [
    { 
      label: `${formatCurrency(0, currency.code)} - ${formatCurrency(20000, currency.code)}`, 
      value: '20000', 
      avgTransaction: 50 
    },
    { 
      label: `${formatCurrency(20001, currency.code)} - ${formatCurrency(50000, currency.code)}`, 
      value: '50000', 
      avgTransaction: 75 
    },
    { 
      label: `${formatCurrency(50001, currency.code)} - ${formatCurrency(100000, currency.code)}`, 
      value: '100000', 
      avgTransaction: 100 
    },
    { 
      label: `${formatCurrency(100001, currency.code)} - ${formatCurrency(250000, currency.code)}`, 
      value: '250000', 
      avgTransaction: 150 
    },
    { 
      label: `${formatCurrency(250001, currency.code)} - ${formatCurrency(500000, currency.code)}`, 
      value: '500000', 
      avgTransaction: 200 
    },
    { 
      label: `${formatCurrency(500001, currency.code)}+`, 
      value: '1000000', 
      avgTransaction: 250 
    },
  ];

  const handleCurrencyChange = (currencyCode: string) => {
    const newCurrency = currencies.find(c => c.code === currencyCode);
    if (newCurrency) {
      setCurrentCurrency(newCurrency);
      setFormState(prev => ({
        ...prev,
        volume: '',
        currentRate: {
          percentage: '',
          fixed: ''
        }
      }));
      setSavings({
        monthly: 0,
        yearly: 0,
        percentage: 0
      });
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsCalculating(true);
    try {
      const selectedRange = getVolumeRanges(currentCurrency).find(range => range.value === formState.volume);
      if (!selectedRange) return;

      const volumeNum = parseInt(selectedRange.value);
      const currentPercentRate = parseFloat(formState.currentRate.percentage) / 100;
      const currentFixedRate = parseFloat(formState.currentRate.fixed);
      
      const ourRate = getOurRates(volumeNum, currentCurrency.code as keyof RatesByCountry);
      const ourPercentRate = ourRate.percent;
      const ourFixedRate = ourRate.fixed;

      const avgTransactionSize = selectedRange.avgTransaction;
      const numberOfTransactions = Math.ceil(volumeNum / avgTransactionSize);

      const currentTotalFees = (volumeNum * currentPercentRate) + (numberOfTransactions * currentFixedRate);
      const ourTotalFees = (volumeNum * ourPercentRate) + (numberOfTransactions * ourFixedRate);

      const monthlySavings = Math.max(0, currentTotalFees - ourTotalFees);
      const yearlySavings = monthlySavings * 12;
      const savingsPercentage = currentTotalFees > 0 ? (monthlySavings / currentTotalFees) * 100 : 0;

      setSavings({
        monthly: monthlySavings,
        yearly: yearlySavings,
        percentage: savingsPercentage
      });

      setShowResults(true);

      await onSubmit({ 
        email: formState.email, 
        volume: formState.volume,
        savings: monthlySavings,
        currentRates: formState.currentRate
      });
    } catch (error) {
      console.error('Error calculating savings:', error);
      // Handle submission error
    } finally {
      setIsCalculating(false);
    }
  };

  const handleFormChange = (newState: typeof formState) => {
    setFormState(newState);
    setShowResults(false);
  };

  return (
    <section className="py-16 relative overflow-hidden">
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-gradient-to-b from-black via-blue-950/20 to-black" />
        <div className="absolute inset-0 bg-grid-white/[0.02]" />
        <motion.div
          className="absolute inset-0 opacity-20"
          animate={{
            background: [
              'radial-gradient(circle at 0% 0%, rgba(56, 189, 248, 0.1) 0%, transparent 50%)',
              'radial-gradient(circle at 100% 100%, rgba(168, 85, 247, 0.1) 0%, transparent 50%)',
              'radial-gradient(circle at 0% 100%, rgba(56, 189, 248, 0.1) 0%, transparent 50%)',
            ],
          }}
          transition={{ duration: 10, repeat: Infinity }}
        />
      </div>

      <div className="container mx-auto px-4 relative">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
          className="max-w-4xl mx-auto"
        >
          <motion.div 
            className="text-center mb-12"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.2 }}
          >
            <h2 className="text-3xl md:text-4xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400 relative">
              Calculate Your Savings
              <motion.div
                className="absolute -inset-1 bg-gradient-to-r from-blue-500/20 to-purple-500/20 blur-lg opacity-0"
                animate={{ opacity: [0, 0.5, 0] }}
                transition={{ duration: 2, repeat: Infinity }}
              />
            </h2>
            <p className="text-gray-200 max-w-2xl mx-auto text-sm sm:text-base">
              See how much you could save by switching to our exclusive payment provider rates
            </p>
          </motion.div>

          <Card className="relative overflow-hidden border-white/10 backdrop-blur-sm">
            <div className="absolute inset-0 bg-gradient-to-r from-blue-500/10 to-purple-500/10" />
            <CardContent className="relative p-4 sm:p-6 md:p-8">
              <form onSubmit={handleSubmit} className="space-y-6">
                <div className="grid sm:grid-cols-2 gap-4 sm:gap-6">
                  <motion.div 
                    className="space-y-2"
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: 0.3 }}
                  >
                    <Label className="text-white">Currency</Label>
                    <Select 
                      value={currentCurrency.code}
                      onValueChange={handleCurrencyChange}
                    >
                      <SelectTrigger className="bg-black/50 text-white border-white/20 hover:border-white/40 transition-colors">
                        <SelectValue placeholder="Select currency">
                          {currentCurrency.symbol} {currentCurrency.code}
                        </SelectValue>
                      </SelectTrigger>
                      <SelectContent className="bg-black/90 border-white/20">
                        {currencies.map((currency) => (
                          <SelectItem 
                            key={currency.code} 
                            value={currency.code}
                            className="text-white hover:bg-white/10"
                          >
                            {currency.symbol} {currency.code} - {currency.name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </motion.div>

                  <div className="space-y-2" >
                    <Label className="text-white">Monthly Transaction Volume</Label>
                    <Select 
                      value={formState.volume}
                      onValueChange={(value) => handleFormChange({ ...formState, volume: value })}
                    >
                      <SelectTrigger className="bg-black/50 text-white">
                        <SelectValue placeholder="Select volume range" />
                      </SelectTrigger>
                      <SelectContent>
                        {getVolumeRanges(currentCurrency).map((range) => (
                          <SelectItem key={range.value} value={range.value}>
                            {range.label}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    {errors.volume && <p className="text-red-400 text-xs mt-1">{errors.volume}</p>}
                  </div>
                </div>

                <div className="space-y-2">
                  <Label className="text-white">Current Processing Rate</Label>
                  <div className="grid grid-cols-2 gap-4">
                    <div className="relative">
                      <Label className="text-xs text-gray-300 mb-1 block">
                        Percentage Rate
                      </Label>
                      <Input
                        type="number"
                        step="0.01"
                        value={formState.currentRate.percentage}
                        onChange={(e) => handleFormChange({
                          ...formState,
                          currentRate: { ...formState.currentRate, percentage: e.target.value }
                        })}
                        placeholder="2.9"
                        className="bg-black/50 pr-8 text-white placeholder:text-gray-400"
                      />
                      <span className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400">%</span>
                      {errors.percentage && <p className="text-red-400 text-xs mt-1">{errors.percentage}</p>}
                    </div>
                    <div className="relative">
                      <Label className="text-xs text-gray-300 mb-1 block">
                        Per Transaction Fee
                      </Label>
                      <span className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400">
                        {currentCurrency.symbol}
                      </span>
                      <Input
                        type="number"
                        step="0.01"
                        value={formState.currentRate.fixed}
                        onChange={(e) => handleFormChange({
                          ...formState,
                          currentRate: { ...formState.currentRate, fixed: e.target.value }
                        })}
                        placeholder="0.30"
                        className="bg-black/50 pl-8 text-white placeholder:text-gray-400"
                      />
                      {errors.fixed && <p className="text-red-400 text-xs mt-1">{errors.fixed}</p>}
                    </div>
                  </div>
                  
                  <div className="mt-4 p-3 rounded-lg bg-blue-500/10 border border-blue-500/20">
                    <p className="text-sm text-white font-medium mb-1">
                      Our Competitive Rate for Your Volume:
                    </p>
                    <div className="flex items-center gap-2 text-blue-400">
                      <span className="text-lg font-semibold">
                        {getExampleRate(formState.volume, currentCurrency).percent}%
                      </span>
                      <span className="text-gray-400">+</span>
                      <span className="text-lg font-semibold">
                        {getExampleRate(formState.volume, currentCurrency).fixed}
                      </span>
                      <span className="text-sm text-gray-300">per transaction</span>
                    </div>
                  </div>

                  {formState.currentRate.percentage && formState.currentRate.fixed && (
                    <div className="mt-2 text-xs text-gray-300">
                      <p>Example: On a {formatCurrency(100, currentCurrency.code)} transaction:</p>
                      <div className="mt-1 grid grid-cols-2 gap-4">
                        <div>
                          <span className="text-gray-400">Current cost:</span>
                          <span className="text-white ml-1">
                            {formatCurrency(
                              (100 * parseFloat(formState.currentRate.percentage) / 100) + 
                              parseFloat(formState.currentRate.fixed),
                              currentCurrency.code
                            )}
                          </span>
                        </div>
                        <div>
                          <span className="text-gray-400">Our cost:</span>
                          <span className="text-blue-400 ml-1">
                            {formatCurrency(
                              (100 * parseFloat(getExampleRate(formState.volume, currentCurrency).percent) / 100) + 
                              parseFloat(getExampleRate(formState.volume, currentCurrency).fixed.replace(/[^0-9.]/g, '')),
                              currentCurrency.code
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="space-y-2" >
                  <Label className="text-white" htmlFor="email">Business Email</Label>
                  <Input
                    id="email"
                    type="email"
                    value={formState.email}
                    onChange={(e) => handleFormChange({ ...formState, email: e.target.value })}
                    placeholder="you@company.com"
                    className="bg-black/50 text-white placeholder:text-gray-400"
                    required
                  />
                  {errors.email && <p className="text-red-400 text-xs mt-1">{errors.email}</p>}
                </div>

                {showResults && savings.monthly > 0 && (
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ 
                      duration: 0.6,
                      staggerChildren: 0.1,
                    }}
                    className="grid sm:grid-cols-3 gap-4"
                  >
                    <motion.div whileHover={{ scale: 1.02 }} transition={{ type: "spring" }}>
                      <Card className="bg-gradient-to-r from-blue-500/10 to-purple-500/10 border-0 hover:from-blue-500/20 hover:to-purple-500/20 transition-all duration-300">
                        <CardContent className="p-4 text-center">
                          <DollarSign className="w-6 h-6 mx-auto mb-2 text-blue-400" />
                          <div className="text-sm text-gray-400 mb-1">Potential Monthly Savings</div>
                          <div className="text-xs text-gray-500 mb-1">Up to</div>
                          <div className="text-2xl font-bold text-white">
                            {formatCurrency(savings.monthly, currentCurrency.code)}
                          </div>
                        </CardContent>
                      </Card>
                    </motion.div>

                    <motion.div whileHover={{ scale: 1.02 }} transition={{ type: "spring" }}>
                      <Card className="bg-gradient-to-r from-blue-500/10 to-purple-500/10 border-0 hover:from-blue-500/20 hover:to-purple-500/20 transition-all duration-300">
                        <CardContent className="p-4 text-center">
                          <Percent className="w-6 h-6 mx-auto mb-2 text-purple-400" />
                          <div className="text-sm text-gray-400 mb-1">Potential Savings Rate</div>
                          <div className="text-xs text-gray-500 mb-1">Up to</div>
                          <div className="text-2xl font-bold text-white">
                            {savings.percentage.toFixed(1)}%
                          </div>
                        </CardContent>
                      </Card>
                    </motion.div>

                    <motion.div whileHover={{ scale: 1.02 }} transition={{ type: "spring" }}>
                      <Card className="bg-gradient-to-r from-blue-500/10 to-purple-500/10 border-0 hover:from-blue-500/20 hover:to-purple-500/20 transition-all duration-300">
                        <CardContent className="p-4 text-center">
                          <CalcIcon className="w-6 h-6 mx-auto mb-2 text-green-400" />
                          <div className="text-sm text-gray-400 mb-1">Potential Yearly Savings</div>
                          <div className="text-xs text-gray-500 mb-1">Up to</div>
                          <div className="text-2xl font-bold text-white">
                            {formatCurrency(savings.yearly, currentCurrency.code)}
                          </div>
                        </CardContent>
                      </Card>
                    </motion.div>
                  </motion.div>
                )}

                <motion.div
                  whileHover={{ scale: 1.01 }}
                  whileTap={{ scale: 0.99 }}
                >
                  <Button 
                    type="submit" 
                    className="w-full bg-gradient-to-r from-blue-500 to-purple-500 hover:from-blue-600 hover:to-purple-600 transition-all duration-300"
                    disabled={isCalculating}
                  >
                    {isCalculating ? (
                      <span className="flex items-center">
                        Calculating...
                        <motion.div
                          animate={{ rotate: 360 }}
                          transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
                          className="ml-2"
                        >
                          ⟳
                        </motion.div>
                      </span>
                    ) : (
                      <span className="flex items-center">
                        Calculate Savings <ArrowRight className="ml-2 w-4 h-4" />
                      </span>
                    )}
                  </Button>
                </motion.div>
              </form>
            </CardContent>
          </Card>
        </motion.div>
      </div>
    </section>
  );
};