// src/components/AdminProviderList.tsx

import React, { useState } from 'react';
import { Edit, Trash2, Plus, HelpCircle } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import {
  Provider,
  useGetProvidersQuery,
  useUpdateProviderMutation,
  useAddProviderMutation,
} from '@/store/api/rootApi';
import toast from 'react-hot-toast';
import { Skeleton } from '@/components/ui/skeleton';
import { Checkbox } from '@/components/ui/checkbox';

interface AdminProviderListProps {
  searchQuery: string;
}

// First, define the available features
const AVAILABLE_FEATURES = [
  { id: 'pos_machine', label: 'POS Machine' },
  { id: 'ecommerce', label: 'E-commerce' },
  { id: 'mobile_payments', label: 'Mobile Payments' },
  { id: 'recurring_payments', label: 'Recurring Payments' },
  { id: 'invoice_payments', label: 'Invoice Payments' },
  { id: 'payment_links', label: 'Payment Links' },
  { id: 'virtual_terminal', label: 'Virtual Terminal' },
  { id: 'qr_payments', label: 'QR Payments' },
] as const;

// Update the Provider type to ensure all features are required booleans
type ProviderFeatures = {
  [K in typeof AVAILABLE_FEATURES[number]['id']]: boolean;
};

export const AdminProviderList: React.FC<AdminProviderListProps> = ({
  searchQuery,
}) => {
  const [selectedProvider, setSelectedProvider] = useState<Provider | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState<Partial<Provider>>({});
  const [isAdding, setIsAdding] = useState(false);
  const [addProvider] = useAddProviderMutation();
  const [activeFilters, setActiveFilters] = useState<string[]>([]);

  const { data: providers, isLoading, error } = useGetProvidersQuery();
  const [updateProvider] = useUpdateProviderMutation();

  const filteredProviders = providers?.filter((provider) => {
    // Text search filter
    const matchesSearch = provider.name.toLowerCase().includes(searchQuery.toLowerCase());
    
    // Status filter
    const isActive = provider.status !== 'inactive';
    
    // Features filter
    const matchesFeatures = activeFilters.length === 0 || 
      activeFilters.every(feature => provider.features[feature as keyof Provider['features']]);

    return matchesSearch && isActive && matchesFeatures;
  });

  const handleEdit = (provider: Provider) => {
    setSelectedProvider(provider);
    setFormData(provider);
    setIsEditing(true);
  };

  const handleDelete = async (id: string) => {
    if (window.confirm('Are you sure you want to delete this provider?')) {
      try {
        await updateProvider({
          id,
          data: { status: 'inactive' },
        }).unwrap();
        toast.success('Provider deleted successfully');
      } catch (error) {
        toast.error('Failed to delete provider');
      }
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!selectedProvider) return;

    try {
      await updateProvider({
        id: selectedProvider.id,
        data: formData,
      }).unwrap();
      toast.success('Provider updated successfully');
      setIsEditing(false);
      setSelectedProvider(null);
      setFormData({});
    } catch (error) {
      toast.error('Failed to update provider');
    }
  };

  const handleInputChange = (field: keyof Provider, value: any) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleFeatureChange = (feature: keyof ProviderFeatures, value: boolean) => {
    setFormData((prev) => {
      const currentFeatures = prev.features || {} as ProviderFeatures;
      return {
        ...prev,
        features: {
          ...currentFeatures,
          [feature]: value,
        } as ProviderFeatures
      };
    });
  };

  const handleAddSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await addProvider(formData).unwrap();
      toast.success('Provider added successfully');
      setIsAdding(false);
      setFormData({});
    } catch (error) {
      toast.error('Failed to add provider');
    }
  };

  const handleAddNew = () => {
    setIsAdding(true);
    const initialFeatures = AVAILABLE_FEATURES.reduce((acc, feature) => ({
      ...acc,
      [feature.id]: false
    }), {}) as ProviderFeatures;

    setFormData({
      features: initialFeatures,
      payment_methods: [],
      card_types: [],
    });
  };

  const toggleFeatureFilter = (featureId: string) => {
    setActiveFilters(prev => 
      prev.includes(featureId)
        ? prev.filter(f => f !== featureId)
        : [...prev, featureId]
    );
  };

  if (isLoading) {
    return (
      <div className="space-y-4">
        <Skeleton className="h-20 w-full" />
        <Skeleton className="h-20 w-full" />
        <Skeleton className="h-20 w-full" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-red-500 p-4">
        Failed to load providers. Please try again later.
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h3 className="text-lg font-semibold text-white">Payment Providers</h3>
        <Button
          className="bg-blue-600 hover:bg-blue-700 text-white"
          onClick={handleAddNew}
        >
          <Plus className="w-4 h-4 mr-2" />
          Add Provider
        </Button>
      </div>

      <div className="flex flex-wrap gap-2">
        {AVAILABLE_FEATURES.map(feature => (
          <Button
            key={feature.id}
            variant="outline"
            size="sm"
            onClick={() => toggleFeatureFilter(feature.id)}
            className={`
              ${activeFilters.includes(feature.id)
                ? 'bg-blue-600/20 text-blue-100 border-blue-500/50'
                : 'bg-gray-800/50 text-gray-300 border-gray-700'
              } hover:bg-gray-800`
            }
          >
            {feature.label}
            {activeFilters.includes(feature.id) && (
              <span className="ml-2 w-2 h-2 rounded-full bg-blue-400" />
            )}
          </Button>
        ))}
      </div>

      <div className="grid gap-4">
        {filteredProviders?.map((provider) => (
          <Card key={provider.id} className="bg-gray-900 border-gray-800">
            <CardContent className="p-6">
              <div className="flex items-center justify-between">
                <div className="flex items-start gap-4">
                  {provider.logo_url && (
                    <img
                      src={provider.logo_url}
                      alt={provider.name}
                      className="w-12 h-12 object-contain"
                    />
                  )}
                  <div>
                    <h4 className="font-semibold text-white">{provider.name}</h4>
                    <p className="text-gray-400">{provider.description}</p>
                    <div className="mt-2 flex flex-wrap gap-2">
                      {AVAILABLE_FEATURES.map(feature => 
                        provider.features[feature.id as keyof Provider['features']] && (
                          <button
                            key={feature.id}
                            onClick={() => toggleFeatureFilter(feature.id)}
                            className={`
                              bg-blue-500/10 text-blue-400 text-xs px-2 py-1 rounded-full
                              hover:bg-blue-500/20 transition-colors cursor-pointer
                              ${activeFilters.includes(feature.id) ? 'ring-1 ring-blue-400' : ''}
                            `}
                          >
                            {feature.label}
                          </button>
                        )
                      )}
                    </div>
                  </div>
                </div>

                <div className="flex items-center gap-6">
                  <div className="text-right">
                    <div className="text-sm text-gray-400">Transaction Fee</div>
                    <div className="text-gray-300">
                      {provider.transaction_fee_standard}% per transaction
                    </div>
                    {provider.percentage_discount_rate && (
                      <div className="text-sm text-gray-400 mt-1">
                        Discount Rate: {provider.percentage_discount_rate}%
                      </div>
                    )}
                  </div>

                  <div className="flex items-center gap-2">
                    <Button
                      variant="outline"
                      size="sm"
                      className="text-gray-300 hover:text-white"
                      onClick={() => handleEdit(provider)}
                    >
                      <Edit className="w-4 h-4" />
                    </Button>
                    <Button
                      variant="outline"
                      size="sm"
                      className="text-red-400 hover:text-red-300"
                      onClick={() => handleDelete(provider.id)}
                    >
                      <Trash2 className="w-4 h-4" />
                    </Button>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        ))}

        {filteredProviders?.length === 0 && (
          <div className="text-center py-8 text-gray-400 bg-gray-900 rounded-lg border border-gray-800">
            No providers found
          </div>
        )}
      </div>

      {/* Edit Provider Dialog */}
      <Dialog
        open={isEditing}
        onOpenChange={(open) => {
          if (!open) {
            setIsEditing(false);
            setSelectedProvider(null);
            setFormData({});
          }
        }}
      >
        <DialogContent className="bg-gray-900 border-gray-800 max-w-xl">
          <DialogHeader>
            <DialogTitle className="text-white">Edit Provider</DialogTitle>
            <DialogDescription className="text-gray-400">
              Update provider information
            </DialogDescription>
          </DialogHeader>

          {selectedProvider && (
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <Label className="text-gray-300 flex items-center gap-1">
                  Provider Name
                  <div className="relative" title="View and manage provider details">
                    <HelpCircle className="w-4 h-4 text-gray-400" />
                    <span className="sr-only">Provider details help</span>
                  </div>
                </Label>
                <Input
                  value={formData.name || ''}
                  onChange={(e) => handleInputChange('name', e.target.value)}
                  className="bg-gray-800 border-gray-700 text-white"
                  required
                />
              </div>

              <div>
                <Label className="text-gray-300 flex items-center gap-1">
                  Description
                  <div className="relative" title="Configure provider settings">
                    <HelpCircle className="w-4 h-4 text-gray-400" />
                    <span className="sr-only">Provider settings help</span>
                  </div>
                </Label>
                <Input
                  value={formData.description || ''}
                  onChange={(e) => handleInputChange('description', e.target.value)}
                  className="bg-gray-800 border-gray-700 text-white"
                />
              </div>

              <div>
                <Label className="text-gray-300 flex items-center gap-1">
                  Logo URL
                  <div className="relative" title="View transaction history">
                    <HelpCircle className="w-4 h-4 text-gray-400" />
                    <span className="sr-only">Transaction history help</span>
                  </div>
                </Label>
                <Input
                  value={formData.logo_url || ''}
                  onChange={(e) => handleInputChange('logo_url', e.target.value)}
                  className="bg-gray-800 border-gray-700 text-white"
                />
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div>
                  <Label className="text-gray-300 flex items-center gap-1">
                    Standard Transaction Fee (%)
                    <div className="relative" title="Manage integration settings">
                      <HelpCircle className="w-4 h-4 text-gray-400" />
                      <span className="sr-only">Integration settings help</span>
                    </div>
                  </Label>
                  <Input
                    type="number"
                    value={formData.transaction_fee_standard || ''}
                    onChange={(e) =>
                      handleInputChange('transaction_fee_standard', parseFloat(e.target.value))
                    }
                    className="bg-gray-800 border-gray-700 text-white"
                    required
                    min="0"
                    step="0.01"
                  />
                </div>

                <div>
                  <Label className="text-gray-300 flex items-center gap-1">
                    Percentage Discount Rate (%)
                    <div className="relative" title="Configure payment methods">
                      <HelpCircle className="w-4 h-4 text-gray-400" />
                      <span className="sr-only">Payment methods help</span>
                    </div>
                  </Label>
                  <Input
                    type="number"
                    value={formData.percentage_discount_rate || ''}
                    onChange={(e) =>
                      handleInputChange('percentage_discount_rate', parseFloat(e.target.value))
                    }
                    className="bg-gray-800 border-gray-700 text-white"
                    min="0"
                    step="0.01"
                  />
                </div>
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div>
                  <Label className="text-gray-300 flex items-center gap-1">
                    Countries Supported
                    <div className="relative" title="Set up provider features">
                      <HelpCircle className="w-4 h-4 text-gray-400" />
                      <span className="sr-only">Provider features help</span>
                    </div>
                  </Label>
                  <Input
                    type="number"
                    value={formData.countries_supported || ''}
                    onChange={(e) =>
                      handleInputChange('countries_supported', parseInt(e.target.value))
                    }
                    className="bg-gray-800 border-gray-700 text-white"
                    required
                    min="0"
                  />
                </div>

                <div>
                  <Label className="text-gray-300 flex items-center gap-1">
                    Currencies Supported
                    <div className="relative" title="Configure security settings">
                      <HelpCircle className="w-4 h-4 text-gray-400" />
                      <span className="sr-only">Security settings help</span>
                    </div>
                  </Label>
                  <Input
                    type="number"
                    value={formData.currencies_supported || ''}
                    onChange={(e) =>
                      handleInputChange('currencies_supported', parseInt(e.target.value))
                    }
                    className="bg-gray-800 border-gray-700 text-white"
                    required
                    min="0"
                  />
                </div>
              </div>

              {/* Features */}
              <div>
                <Label className="text-gray-300 mb-2 flex items-center gap-1">
                  Features
                  <div className="relative" title="View analytics data">
                    <HelpCircle className="w-4 h-4 text-gray-400" />
                    <span className="sr-only">Analytics help</span>
                  </div>
                </Label>
                <div className="grid grid-cols-2 gap-4">
                  {AVAILABLE_FEATURES.map((feature) => (
                    <div key={feature.id} className="flex items-center space-x-2">
                      <Checkbox
                        id={feature.id}
                        checked={formData.features?.[feature.id as keyof Provider['features']] || false}
                        onCheckedChange={(checked) =>
                          handleFeatureChange(feature.id as keyof ProviderFeatures, !!checked)
                        }
                        className="border-gray-700"
                      />
                      <Label 
                        htmlFor={feature.id} 
                        className="text-gray-300 cursor-pointer"
                      >
                        {feature.label}
                      </Label>
                    </div>
                  ))}
                </div>
              </div>

              <div className="flex justify-end gap-4">
                <Button
                  type="button"
                  variant="outline"
                  onClick={() => {
                    setIsEditing(false);
                    setSelectedProvider(null);
                    setFormData({});
                  }}
                  className="text-gray-300 hover:text-white"
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  className="bg-blue-600 hover:bg-blue-700 text-white"
                >
                  Save Changes
                </Button>
              </div>
            </form>
          )}
        </DialogContent>
      </Dialog>

      {/* Add Provider Dialog */}
      <Dialog
        open={isAdding}
        onOpenChange={(open) => {
          if (!open) {
            setIsAdding(false);
            setFormData({});
          }
        }}
      >
        <DialogContent className="bg-gray-900 border-gray-800 max-w-xl">
          <DialogHeader>
            <DialogTitle className="text-white">Add New Provider</DialogTitle>
            <DialogDescription className="text-gray-400">
              Enter new provider information
            </DialogDescription>
          </DialogHeader>

          <form onSubmit={handleAddSubmit} className="space-y-4">
            <div>
              <Label className="text-gray-300 flex items-center gap-1">
                Provider Name
                <div className="relative" title="View and manage provider details">
                  <HelpCircle className="w-4 h-4 text-gray-400" />
                  <span className="sr-only">Provider details help</span>
                </div>
              </Label>
              <Input
                value={formData.name || ''}
                onChange={(e) => handleInputChange('name', e.target.value)}
                className="bg-gray-800 border-gray-700 text-white"
                required
              />
            </div>

            <div>
              <Label className="text-gray-300 flex items-center gap-1">
                Description
                <div className="relative" title="Configure provider settings">
                  <HelpCircle className="w-4 h-4 text-gray-400" />
                  <span className="sr-only">Provider settings help</span>
                </div>
              </Label>
              <Input
                value={formData.description || ''}
                onChange={(e) => handleInputChange('description', e.target.value)}
                className="bg-gray-800 border-gray-700 text-white"
              />
            </div>

            <div>
              <Label className="text-gray-300 flex items-center gap-1">
                Logo URL
                <div className="relative" title="View transaction history">
                  <HelpCircle className="w-4 h-4 text-gray-400" />
                  <span className="sr-only">Transaction history help</span>
                </div>
              </Label>
              <Input
                value={formData.logo_url || ''}
                onChange={(e) => handleInputChange('logo_url', e.target.value)}
                className="bg-gray-800 border-gray-700 text-white"
              />
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <Label className="text-gray-300 flex items-center gap-1">
                  Standard Transaction Fee (%)
                  <div className="relative" title="Manage integration settings">
                    <HelpCircle className="w-4 h-4 text-gray-400" />
                    <span className="sr-only">Integration settings help</span>
                  </div>
                </Label>
                <Input
                  type="number"
                  value={formData.transaction_fee_standard || ''}
                  onChange={(e) =>
                    handleInputChange('transaction_fee_standard', parseFloat(e.target.value))
                  }
                  className="bg-gray-800 border-gray-700 text-white"
                  required
                  min="0"
                  step="0.01"
                />
              </div>

              <div>
                <Label className="text-gray-300 flex items-center gap-1">
                  Percentage Discount Rate (%)
                  <div className="relative" title="Configure payment methods">
                    <HelpCircle className="w-4 h-4 text-gray-400" />
                    <span className="sr-only">Payment methods help</span>
                  </div>
                </Label>
                <Input
                  type="number"
                  value={formData.percentage_discount_rate || ''}
                  onChange={(e) =>
                    handleInputChange('percentage_discount_rate', parseFloat(e.target.value))
                  }
                  className="bg-gray-800 border-gray-700 text-white"
                  min="0"
                  step="0.01"
                />
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <Label className="text-gray-300 flex items-center gap-1">
                  Countries Supported
                  <div className="relative" title="Set up provider features">
                    <HelpCircle className="w-4 h-4 text-gray-400" />
                    <span className="sr-only">Provider features help</span>
                  </div>
                </Label>
                <Input
                  type="number"
                  value={formData.countries_supported || ''}
                  onChange={(e) =>
                    handleInputChange('countries_supported', parseInt(e.target.value))
                  }
                  className="bg-gray-800 border-gray-700 text-white"
                  required
                  min="0"
                />
              </div>

              <div>
                <Label className="text-gray-300 flex items-center gap-1">
                  Currencies Supported
                  <div className="relative" title="Configure security settings">
                    <HelpCircle className="w-4 h-4 text-gray-400" />
                    <span className="sr-only">Security settings help</span>
                  </div>
                </Label>
                <Input
                  type="number"
                  value={formData.currencies_supported || ''}
                  onChange={(e) =>
                    handleInputChange('currencies_supported', parseInt(e.target.value))
                  }
                  className="bg-gray-800 border-gray-700 text-white"
                  required
                  min="0"
                />
              </div>
            </div>

            {/* Features */}
            <div>
              <Label className="text-gray-300 mb-2 flex items-center gap-1">
                Features
                <div className="relative" title="View analytics data">
                  <HelpCircle className="w-4 h-4 text-gray-400" />
                  <span className="sr-only">Analytics help</span>
                </div>
              </Label>
              <div className="grid grid-cols-2 gap-4">
                {AVAILABLE_FEATURES.map((feature) => (
                  <div key={feature.id} className="flex items-center space-x-2">
                    <Checkbox
                      id={feature.id}
                      checked={formData.features?.[feature.id as keyof Provider['features']] || false}
                      onCheckedChange={(checked) =>
                        handleFeatureChange(feature.id as keyof ProviderFeatures, !!checked)
                      }
                      className="border-gray-700"
                    />
                    <Label 
                      htmlFor={feature.id} 
                      className="text-gray-300 cursor-pointer"
                    >
                      {feature.label}
                    </Label>
                  </div>
                ))}
              </div>
            </div>

            <div className="flex justify-end gap-4">
              <Button
                type="button"
                variant="outline"
                onClick={() => {
                  setIsAdding(false);
                  setFormData({});
                }}
                className="text-gray-300 hover:text-white"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="bg-blue-600 hover:bg-blue-700 text-white"
              >
                Add Provider
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AdminProviderList;
