import React, { useState } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Legend,
} from 'recharts';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from '@/components/ui/card';
import {
  useGetDailyAnalyticsQuery,
  useGetProviderAnalyticsQuery,
} from '@/store/api/rootApi';
import { Loader2, AlertCircle } from 'lucide-react';

const COLORS = ['#3B82F6', '#10B981', '#F59E0B', '#EF4444', '#8B5CF6', '#EC4899'];

interface ProviderAnalyticsData {
  id: string;
  created_at: string;
  provider_id: string;
  provider_name: string;
  total_revenue: number;
}

interface ChartData {
  name: string;
  value: number;
}

export const AdminAnalytics: React.FC = () => {
  const [dateRange] = useState(() => {
    const end = new Date();
    const start = new Date();
    start.setDate(start.getDate() - 30);
    
    return {
      start: `${start.toISOString().split('T')[0]}T00:00:00Z`,
      end: `${end.toISOString().split('T')[0]}T23:59:59Z`
    };
  });

  const { data: dailyAnalytics, isLoading: isDailyLoading, error: dailyError } = useGetDailyAnalyticsQuery({
    start: dateRange.start,
    end: dateRange.end,
  });

  
  const { data: providerAnalytics, isLoading: isProviderLoading, error: providerError } = useGetProviderAnalyticsQuery({
    start: dateRange.start,
    end: dateRange.end,
  });

  if (isDailyLoading || isProviderLoading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="text-center space-y-4">
          <Loader2 className="w-8 h-8 animate-spin text-blue-400 mx-auto" />
          <p className="text-gray-300">Loading analytics data...</p>
        </div>
      </div>
    );
  }

  if (dailyError || providerError) {
    console.error('Analytics Error:', {
      dailyError,
      providerError,
      dateRange
    });
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="text-center space-y-4">
          <AlertCircle className="w-8 h-8 text-red-400 mx-auto" />
          <p className="text-gray-300">Failed to load analytics data</p>
          <p className="text-sm text-gray-400">
            {(dailyError as any)?.message || (providerError as any)?.message || 'Unknown error occurred'}
          </p>
        </div>
      </div>
    );
  }

  if (!dailyAnalytics?.length || !providerAnalytics?.length) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="text-center space-y-4">
          <AlertCircle className="w-8 h-8 text-yellow-400 mx-auto" />
          <p className="text-gray-300">No analytics data available</p>
        </div>
      </div>
    );
  }

  // Transform data for charts
  const revenueData: ChartData[] = (dailyAnalytics || []).map((day) => ({
    name: new Date(day.date).toLocaleDateString(),
    value: Number(day.total_revenue) || 0,
  }));

  const providerData: ChartData[] = (providerAnalytics || []).reduce((acc: ChartData[], provider: ProviderAnalyticsData) => {
    if (!provider.provider_name) return acc;
    
    const existing = acc.find((item) => item.name === provider.provider_name);
    if (existing) {
      existing.value += Number(provider.total_revenue) || 0;
    } else {
      acc.push({
        name: provider.provider_name,
        value: Number(provider.total_revenue) || 0,
      });
    }
    return acc;
  }, []);

  return (
    <div className="space-y-6">
      <div className="grid md:grid-cols-2 gap-6">
        <Card className="bg-gray-900 border-gray-800">
          <CardHeader>
            <CardTitle className="text-white">Revenue Trend</CardTitle>
            <CardDescription className="text-gray-300">Last 30 days revenue analysis</CardDescription>
          </CardHeader>
          <CardContent>
            <div className="h-[300px]">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={revenueData}>
                  <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
                  <XAxis dataKey="name" stroke="#9CA3AF" />
                  <YAxis stroke="#9CA3AF" />
                  <Tooltip
                    contentStyle={{
                      backgroundColor: '#1F2937',
                      border: '1px solid #374151',
                      color: '#fff'
                    }}
                  />
                  <Line
                    type="monotone"
                    dataKey="value"
                    stroke="#3B82F6"
                    strokeWidth={2}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </CardContent>
        </Card>

        <Card className="bg-gray-900 border-gray-800">
          <CardHeader>
            <CardTitle className="text-white">Provider Distribution</CardTitle>
            <CardDescription className="text-gray-300">Revenue by provider</CardDescription>
          </CardHeader>
          <CardContent>
            <div className="h-[300px]">
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={providerData}
                    cx="50%"
                    cy="50%"
                    innerRadius={60}
                    outerRadius={80}
                    fill="#8884d8"
                    paddingAngle={5}
                    dataKey="value"
                    label={({ name }) => name}
                  >
                    {providerData.map((_, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                  <Tooltip
                    contentStyle={{
                      backgroundColor: '#1F2937',
                      border: '1px solid #374151',
                      color: '#fff'
                    }}
                  />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </div>
          </CardContent>
        </Card>
      </div>

      <div className="grid md:grid-cols-3 gap-6">
        <Card className="bg-gray-900 border-gray-800">
          <CardContent className="p-6">
            <div className="space-y-2">
              <h4 className="text-sm text-gray-300">Total Revenue</h4>
              <div className="text-2xl font-bold text-white">
                ${dailyAnalytics.reduce((sum, day) => 
                  sum + (Number(day.total_revenue) || 0), 0).toFixed(2)}
              </div>
              <div className="text-sm text-gray-300">Last 30 days</div>
            </div>
          </CardContent>
        </Card>

        <Card className="bg-gray-900 border-gray-800">
          <CardContent className="p-6">
            <div className="space-y-2">
              <h4 className="text-sm text-gray-300">New Businesses</h4>
              <div className="text-2xl font-bold text-white">
                {dailyAnalytics.reduce((sum, day) => 
                  sum + (Number(day.new_businesses) || 0), 0)}
              </div>
              <div className="text-sm text-gray-300">Last 30 days</div>
            </div>
          </CardContent>
        </Card>

        <Card className="bg-gray-900 border-gray-800">
          <CardContent className="p-6">
            <div className="space-y-2">
              <h4 className="text-sm text-gray-300">Provider Switches</h4>
              <div className="text-2xl font-bold text-white">
                {dailyAnalytics.reduce((sum, day) => 
                  sum + (Number(day.provider_switches) || 0), 0)}
              </div>
              <div className="text-sm text-gray-300">Last 30 days</div>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};
