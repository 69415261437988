import React, { useState, useMemo } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import {
  CreditCard,
  Zap,
  Shield,
  Percent,
  ArrowRight,
  CheckCircle,
} from 'lucide-react';
import { Button } from './ui/button';

// Animation Variants
const posTerminalVariants = {
  processing: {
    scale: [1, 1.02, 1],
    transition: {
      duration: 2,
      repeat: Infinity,
      ease: 'easeInOut',
    },
  },
};

interface Transaction {
  id: number;
  amount: number;
  time: string;
  provider: string;
  charge: number;
  status: 'succeeded' | 'processing' | 'failed';
  customer: string;
  item: string;
  cardType?: string;
}

const partners = [
  { name: 'Stripe', logo: '/images/partners/stripe.svg' },
  { name: 'PayPal', logo: '/images/partners/paypal.svg' },
  { name: 'Square', logo: '/images/partners/square.svg' },
  { name: 'Adyen', logo: '/images/partners/adyen.svg' },
  { name: 'Klarna', logo: '/images/partners/klarna.svg' },
  { name: 'Wise', logo: '/images/partners/wise.svg' },
  { name: 'Checkout.com', logo: '/images/partners/checkout.svg' },
  { name: 'WorldPay', logo: '/images/partners/worldpay.svg' },
  { name: 'Amazon Pay', logo: '/images/partners/amazonpay.svg' },
  { name: 'Google Pay', logo: '/images/partners/googlepay.svg' },
];

export const Hero: React.FC = () => {
  const navigate = useNavigate();
  const [amount, setAmount] = useState<string>('0');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState<'idle' | 'processing' | 'success' | 'error'>('idle');

  const stats = [
    { icon: Shield, text: 'Merchants Supported', highlight: '5,000+' },
    { icon: Zap, text: 'Average Fee Savings', highlight: '60%' },
    { icon: Percent, text: 'Cashback on Fees', highlight: '5%' },
  ];

  const recentTransactions: Transaction[] = [
    {
      id: 1,
      amount: 156.99,
      time: '2 mins ago',
      provider: 'Stripe',
      charge: 4.85,
      status: 'succeeded',
      customer: 'Sarah M.',
      item: 'Electronics',
      cardType: 'VISA ****4582',
    },
    {
      id: 2,
      amount: 89.99,
      time: '5 mins ago',
      provider: 'Stripe',
      charge: 3.12,
      status: 'processing',
      customer: 'John D.',
      item: 'Groceries',
      cardType: 'MC ****7845',
    },
    {
      id: 3,
      amount: 299.99,
      time: 'Just now',
      provider: 'Stripe',
      charge: 8.7,
      status: 'succeeded',
      customer: 'Alex K.',
      item: 'Fashion',
      cardType: 'AMEX ****3456',
    },
    {
      id: 4,
      amount: 42.5,
      time: '1 min ago',
      provider: 'Stripe',
      charge: 1.48,
      status: 'succeeded',
      customer: 'Emma R.',
      item: 'Food & Beverage',
      cardType: 'VISA ****9012',
    },
  ];

  const handleNumberClick = (value: string) => {
    console.log('handleNumberClick called with:', value);
    try {
      if (value === '⌫') {
        console.log('Handling backspace');
        setAmount(prev => {
          const newValue = prev.slice(0, -1) || '0';
          console.log('New amount after backspace:', newValue);
          return newValue;
        });
        setErrorMessage('');
        return;
      }

      if (value === '') return;

      setAmount(prev => {
        console.log('Previous amount:', prev);
        const newAmount = prev === '0' ? value : prev + value;
        console.log('New amount:', newAmount);
        
        if (parseFloat(newAmount) > 999999.99) {
          setErrorMessage('Maximum amount exceeded');
          return prev;
        }

        if (newAmount.includes('.')) {
          const [, decimal] = newAmount.split('.');
          if (decimal && decimal.length > 2) return prev;
        }

        setErrorMessage('');
        return newAmount;
      });
    } catch (error) {
      setErrorMessage('Invalid input');
      console.error('Error processing input:', error);
    }
  };

  const formattedAmount = useMemo(() => {
    try {
      const num = parseFloat(amount);
      return isNaN(num) ? '$0.00' : `$${num.toFixed(2)}`;
    } catch (error) {
      console.error('Error formatting amount:', error);
      return '$0.00';
    }
  }, [amount]);

  const handlePayment = async (method: 'Tap' | 'Insert' | 'Swipe') => {
    console.log(`Processing payment via ${method}`);
    
    if (amount === '0' || isProcessing) return;
    
    try {
      setIsProcessing(true);
      setPaymentStatus('processing');
      
      // Simulate payment processing
      await new Promise(resolve => setTimeout(resolve, 2000));
      
      // Simulate success (90% success rate)
      const isSuccess = Math.random() < 0.9;
      
      if (isSuccess) {
        setPaymentStatus('success');
        // Wait 1 second to show success before resetting
        await new Promise(resolve => setTimeout(resolve, 1000));
        // Reset all states to initial values
        setAmount('0');
        setPaymentStatus('idle');
        setErrorMessage('');
      } else {
        setPaymentStatus('error');
        setErrorMessage(`Payment failed via ${method}. Please try again.`);
        // Error clears after 2 seconds
        setTimeout(() => {
          setErrorMessage('');
          setPaymentStatus('idle');
        }, 2000);
      }
    } catch (error) {
      setPaymentStatus('error');
      setErrorMessage(`Payment failed via ${method}. Please try again.`);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="relative overflow-hidden bg-black text-white min-h-screen">
      {/* Background Animations */}
      <div className="absolute inset-0">
        <motion.div
          className="absolute inset-0 bg-gradient-to-r from-blue-600/50 to-purple-600/50"
          animate={{ opacity: [0.5, 0.7, 0.5] }}
          transition={{ duration: 10, repeat: Infinity, ease: 'easeInOut' }}
        />
        <motion.div
          className="absolute inset-0 bg-grid-white/[0.05]"
          animate={{ backgroundPosition: ['0% 0%', '100% 100%'] }}
          transition={{ duration: 20, repeat: Infinity, ease: 'linear' }}
        />
        <motion.div
          className="absolute inset-0"
          style={{
            backgroundImage:
              'radial-gradient(at top left, rgba(56, 189, 248, 0.15), transparent 70%), radial-gradient(at bottom right, rgba(168, 85, 247, 0.15), transparent 70%)',
          }}
          animate={{ backgroundPosition: ['0% 0%', '100% 100%'] }}
          transition={{ duration: 15, repeat: Infinity, ease: 'linear' }}
        />
      </div>

      <div className="container mx-auto px-4 py-8 sm:py-12 md:py-16 lg:py-24 relative z-10">
        <div className="grid lg:grid-cols-2 gap-6 md:gap-8 lg:gap-12 items-center">
          {/* Left Side */}
          <motion.div>
            {/* Badge */}
            <motion.div className="flex items-center gap-2 mb-6 bg-white/10 w-fit px-4 py-2 rounded-full backdrop-blur-sm">
              <CreditCard className="w-4 h-4 text-blue-400" />
              <span className="text-xs sm:text-sm">
                Partnered with 50+ leading payment providers
              </span>
              <motion.span
                className="inline-block w-2 h-2 bg-blue-400 rounded-full"
                animate={{ scale: [1, 1.2, 1] }}
                transition={{ duration: 2, repeat: Infinity }}
              />
            </motion.div>

            {/* Heading */}
            <div className="relative mb-6">
              <motion.h1 className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold leading-tight">
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400">
                  Find Your Best
                </span>
                <br />
                <span className="relative">
                  Payment Rates
                  <motion.div
                    className="absolute -bottom-2 left-0 h-2 bg-gradient-to-r from-blue-400 to-purple-400 rounded-full"
                    initial={{ width: 0 }}
                    animate={{ width: '100%' }}
                    transition={{ delay: 0.8, duration: 0.8 }}
                  />
                </span>
              </motion.h1>
            </div>

            {/* Subheading */}
            <motion.p className="text-base sm:text-lg md:text-xl text-gray-300 mb-8 max-w-lg">
              Compare and access exclusive rates from{' '}
              <span className="text-blue-400 font-semibold">
                leading payment providers starting at 0.1%
              </span>
              . We negotiate with{' '}
              <span className="text-purple-400 font-semibold">Stripe, PayPal, Square</span>
              {' '}and others to secure{' '}
              <span className="text-blue-400 font-semibold">
                the best possible terms
              </span>
              .
            </motion.p>

            {/* Call to Action Buttons */}
            <div className="flex flex-col sm:flex-row gap-4 mb-8 sm:mb-12">
              <motion.div>
                <Button
                  size="lg"
                  className="w-full sm:w-auto bg-gradient-to-r from-blue-500 to-purple-500 hover:from-blue-600 hover:to-purple-600 shadow-lg hover:shadow-blue-500/25"
                  onClick={() => navigate('/onboarding')}
                >
                  Get Started
                  <ArrowRight className="ml-2 w-4 h-4" />
                </Button>
              </motion.div>
              <motion.div>
                <Button
                  size="lg"
                  variant="outline"
                  className="w-full sm:w-auto border-gray-700 text-white hover:bg-white/10 backdrop-blur-sm"
                  onClick={() => navigate('/compare')}
                >
                  View All Providers
                </Button>
              </motion.div>
            </div>

            {/* Stats */}
            <div className="grid grid-cols-3 gap-3 sm:gap-4 mt-8 sm:mt-0">
              {stats.map(({ icon: Icon, text, highlight }, index) => (
                <motion.div
                  key={text}
                  className="bg-white/5 rounded-lg p-3 sm:p-4 backdrop-blur-sm"
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.6 + index * 0.1 }}
                >
                  <Icon
                    className="w-5 h-5 sm:w-6 sm:h-6 text-blue-400 mb-2"
                    aria-hidden="true"
                  />
                  <p className="text-xs sm:text-sm text-gray-300">{text}</p>
                  <p className="text-sm sm:text-lg font-semibold text-blue-400">
                    {highlight}
                  </p>
                </motion.div>
              ))}
            </div>

            {/* Additional benefits section */}
            <motion.div className="mt-6 bg-white/5 rounded-lg p-4 backdrop-blur-sm">
              <ul className="space-y-2 text-sm text-gray-300">
                <li className="flex items-center gap-2">
                  <CheckCircle className="w-4 h-4 text-green-400" />
                  Free provider comparison & consultation
                </li>
                <li className="flex items-center gap-2">
                  <CheckCircle className="w-4 h-4 text-green-400" />
                  Negotiated setup fee waivers
                </li>
                <li className="flex items-center gap-2">
                  <CheckCircle className="w-4 h-4 text-green-400" />
                  Exclusive provider hardware discounts
                </li>
              </ul>
            </motion.div>
          </motion.div>

          {/* Right Side */}
          <div className="relative w-full max-w-2xl mx-auto lg:mx-0">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {/* POS Terminal Animation */}
              <motion.div
                className="relative w-full h-full min-h-[400px] sm:min-h-[500px] md:min-h-[600px]"
                variants={posTerminalVariants}
                animate="processing"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: 0.8 }}
              >
                {/* POS Terminal Component */}
                <div className="absolute inset-0 bg-gradient-to-br from-gray-800 to-gray-900 rounded-2xl shadow-2xl transform rotate-0 md:rotate-12 overflow-hidden">
                  {/* Device Frame */}
                  <div className="relative h-full bg-gray-900 rounded-xl border border-gray-700/50 overflow-hidden">
                    {/* Main Screen */}
                    <div className="p-2 sm:p-4">
                      {/* Amount Display */}
                      <div className="bg-gradient-to-br from-gray-800 to-gray-900 rounded-lg p-3 sm:p-6 mb-2 sm:mb-4 border border-gray-700/30">
                        <div className="text-center space-y-2">
                          <span className="text-sm text-gray-400">
                            Amount Due
                          </span>
                          <motion.div
                            className={`text-4xl font-bold bg-gradient-to-r 
                              ${paymentStatus === 'success' 
                                ? 'from-green-400 to-green-500' 
                                : paymentStatus === 'error'
                                ? 'from-red-400 to-red-500'
                                : 'from-blue-400 to-purple-400'
                              } bg-clip-text text-transparent`}
                            animate={{ scale: [1, 1.02, 1] }}
                            transition={{ duration: 2, repeat: Infinity }}
                          >
                            {formattedAmount}
                          </motion.div>
                          {errorMessage && (
                            <span className="text-xs text-red-400">
                              {errorMessage}
                            </span>
                          )}
                          <span className="text-xs text-gray-500">
                            {paymentStatus === 'processing' 
                              ? 'Processing payment...' 
                              : paymentStatus === 'success'
                              ? 'Payment successful!'
                              : paymentStatus === 'error'
                              ? 'Payment failed'
                              : 'Please select payment method'}
                          </span>
                        </div>

                        {/* Processing Animation */}
                        <motion.div className="mt-6 h-1.5 bg-gray-700 rounded-full overflow-hidden">
                          <motion.div
                            className={`h-full bg-gradient-to-r 
                              ${paymentStatus === 'success'
                                ? 'from-green-400 to-green-500'
                                : paymentStatus === 'error'
                                ? 'from-red-400 to-red-500'
                                : 'from-blue-400 via-purple-400 to-blue-400'
                              }`}
                            animate={{
                              x: isProcessing ? [-200, 400] : 0,
                            }}
                            transition={{
                              duration: 3,
                              repeat: isProcessing ? Infinity : 0,
                              ease: 'linear',
                            }}
                          />
                        </motion.div>
                      </div>

                      {/* Payment Methods */}
                      <div className="grid grid-cols-3 gap-2 sm:gap-3 mb-3 sm:mb-6">
                        {[
                          { method: 'Tap' as const, icon: '📱' },
                          { method: 'Insert' as const, icon: '💳' },
                          { method: 'Swipe' as const, icon: '⟷' },
                        ].map(({ method, icon }) => (
                          <motion.button
                            key={method}
                            type="button"
                            onClick={() => handlePayment(method)}
                            disabled={isProcessing || amount === '0'}
                            className="bg-gray-800/30 h-10 sm:h-14 rounded-lg flex flex-col items-center justify-center border border-gray-700/20 relative overflow-hidden hover:bg-gray-700/30 active:bg-gray-600/30 transition-colors cursor-pointer z-50"
                            whileHover={{
                              scale: 1.05,
                              backgroundColor: 'rgba(59, 130, 246, 0.1)',
                            }}
                            whileTap={{ scale: 0.95 }}
                          >
                            <span className="text-gray-300 font-medium text-base sm:text-lg z-10 relative">
                              {icon}
                            </span>
                            <span className="text-xs text-gray-400 mt-1 z-10 relative">
                              {method}
                            </span>
                            {isProcessing && (
                              <motion.div
                                className="absolute inset-0 bg-blue-400/10"
                                animate={{ opacity: [0.5, 0.8, 0.5] }}
                                transition={{ duration: 1, repeat: Infinity }}
                              />
                            )}
                          </motion.button>
                        ))}
                      </div>

                      {/* Keypad */}
                      <div className="grid grid-cols-3 gap-2 sm:gap-3">
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, '.', 0, '⌫'].map(
                          (key, i) => (
                            <motion.button
                              key={i}
                              type="button"
                              onClick={() => {
                                console.log('Button clicked:', key);
                                handleNumberClick(key.toString());
                              }}
                              className="bg-gray-800/30 h-10 sm:h-14 rounded-lg flex items-center justify-center border border-gray-700/20 relative overflow-hidden hover:bg-gray-700/30 active:bg-gray-600/30 transition-colors cursor-pointer z-50"
                              whileHover={{
                                scale: 1.05,
                                backgroundColor: 'rgba(59, 130, 246, 0.1)',
                              }}
                              whileTap={{ scale: 0.95 }}
                            >
                              <span className="text-gray-300 font-medium text-base sm:text-lg z-10 relative">
                                {key}
                              </span>
                            </motion.button>
                          )
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Ambient Light Effects */}
                  <motion.div
                    className="absolute -inset-4 bg-gradient-to-r from-blue-500/10 via-purple-500/10 to-blue-500/10 rounded-full blur-xl"
                    animate={{
                      scale: [1, 1.2, 1],
                      opacity: [0.3, 0.5, 0.3],
                    }}
                    transition={{
                      duration: 3,
                      repeat: Infinity,
                      ease: 'linear',
                    }}
                  />
                </div>
              </motion.div>

              {/* Transactions List */}
              <motion.div
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.8 }}
                className="w-full bg-gray-900/50 backdrop-blur-sm rounded-lg p-3 sm:p-4 border border-gray-800"
              >
                {/* Transactions Header */}
                <div className="flex items-center justify-between mb-4">
                  <div className="flex items-center gap-2">
                    <motion.div
                      animate={{ scale: [1, 1.2, 1] }}
                      transition={{ duration: 2, repeat: Infinity }}
                      className="w-2 h-2 bg-green-400 rounded-full"
                    />
                    <h3 className="text-sm font-medium text-gray-300">
                      Live Transactions
                    </h3>
                  </div>
                  <div className="flex items-center gap-2">
                    <span className="text-xs text-gray-500">
                      Last Updated:
                    </span>
                    <motion.span
                      className="text-xs text-blue-400"
                      animate={{ opacity: [1, 0.7, 1] }}
                      transition={{ duration: 1, repeat: Infinity }}
                    >
                      Just now
                    </motion.span>
                  </div>
                </div>

                {/* Transactions List */}
                <div className="space-y-2 sm:space-y-3 max-h-[300px] sm:max-h-[400px] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-700 scrollbar-track-gray-900">
                  {recentTransactions.map((transaction, index) => (
                    <motion.div
                      key={transaction.id}
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.7 + index * 0.1 }}
                      className="relative bg-gray-800/50 rounded-lg p-2 sm:p-3 border border-gray-700/50 hover:bg-gray-800/70 transition-colors text-sm sm:text-base"
                    >
                      {/* Status Indicator */}
                      <div
                        className={`absolute left-0 top-0 bottom-0 w-1 rounded-l-lg ${
                          transaction.status === 'succeeded'
                            ? 'bg-green-400'
                            : transaction.status === 'processing'
                            ? 'bg-blue-400'
                            : 'bg-red-400'
                        }`}
                      />
                      <div className="flex items-center justify-between mb-2">
                        <div className="flex items-center gap-3">
                          <span className="text-sm font-medium text-white">
                            ${transaction.amount.toFixed(2)}
                          </span>
                          <span
                            className={`text-xs px-2 py-0.5 rounded-full ${
                              transaction.status === 'succeeded'
                                ? 'bg-green-400/20 text-green-400'
                                : transaction.status === 'processing'
                                ? 'bg-blue-400/20 text-blue-400'
                                : 'bg-red-400/20 text-red-400'
                            }`}
                          >
                            {transaction.status}
                          </span>
                        </div>
                        <div className="flex items-center gap-2">
                          <span className="text-xs text-gray-500">
                            {transaction.time}
                          </span>
                          {transaction.status === 'processing' && (
                            <motion.div
                              animate={{ rotate: 360 }}
                              transition={{
                                duration: 2,
                                repeat: Infinity,
                                ease: 'linear',
                              }}
                            >
                              ⟳
                            </motion.div>
                          )}
                        </div>
                      </div>
                      <div className="grid grid-cols-2 gap-2 mb-2">
                        <div className="text-xs text-gray-400">
                          <span className="text-gray-500">Customer:</span>{' '}
                          {transaction.customer}
                        </div>
                        <div className="text-xs text-gray-400 text-right">
                          {transaction.cardType}
                        </div>
                      </div>
                      <div className="flex items-center justify-between">
                        <div className="flex items-center gap-2">
                          <span className="text-xs text-gray-400">
                            {transaction.provider}
                          </span>
                          <motion.div
                            className="text-xs px-2 py-0.5 rounded-full bg-red-500/20 text-red-400"
                            initial={{ scale: 0.8 }}
                            animate={{ scale: 1 }}
                            transition={{
                              delay: 0.8 + index * 0.1,
                            }}
                          >
                            Fee: ${transaction.charge.toFixed(2)}
                          </motion.div>
                        </div>
                        <span className="text-xs text-gray-400">
                          {transaction.item}
                        </span>
                      </div>
                    </motion.div>
                  ))}
                </div>

                {/* Summary Stats */}
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 1 }}
                  className="mt-3 sm:mt-4 pt-3 sm:pt-4 border-t border-gray-700/50"
                >
                  <div className="grid grid-cols-3 gap-2 sm:gap-4">
                    <div className="text-center">
                      <div className="text-xs text-gray-500">
                        Today's Volume
                      </div>
                      <div className="text-sm font-medium text-white">
                        $589.47
                      </div>
                    </div>
                    <div className="text-center">
                      <div className="text-xs text-gray-500">Total Fees</div>
                      <motion.div
                        className="text-sm font-medium text-red-400"
                        animate={{ opacity: [1, 0.8, 1] }}
                        transition={{ duration: 2, repeat: Infinity }}
                      >
                        $18.15
                      </motion.div>
                    </div>
                    <div className="text-center">
                      <div className="text-xs text-gray-500">
                        Potential Savings
                      </div>
                      <div className="text-sm font-medium text-green-400">
                        $7.26
                      </div>
                    </div>
                  </div>
                </motion.div>
              </motion.div>
            </div>
          </div>
        </div>
      </div>

      {/* Partner Logos Section */}
      <div className="mt-8 sm:mt-12 relative overflow-hidden py-3 sm:py-4 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900">
        <div className="absolute inset-0 bg-grid-white/[0.02]" />

        {/* Gradient Overlays */}
        <div className="absolute left-0 top-0 bottom-0 w-24 bg-gradient-to-r from-black to-transparent z-10" />
        <div className="absolute right-0 top-0 bottom-0 w-24 bg-gradient-to-l from-black to-transparent z-10" />

        {/* Scrolling Container */}
        <div className="flex overflow-hidden">
          <motion.div
            className="flex gap-4 sm:gap-8 items-center"
            animate={{
              x: ['0%', '-50%'],
            }}
            transition={{
              duration: 20,
              ease: 'linear',
              repeat: Infinity,
            }}
          >
            {[...partners, ...partners].map((partner, idx) => (
              <div
                key={`${partner.name}-${idx}`}
                className="flex-shrink-0 relative group"
              >
                <motion.div
                  whileHover={{ scale: 1.02 }}
                  className="relative w-16 sm:w-24 md:w-32 h-8 sm:h-12 md:h-16 flex items-center justify-center"
                >
                  <img
                    src={partner.logo}
                    alt={`${partner.name} logo`}
                    className="max-w-full max-h-full object-contain opacity-50 group-hover:opacity-100 transition-opacity duration-300 grayscale group-hover:grayscale-0"
                  />
                </motion.div>
              </div>
            ))}
          </motion.div>
        </div>
      </div>
    </div>
  );
};
