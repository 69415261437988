import React from 'react';
import { motion } from 'framer-motion';
import { DollarSign, CreditCard, Building2, Shield, Zap, Globe } from 'lucide-react';
import { Card, CardContent } from './ui/card';

// Add type definition for feature items
interface Feature {
  icon: React.ElementType;
  title: string;
  description: string;
}

const features: Feature[] = [
  {
    icon: DollarSign,
    title: 'Exclusive Provider Rates',
    description: 'We negotiate directly with payment providers to secure rates as low as 0.1%. Save up to 60% compared to standard provider pricing.'
  },
  {
    icon: Shield,
    title: 'Unbiased Recommendations',
    description: 'Get provider-agnostic advice based on your business needs. Compare security features, uptime guarantees, and fraud protection across providers.'
  },
  {
    icon: Zap,
    title: 'Streamlined Setup',
    description: 'Skip the lengthy provider negotiations. Get instant comparisons and same-day account setup with your chosen payment provider.'
  },
  {
    icon: Globe,
    title: 'Provider Network Access',
    description: 'Choose from 50+ leading providers including Stripe, PayPal, and Square. Accept 135+ currencies through your preferred provider.'
  },
  {
    icon: CreditCard,
    title: 'Enhanced Provider Benefits',
    description: 'Access exclusive perks through our partnerships: 5% cashback on transaction fees, waived setup costs, and hardware discounts.'
  },
  {
    icon: Building2,
    title: 'Integration Support',
    description: 'Free consultation on provider selection, technical integration assistance, and ongoing support for your payment operations.'
  }
];

export const Features = () => {
  return (
    <section className="py-12 md:py-20 relative overflow-hidden bg-gradient-to-b from-black to-gray-900">
      <div className="absolute inset-0 bg-grid-white/[0.02] -z-10" aria-hidden="true" />
      <div className="container mx-auto px-4 sm:px-6">
        <div className="text-center mb-10 md:mb-16">
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            className="text-3xl sm:text-4xl md:text-5xl font-bold mb-4 md:mb-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 via-purple-400 to-pink-400"
          >
            Find Your Perfect Payment Provider
          </motion.h2>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.2 }}
            className="text-gray-400 max-w-3xl mx-auto text-base sm:text-lg px-4"
          >
            We help you navigate the payment provider landscape to find the best solution for your business. Compare rates, features, and benefits across leading providers - all in one place.
          </motion.p>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6 lg:gap-8">
          {features.map((feature, index) => (
            <motion.div
              key={feature.title}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true, margin: "30px" }}
              transition={{ delay: Math.min(index * 0.1, 0.3) }}
            >
              <Card className="h-full hover:shadow-2xl transition-all duration-300 bg-gradient-to-br from-gray-900 to-gray-800 border-gray-800">
                <CardContent className="p-5 sm:p-6 md:p-8">
                  <div 
                    className="bg-gradient-to-r from-blue-500/20 to-purple-500/20 w-12 h-12 sm:w-14 sm:h-14 rounded-xl flex items-center justify-center mb-4 sm:mb-6"
                    aria-hidden="true"
                  >
                    <feature.icon className="w-6 h-6 sm:w-7 sm:h-7 text-blue-400" aria-hidden="true" />
                  </div>
                  <h3 className="text-lg sm:text-xl font-bold mb-2 sm:mb-3 text-white">{feature.title}</h3>
                  <p className="text-gray-400 text-sm sm:text-base leading-relaxed">{feature.description}</p>
                </CardContent>
              </Card>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};