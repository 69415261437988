import React from 'react';
import { format } from 'date-fns';
import { CheckCircle2, XCircle, Clock, Download } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import { Badge } from '@/components/ui/badge';
import { useGetDocumentsQuery, useUpdateDocumentStatusMutation } from '@/store/api/rootApi';
import type { Document } from '@/store/api/rootApi';
import toast from 'react-hot-toast';

interface AdminSubmissionsProps {
  searchQuery: string;
  dateFilter: string;
}

export const AdminSubmissions: React.FC<AdminSubmissionsProps> = ({
  searchQuery,
  dateFilter
}) => {
  const { data: documents, isLoading } = useGetDocumentsQuery();
  const [updateStatus] = useUpdateDocumentStatusMutation();

  const filteredSubmissions = documents?.filter(doc => {
    const matchesSearch = 
      doc.merchant?.full_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      doc.merchant?.company_name?.toLowerCase()?.includes(searchQuery.toLowerCase()) || 
      false;

    if (!matchesSearch) return false;

    const submissionDate = new Date(doc.created_at);
    const now = new Date();

    switch (dateFilter) {
      case 'today': {
        return submissionDate.toDateString() === now.toDateString();
      }
      case 'week': {
        const weekAgo = new Date(now.getTime());
        weekAgo.setDate(weekAgo.getDate() - 7);
        return submissionDate >= weekAgo;
      }
      case 'month': {
        const monthAgo = new Date(now.getTime()); 
        monthAgo.setMonth(monthAgo.getMonth() - 1);
        return submissionDate >= monthAgo;
      }
      default:
        return true;
    }
  });

  const getStatusIcon = (status: Document['status']) => {
    switch (status) {
      case 'verified':
        return <CheckCircle2 className="w-4 h-4 text-green-400" />;
      case 'rejected':
        return <XCircle className="w-4 h-4 text-red-400" />;
      default:
        return <Clock className="w-4 h-4 text-yellow-400" />;
    }
  };

  const handleStatusUpdate = async (id: string, status: Document['status']) => {
    try {
      await updateStatus({
        id,
        status,
        reason: status === 'rejected' ? 'Document does not meet requirements' : undefined
      }).unwrap();
      toast.success(`Document ${status} successfully`);
    } catch (error) {
      console.error('Error updating document status:', error);
      toast.error('Failed to update document status');
    }
  };

  const handleDownload = (url: string, filename: string) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center p-8">
        <div className="text-gray-400">Loading submissions...</div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h3 className="text-lg font-semibold">Document Submissions</h3>
        <Button variant="outline">
          <Download className="w-4 h-4 mr-2" />
          Export List
        </Button>
      </div>

      <div className="space-y-4">
        {filteredSubmissions?.map((submission) => (
          <Card key={submission.id}>
            <CardContent className="p-6">
              <div className="flex items-center justify-between">
                <div>
                  <div className="flex items-center gap-4 mb-2">
                    <h4 className="font-semibold">
                      {submission.merchant?.company_name || submission.merchant?.full_name}
                    </h4>
                    <Badge
                      variant={
                        submission.status === 'verified'
                          ? 'default'
                          : submission.status === 'rejected'
                          ? 'destructive'
                          : 'secondary'
                      }
                      className="flex items-center gap-1"
                    >
                      {getStatusIcon(submission.status)}
                      {submission.status.charAt(0).toUpperCase() + submission.status.slice(1)}
                    </Badge>
                  </div>
                  <div className="text-sm text-gray-400">
                    Submitted on {format(new Date(submission.created_at), 'PPP')}
                  </div>
                </div>

                <div className="flex items-center gap-4">
                  <div className="text-right">
                    <div className="font-medium">{submission.type}</div>
                    <div className="text-sm text-gray-400">
                      {submission.merchant?.business_type}
                    </div>
                  </div>

                  <div className="flex items-center gap-2">
                    <Button 
                      variant="outline" 
                      size="sm"
                      onClick={() => handleDownload(submission.url, submission.name)}
                    >
                      <Download className="w-4 h-4 mr-2" />
                      Download
                    </Button>
                    {submission.status === 'pending' && (
                      <>
                        <Button 
                          variant="outline" 
                          size="sm" 
                          className="text-green-400 hover:text-green-500"
                          onClick={() => handleStatusUpdate(submission.id, 'verified')}
                        >
                          <CheckCircle2 className="w-4 h-4" />
                        </Button>
                        <Button 
                          variant="outline" 
                          size="sm" 
                          className="text-red-400 hover:text-red-500"
                          onClick={() => handleStatusUpdate(submission.id, 'rejected')}
                        >
                          <XCircle className="w-4 h-4" />
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        ))}

        {filteredSubmissions?.length === 0 && (
          <div className="text-center py-8 text-gray-400">
            No submissions found matching your criteria
          </div>
        )}
      </div>
    </div>
  );
};