import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Upload, FileCheck, AlertCircle, Loader2 } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from '@/components/ui/card';
import { Alert, AlertTitle, AlertDescription } from '@/components/ui/alert';
import { supabase } from '@/lib/supabase';
import toast from 'react-hot-toast';
import { ProviderList } from '@/types';

interface LocationState {
  provider: ProviderList;
}

interface FileState {
  name: string;
  size: number;
  type: string;
}

interface FormData {
  businessRegistrationNumber: string;
  taxId: string;
  businessAddress: string;
  websiteUrl: string;
  monthlyVolume: string;
  businessType: string;
  preferredCurrency: string;
  acceptedPaymentMethods: string[];
  contactName: string;
  contactEmail: string;
  contactPhone: string;
}

export const BusinessDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { provider } = (location.state as LocationState) || {};
  
  const [businessLicense, setBusinessLicense] = useState<FileState | null>(null);
  const [identificationDoc, setIdentificationDoc] = useState<FileState | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    businessRegistrationNumber: '',
    taxId: '',
    businessAddress: '',
    websiteUrl: '',
    monthlyVolume: '',
    businessType: '',
    preferredCurrency: '',
    acceptedPaymentMethods: [],
    contactName: '',
    contactEmail: '',
    contactPhone: ''
  });

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, type: 'license' | 'id') => {
    const file = event.target.files?.[0];
    if (file) {
      if (file.type !== 'application/pdf') {
        toast.error('Please upload PDF files only');
        return;
      }
      if (file.size > 5 * 1024 * 1024) { // 5MB limit
        toast.error('File size should be less than 5MB');
        return;
      }
      
      const fileState = {
        name: file.name,
        size: file.size,
        type: file.type
      };
      
      if (type === 'license') {
        setBusinessLicense(fileState);
      } else {
        setIdentificationDoc(fileState);
      }
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!businessLicense || !identificationDoc) {
      toast.error('Please upload all required documents');
      return;
    }

    setIsSubmitting(true);

    try {
      const { error } = await supabase
        .from('merchant_provider_applications')
        .insert({
          provider_id: provider.id,
          business_details: formData,
          documents_submitted: true,
          status: 'pending_review',
          submitted_at: new Date().toISOString()
        });

      if (error) throw error;

      toast.success('Details submitted successfully! The provider will contact you shortly.');
      navigate('/dashboard');
    } catch (error) {
      console.error('Error submitting details:', error);
      toast.error('Failed to submit details. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!provider) {
    navigate('/providers');
    return null;
  }

  return (
    <div className="min-h-screen py-16 bg-gray-950">
      <div className="container max-w-4xl mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-12"
        >
          <h1 className="text-3xl md:text-4xl font-bold mb-4 text-white">
            Complete Your {provider.name} Application
          </h1>
          <p className="text-gray-400 max-w-2xl mx-auto">
            Submit your business details to finalize your payment provider setup
          </p>
        </motion.div>

        <Alert className="mb-8 bg-blue-900/20 border-blue-800">
          <AlertCircle className="h-4 w-4 text-blue-400" />
          <AlertTitle className="text-blue-100">Important</AlertTitle>
          <AlertDescription className="text-blue-200">
            Please ensure all documents are clear, recent, and in PDF format. Files should be less than 5MB.
          </AlertDescription>
        </Alert>

        <form onSubmit={handleSubmit} className="space-y-8">
          <Card className="bg-gray-900 border-gray-800">
            <CardHeader>
              <CardTitle className="text-white">Business Information</CardTitle>
              <CardDescription className="text-gray-400">
                Legal details about your business
              </CardDescription>
            </CardHeader>
            <CardContent className="space-y-6">
              <div className="grid md:grid-cols-2 gap-6">
                <div className="space-y-2">
                  <Label htmlFor="businessRegistrationNumber" className="text-gray-200">Business Registration Number</Label>
                  <Input
                    id="businessRegistrationNumber"
                    value={formData.businessRegistrationNumber}
                    onChange={(e) => setFormData(prev => ({ ...prev, businessRegistrationNumber: e.target.value }))}
                    className="bg-gray-800 border-gray-700 text-white"
                    required
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="taxId" className="text-gray-200">Tax ID / VAT Number</Label>
                  <Input
                    id="taxId"
                    value={formData.taxId}
                    onChange={(e) => setFormData(prev => ({ ...prev, taxId: e.target.value }))}
                    className="bg-gray-800 border-gray-700 text-white"
                    required
                  />
                </div>
              </div>

              <div className="space-y-2">
                <Label htmlFor="businessAddress" className="text-gray-200">Business Address</Label>
                <Input
                  id="businessAddress"
                  value={formData.businessAddress}
                  onChange={(e) => setFormData(prev => ({ ...prev, businessAddress: e.target.value }))}
                  className="bg-gray-800 border-gray-700 text-white"
                  required
                />
              </div>

              <div className="space-y-2">
                <Label htmlFor="websiteUrl" className="text-gray-200">Website URL</Label>
                <Input
                  id="websiteUrl"
                  type="url"
                  value={formData.websiteUrl}
                  onChange={(e) => setFormData(prev => ({ ...prev, websiteUrl: e.target.value }))}
                  className="bg-gray-800 border-gray-700 text-white"
                  required
                />
              </div>

              <div className="space-y-2">
                <Label htmlFor="monthlyVolume" className="text-gray-200">Monthly Volume</Label>
                <Input
                  id="monthlyVolume"
                  type="number"
                  value={formData.monthlyVolume}
                  onChange={(e) => setFormData(prev => ({ ...prev, monthlyVolume: e.target.value }))}
                  className="bg-gray-800 border-gray-700 text-white"
                  required
                />
              </div>

              <div className="space-y-2">
                <Label htmlFor="businessType" className="text-gray-200">Business Type</Label>
                <Input
                  id="businessType"
                  value={formData.businessType}
                  onChange={(e) => setFormData(prev => ({ ...prev, businessType: e.target.value }))}
                  className="bg-gray-800 border-gray-700 text-white"
                  required
                />
              </div>

              <div className="space-y-2">
                <Label htmlFor="preferredCurrency" className="text-gray-200">Preferred Currency</Label>
                <Input
                  id="preferredCurrency"
                  value={formData.preferredCurrency}
                  onChange={(e) => setFormData(prev => ({ ...prev, preferredCurrency: e.target.value }))}
                  className="bg-gray-800 border-gray-700 text-white"
                  required
                />
              </div>

              <div className="space-y-2">
                <Label>Business License</Label>
                <div className="flex items-center gap-4">
                  <Button
                    type="button"
                    variant="outline"
                    onClick={() => document.getElementById('businessLicense')?.click()}
                    className="w-full"
                  >
                    <Upload className="w-4 h-4 mr-2" />
                    Upload Business License
                  </Button>
                  <input
                    type="file"
                    id="businessLicense"
                    accept="application/pdf"
                    className="hidden"
                    onChange={(e) => handleFileChange(e, 'license')}
                  />
                  {businessLicense && (
                    <div className="flex items-center gap-2 text-sm text-green-400">
                      <FileCheck className="w-4 h-4" />
                      {businessLicense.name}
                    </div>
                  )}
                </div>
              </div>
            </CardContent>
          </Card>

          <Card className="bg-gray-900 border-gray-800">
            <CardHeader>
              <CardTitle className="text-white">Contact Information</CardTitle>
              <CardDescription className="text-gray-400">
                Primary contact for provider communication
              </CardDescription>
            </CardHeader>
            <CardContent className="space-y-6">
              <div className="grid md:grid-cols-2 gap-6">
                <div className="space-y-2">
                  <Label htmlFor="contactName" className="text-gray-200">Contact Name</Label>
                  <Input
                    id="contactName"
                    value={formData.contactName}
                    onChange={(e) => setFormData(prev => ({ ...prev, contactName: e.target.value }))}
                    className="bg-gray-800 border-gray-700 text-white"
                    required
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="contactEmail" className="text-gray-200">Contact Email</Label>
                  <Input
                    id="contactEmail"
                    type="email"
                    value={formData.contactEmail}
                    onChange={(e) => setFormData(prev => ({ ...prev, contactEmail: e.target.value }))}
                    className="bg-gray-800 border-gray-700 text-white"
                    required
                  />
                </div>
              </div>

              <div className="space-y-2">
                <Label htmlFor="contactPhone" className="text-gray-200">Contact Phone</Label>
                <Input
                  id="contactPhone"
                  type="tel"
                  value={formData.contactPhone}
                  onChange={(e) => setFormData(prev => ({ ...prev, contactPhone: e.target.value }))}
                  className="bg-gray-800 border-gray-700 text-white"
                  required
                />
              </div>
            </CardContent>
          </Card>

          <div className="flex justify-end gap-4">
            <Button 
              type="submit"
              className="w-full md:w-auto bg-blue-600 hover:bg-blue-700 text-white"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <>
                  <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                  Submitting...
                </>
              ) : (
                'Submit Application'
              )}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};