import React, { useState, useCallback } from 'react';
import { motion } from 'framer-motion';
import { 
  Globe, 
  Users, 
  Upload,
  FileCheck,
  CheckCircle2,
  Percent,
  Shield,
  Zap,
  Loader2
} from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Textarea } from '@/components/ui/textarea';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from '@/components/ui/card';
import toast from 'react-hot-toast';
import { cn } from "@/lib/utils";

const benefits = [
  {
    icon: Users,
    title: 'Expand Your Reach',
    description: 'Access thousands of merchants actively seeking payment solutions'
  },
  {
    icon: Percent,
    title: 'Competitive Edge',
    description: 'Offer exclusive rates through our platform'
  },
  {
    icon: Shield,
    title: 'Verified Merchants',
    description: 'Connect with pre-vetted, legitimate businesses'
  }
];

const requirements = [
  'Licensed payment provider',
  'PCI DSS compliance',
  'API documentation',
  'Technical support team',
  'Competitive rates',
  'Multi-currency support'
];

export const BecomePartner = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    companyName: '',
    website: '',
    contactName: '',
    email: '',
    phone: '',
    countriesServed: '',
    transactionVolume: '',
    standardRates: '',
    features: '',
    technicalSpecs: '',
    complianceCerts: ''
  });

  const [documents, setDocuments] = useState<Record<string, { name: string; size: number } | null>>({
    license: null,
    compliance: null,
    apiDocs: null
  });

  const [isUploading, setIsUploading] = useState<Record<string, boolean>>({
    license: false,
    compliance: false,
    apiDocs: false
  });

  const handleFileChange = useCallback(async (event: React.ChangeEvent<HTMLInputElement>, type: keyof typeof documents) => {
    const file = event.target.files?.[0];
    if (file) {
      if (file.size > 10 * 1024 * 1024) {
        toast.error('File size should be less than 10MB');
        return;
      }

      setIsUploading(prev => ({ ...prev, [type]: true }));
      try {
        await new Promise(resolve => setTimeout(resolve, 1000));
        
        setDocuments(prev => ({
          ...prev,
          [type]: {
            name: file.name,
            size: file.size
          }
        }));
      } catch (error) {
        toast.error('Failed to upload file. Please try again.');
      } finally {
        setIsUploading(prev => ({ ...prev, [type]: false }));
      }
    }
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!documents.license || !documents.compliance) {
      toast.error('Please upload required documents');
      return;
    }

    setIsSubmitting(true);
    try {
      await new Promise(resolve => setTimeout(resolve, 2000));
      
      toast.success('Application submitted successfully! Our team will review and contact you soon.');
      setFormData({
        companyName: '',
        website: '',
        contactName: '',
        email: '',
        phone: '',
        countriesServed: '',
        transactionVolume: '',
        standardRates: '',
        features: '',
        technicalSpecs: '',
        complianceCerts: ''
      });
      setDocuments({
        license: null,
        compliance: null,
        apiDocs: null
      });
    } catch (error) {
      toast.error('Failed to submit application. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen py-16">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-12"
        >
          <h1 className="text-3xl md:text-4xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400">
            Partner With PaymentMatch
          </h1>
          <p className="text-gray-400 max-w-2xl mx-auto">
            Join our network of trusted payment providers and connect with businesses worldwide
          </p>
        </motion.div>

        <div className="grid md:grid-cols-3 gap-6 mb-12">
          {benefits.map((benefit, index) => (
            <motion.div
              key={benefit.title}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
            >
              <Card>
                <CardContent className="p-6">
                  <benefit.icon className="w-8 h-8 text-blue-400 mb-4" />
                  <h3 className="font-semibold mb-2 text-white">{benefit.title}</h3>
                  <p className="text-sm text-gray-400">{benefit.description}</p>
                </CardContent>
              </Card>
            </motion.div>
          ))}
        </div>

        <div className="grid lg:grid-cols-3 gap-8 mb-12">
          <div className="lg:col-span-2">
            <Card>
              <CardHeader>
                <CardTitle className="text-white">Partnership Application</CardTitle>
                <CardDescription className="text-gray-300">
                  Tell us about your payment services and capabilities
                </CardDescription>
              </CardHeader>
              <CardContent>
                <form onSubmit={handleSubmit} className="space-y-6">
                  <div className="grid md:grid-cols-2 gap-6">
                    <div className="space-y-2">
                      <Label className="text-white">Company Name</Label>
                      <Input
                        className="text-white bg-transparent"
                        value={formData.companyName}
                        onChange={(e) => setFormData({ ...formData, companyName: e.target.value })}
                        required
                      />
                    </div>
                    <div className="space-y-2">
                      <Label className="text-white">Website</Label>
                      <Input
                        type="url"
                        value={formData.website}
                        onChange={(e) => setFormData({ ...formData, website: e.target.value })}
                        required
                      />
                    </div>
                  </div>

                  <div className="grid md:grid-cols-2 gap-6">
                    <div className="space-y-2">
                      <Label className="text-white">Contact Name</Label>
                      <Input
                        value={formData.contactName}
                        onChange={(e) => setFormData({ ...formData, contactName: e.target.value })}
                        required
                      />
                    </div>
                    <div className="space-y-2">
                      <Label className="text-white">Email</Label>
                      <Input
                        type="email"
                        value={formData.email}
                        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                        required
                      />
                    </div>
                  </div>

                  <div className="grid md:grid-cols-2 gap-6">
                    <div className="space-y-2">
                      <Label className="text-white">Phone</Label>
                      <Input
                        type="tel"
                        value={formData.phone}
                        onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                        required
                      />
                    </div>
                    <div className="space-y-2">
                      <Label className="text-white">Countries/Regions Served</Label>
                      <Input
                        value={formData.countriesServed}
                        onChange={(e) => setFormData({ ...formData, countriesServed: e.target.value })}
                        placeholder="e.g., Global, EU, North America"
                        required
                      />
                    </div>
                  </div>

                  <div className="space-y-2">
                    <Label className="text-white">Standard Transaction Rates</Label>
                    <Input
                      value={formData.standardRates}
                      onChange={(e) => setFormData({ ...formData, standardRates: e.target.value })}
                      placeholder="e.g., 2.9% + $0.30 per transaction"
                      required
                    />
                  </div>

                  <div className="space-y-2">
                    <Label className="text-white">Key Features & Services</Label>
                    <Textarea
                      className="text-white bg-transparent"
                      value={formData.features}
                      onChange={(e) => setFormData({ ...formData, features: e.target.value })}
                      placeholder="List your main payment features, supported payment methods, etc."
                      required
                    />
                  </div>

                  <div className="space-y-2">
                    <Label className="text-white">Technical Specifications</Label>
                    <Textarea
                      className="text-white bg-transparent"
                      value={formData.technicalSpecs}
                      onChange={(e) => setFormData({ ...formData, technicalSpecs: e.target.value })}
                      placeholder="API capabilities, integration methods, SDKs, etc."
                      required
                    />
                  </div>

                  <div className="space-y-2">
                    <Label className="text-white">Compliance & Certifications</Label>
                    <Textarea
                      className="text-white bg-transparent"
                      value={formData.complianceCerts}
                      onChange={(e) => setFormData({ ...formData, complianceCerts: e.target.value })}
                      placeholder="PCI DSS level, licenses, certifications, etc."
                      required
                    />
                  </div>

                  <div className="space-y-4">
                    <Label className="text-white">Required Documents</Label>
                    
                    <div className="space-y-4">
                      <div className="flex items-center gap-4">
                        <Button
                          type="button"
                          variant="outline"
                          onClick={() => document.getElementById('license')?.click()}
                          className={cn(
                            "w-full transition-all",
                            isUploading.license && "opacity-70 cursor-not-allowed"
                          )}
                          disabled={isUploading.license}
                        >
                          {isUploading.license ? (
                            <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                          ) : (
                            <Upload className="w-4 h-4 mr-2" />
                          )}
                          Payment License *
                        </Button>
                        <input
                          type="file"
                          id="license"
                          accept=".pdf"
                          className="hidden"
                          onChange={(e) => handleFileChange(e, 'license')}
                        />
                        {documents.license && (
                          <div className="flex items-center gap-2 text-sm text-white">
                            <FileCheck className="w-4 h-4" />
                            {documents.license.name}
                          </div>
                        )}
                      </div>

                      <div className="flex items-center gap-4">
                        <Button
                          type="button"
                          variant="outline"
                          onClick={() => document.getElementById('compliance')?.click()}
                          className={cn(
                            "w-full transition-all",
                            isUploading.compliance && "opacity-70 cursor-not-allowed"
                          )}
                          disabled={isUploading.compliance}
                        >
                          {isUploading.compliance ? (
                            <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                          ) : (
                            <Upload className="w-4 h-4 mr-2" />
                          )}
                          Compliance Certificates *
                        </Button>
                        <input
                          type="file"
                          id="compliance"
                          accept=".pdf"
                          className="hidden"
                          onChange={(e) => handleFileChange(e, 'compliance')}
                        />
                        {documents.compliance && (
                          <div className="flex items-center gap-2 text-sm text-white">
                            <FileCheck className="w-4 h-4" />
                            {documents.compliance.name}
                          </div>
                        )}
                      </div>

                      <div className="flex items-center gap-4">
                        <Button
                          type="button"
                          variant="outline"
                          onClick={() => document.getElementById('apiDocs')?.click()}
                          className={cn(
                            "w-full transition-all",
                            isUploading.apiDocs && "opacity-70 cursor-not-allowed"
                          )}
                          disabled={isUploading.apiDocs}
                        >
                          {isUploading.apiDocs ? (
                            <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                          ) : (
                            <Upload className="w-4 h-4 mr-2" />
                          )}
                          API Documentation
                        </Button>
                        <input
                          type="file"
                          id="apiDocs"
                          accept=".pdf"
                          className="hidden"
                          onChange={(e) => handleFileChange(e, 'apiDocs')}
                        />
                        {documents.apiDocs && (
                          <div className="flex items-center gap-2 text-sm text-white">
                            <FileCheck className="w-4 h-4" />
                            {documents.apiDocs.name}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <Button 
                    type="submit" 
                    className="w-full"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <>
                        <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                        Submitting...
                      </>
                    ) : (
                      'Submit Application'
                    )}
                  </Button>
                </form>
              </CardContent>
            </Card>
          </div>

          <div className="space-y-6">
            <Card>
              <CardHeader>
                <CardTitle className="text-white">Requirements</CardTitle>
                <CardDescription className="text-gray-300">
                  Minimum criteria for partnership
                </CardDescription>
              </CardHeader>
              <CardContent>
                <div className="space-y-3">
                  {requirements.map((req) => (
                    <div key={req} className="flex items-center gap-2">
                      <CheckCircle2 className="w-4 h-4 text-green-400 flex-shrink-0" />
                      <span className="text-sm text-white">{req}</span>
                    </div>
                  ))}
                </div>
              </CardContent>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle className="text-white">Why Partner With Us?</CardTitle>
              </CardHeader>
              <CardContent className="space-y-4">
                <div className="flex items-center gap-3">
                  <div className="bg-gradient-to-r from-blue-500/10 to-purple-500/10 p-2 rounded-lg">
                    <Users className="w-5 h-5 text-blue-400" />
                  </div>
                  <div>
                    <h4 className="font-medium text-white">Growing Network</h4>
                    <p className="text-sm text-gray-400">2000+ active merchants</p>
                  </div>
                </div>
                <div className="flex items-center gap-3">
                  <div className="bg-gradient-to-r from-blue-500/10 to-purple-500/10 p-2 rounded-lg">
                    <Globe className="w-5 h-5 text-purple-400" />
                  </div>
                  <div>
                    <h4 className="font-medium text-white">Global Reach</h4>
                    <p className="text-sm text-gray-400">180+ countries covered</p>
                  </div>
                </div>
                <div className="flex items-center gap-3">
                  <div className="bg-gradient-to-r from-blue-500/10 to-purple-500/10 p-2 rounded-lg">
                    <Zap className="w-5 h-5 text-green-400" />
                  </div>
                  <div>
                    <h4 className="font-medium text-white">Quick Integration</h4>
                    <p className="text-sm text-gray-400">Streamlined onboarding</p>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};