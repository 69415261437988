import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { ChevronRight, ChevronLeft, HelpCircle } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Checkbox } from '@/components/ui/checkbox';
import { currencies } from '@/lib/currencies';
import toast from 'react-hot-toast';

interface FormData {
  businessName: string;
  businessType: string;
  volume: string;
  location: string;
  currency: string;
  currentProvider: string;
  currentFees: {
    transactionFee: string;
    monthlyFee: string;
    otherFees: string[];
  };
  paymentTypes: string[];
  requirements: string[];
  contactDetails: {
    name: string;
    email: string;
    phone: string;
    preferredContact: string;
    termsAccepted: boolean;
  };
}

const commonInputStyles = "bg-gray-900 border-gray-700 text-white placeholder:text-gray-500 focus:ring-blue-600 focus:border-blue-600";
const commonSelectTriggerStyles = "bg-gray-900 border-gray-700 text-white focus:ring-blue-600 focus:border-blue-600";
const commonLabelStyles = "text-gray-200 font-medium";

// CHANGED: Added step labels to give users more context
const stepLabels = [
  'Business Information',
  'Volume & Fees',
  'Payment Types',
  'Contact Details'
];

export const Onboarding = () => {
  const navigate = useNavigate();
  const [step, setStep] = React.useState(1);
  const [formData, setFormData] = React.useState<FormData>({
    businessName: '',
    businessType: '',
    volume: '',
    location: '',
    currency: 'USD',
    currentProvider: '',
    currentFees: {
      transactionFee: '',
      monthlyFee: '',
      otherFees: []
    },
    paymentTypes: [],
    requirements: [],
    contactDetails: {
      name: '',
      email: '',
      phone: '',
      preferredContact: 'email',
      termsAccepted: false
    }
  });

  const updateFormData = (field: string, value: string | string[]) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const updateContactDetails = (field: string, value: string | boolean) => {
    setFormData(prev => ({
      ...prev,
      contactDetails: {
        ...prev.contactDetails,
        [field]: value
      }
    }));
  };

  const updateCurrentFees = (field: string, value: string) => {
    setFormData(prev => ({
      ...prev,
      currentFees: {
        ...prev.currentFees,
        [field]: value
      }
    }));
  };

  const renderBusinessInfo = () => (
    <motion.div
      initial={{ opacity: 0, x: 20 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -20 }}
      className="space-y-6"
    >
      <div className="space-y-2">
        <div className="flex items-center space-x-2">
          <Label htmlFor="businessName" className={commonLabelStyles}>Business Name</Label>
          {/* ADDED: Simple tooltip */}
          <div className="relative" title="Your business name helps us personalize recommendations.">
            <HelpCircle className="w-4 h-4 text-gray-400" />
          </div>
        </div>
        <Input
          id="businessName"
          value={formData.businessName}
          onChange={(e) => updateFormData('businessName', e.target.value)}
          placeholder="Your business name"
          className={commonInputStyles}
        />
      </div>

      <div className="space-y-2">
        <div className="flex items-center space-x-2">
          <Label htmlFor="businessType" className={commonLabelStyles}>Type of Business</Label>
          <div className="relative" title="We use this to suggest providers specialized in your industry.">
            <HelpCircle className="w-4 h-4 text-gray-400" />
          </div>
        </div>
        <Select value={formData.businessType} onValueChange={(value) => updateFormData('businessType', value)}>
          <SelectTrigger className={commonSelectTriggerStyles}>
            <SelectValue placeholder="Select business type" />
          </SelectTrigger>
          <SelectContent className="bg-gray-900 border-gray-700">
            <SelectItem value="ecommerce">E-commerce</SelectItem>
            <SelectItem value="retail">Retail Store</SelectItem>
            <SelectItem value="services">Services</SelectItem>
            <SelectItem value="marketplace">Marketplace</SelectItem>
            <SelectItem value="subscription">Subscription Business</SelectItem>
            <SelectItem value="other">Other</SelectItem>
          </SelectContent>
        </Select>
      </div>

      <div className="space-y-2">
        <div className="flex items-center space-x-2">
          <Label htmlFor="currentProvider" className={commonLabelStyles}>Current Payment Provider (if any)</Label>
          <div className="relative" title="If you have a current provider, we can compare rates and find you a better deal.">
            <HelpCircle className="w-4 h-4 text-gray-400" />
          </div>
        </div>
        <Input
          id="currentProvider"
          value={formData.currentProvider}
          onChange={(e) => updateFormData('currentProvider', e.target.value)}
          placeholder="Leave blank if none"
          className={commonInputStyles}
        />
      </div>
    </motion.div>
  );

  const renderVolumeAndFees = () => (
    <motion.div
      initial={{ opacity: 0, x: 20 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -20 }}
      className="space-y-6"
    >
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="space-y-2">
          <div className="flex items-center space-x-2">
            <Label htmlFor="currency" className={commonLabelStyles}>Preferred Currency</Label>
            <div className="relative" title="Choosing a currency ensures providers that support it are prioritized.">
              <HelpCircle className="w-4 h-4 text-gray-400" />
            </div>
          </div>
          <Select value={formData.currency} onValueChange={(value) => updateFormData('currency', value)}>
            <SelectTrigger className={commonSelectTriggerStyles}>
              <SelectValue placeholder="Select currency" />
            </SelectTrigger>
            <SelectContent className="bg-gray-900 border-gray-700">
              {currencies.map((currency) => (
                <SelectItem key={currency.code} value={currency.code}>
                  {currency.code} - {currency.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

        <div className="space-y-2">
          <div className="flex items-center space-x-2">
            <Label htmlFor="volume" className={commonLabelStyles}>Monthly Volume</Label>
            <div className="relative" title="Your approximate monthly volume helps us find providers with suitable rates.">
              <HelpCircle className="w-4 h-4 text-gray-400" />
            </div>
          </div>
          <Select value={formData.volume} onValueChange={(value) => updateFormData('volume', value)}>
            <SelectTrigger className={commonSelectTriggerStyles}>
              <SelectValue placeholder="Select volume range" />
            </SelectTrigger>
            <SelectContent className="bg-gray-900 border-gray-700">
              <SelectItem value="starting">Just Starting</SelectItem>
              <SelectItem value="0-5000">0 - 5,000 per month</SelectItem>
              <SelectItem value="5000-20000">5,000 - 20,000 per month</SelectItem>
              <SelectItem value="20000-100000">20,000 - 100,000 per month</SelectItem>
              <SelectItem value="100000+">100,000+ per month</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>

      {formData.currentProvider && (
        <div className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="transactionFee" className={commonLabelStyles}>Current Transaction Fee (%)</Label>
            <Input
              id="transactionFee"
              value={formData.currentFees.transactionFee}
              onChange={(e) => updateCurrentFees('transactionFee', e.target.value)}
              placeholder="e.g., 2.9"
              className={commonInputStyles}
            />
          </div>

          <div className="space-y-2">
            <Label htmlFor="monthlyFee" className={commonLabelStyles}>
              Current Monthly Fee (in {formData.currency})
            </Label>
            <Input
              id="monthlyFee"
              value={formData.currentFees.monthlyFee}
              onChange={(e) => updateCurrentFees('monthlyFee', e.target.value)}
              placeholder="e.g., 29.99"
              className={commonInputStyles}
            />
          </div>
        </div>
      )}
    </motion.div>
  );

  const renderRequirements = () => (
    <motion.div
      initial={{ opacity: 0, x: 20 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -20 }}
      className="space-y-6"
    >
      <div className="space-y-4">
        <Label className="text-white">Payment Types Needed</Label>
        {[
          { id: 'cards', label: 'Credit/Debit Cards' },
          { id: 'digital', label: 'Digital Wallets (Apple Pay, Google Pay)' },
          { id: 'bank', label: 'Bank Transfers' },
          { id: 'bnpl', label: 'Buy Now Pay Later' },
          { id: 'crypto', label: 'Cryptocurrency' },
          { id: 'local', label: 'Local Payment Methods' }
        ].map((type) => (
          <div key={type.id} className="flex items-center space-x-2">
            <Checkbox
              id={type.id}
              checked={formData.paymentTypes.includes(type.id)}
              onCheckedChange={(checked) => {
                updateFormData('paymentTypes', checked ? [...formData.paymentTypes, type.id] : formData.paymentTypes.filter(id => id !== type.id));
              }}
              className="border-gray-600"
            />
            <Label htmlFor={type.id} className="text-white">{type.label}</Label>
          </div>
        ))}
      </div>
    </motion.div>
  );

  const renderContactDetails = () => (
    <motion.div
      initial={{ opacity: 0, x: 20 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -20 }}
      className="space-y-6"
    >
      <div className="space-y-2">
        <Label htmlFor="name" className={commonLabelStyles}>Full Name</Label>
        <Input
          id="name"
          value={formData.contactDetails.name}
          onChange={(e) => updateContactDetails('name', e.target.value)}
          placeholder="Enter your full name"
          className={commonInputStyles}
        />
      </div>

      <div className="space-y-2">
        <Label htmlFor="email" className={commonLabelStyles}>Email Address</Label>
        <Input
          id="email"
          type="email"
          value={formData.contactDetails.email}
          onChange={(e) => updateContactDetails('email', e.target.value)}
          placeholder="Enter your email"
          className={commonInputStyles}
        />
      </div>

      <div className="space-y-2">
        <Label htmlFor="phone" className={commonLabelStyles}>Phone Number</Label>
        <Input
          id="phone"
          type="tel"
          value={formData.contactDetails.phone}
          onChange={(e) => updateContactDetails('phone', e.target.value)}
          placeholder="Enter your phone number"
          className={commonInputStyles}
        />
      </div>

      <div className="space-y-2">
        <Label htmlFor="preferredContact" className={commonLabelStyles}>Preferred Contact Method</Label>
        <Select 
          value={formData.contactDetails.preferredContact} 
          onValueChange={(value) => updateContactDetails('preferredContact', value)}
        >
          <SelectTrigger className={commonSelectTriggerStyles}>
            <SelectValue placeholder="Select contact method" />
          </SelectTrigger>
          <SelectContent className="bg-gray-900 border-gray-700">
            <SelectItem value="email">Email</SelectItem>
            <SelectItem value="phone">Phone</SelectItem>
            <SelectItem value="whatsapp">WhatsApp</SelectItem>
          </SelectContent>
        </Select>
      </div>

      <div className="flex items-center space-x-2">
        <Checkbox
          id="terms"
          checked={formData.contactDetails.termsAccepted}
          onCheckedChange={(checked) => updateContactDetails('termsAccepted', checked as boolean)}
          className="border-gray-600 data-[state=checked]:bg-blue-600 data-[state=checked]:border-blue-600"
        />
        <Label 
          htmlFor="terms" 
          className="text-sm text-gray-300"
        >
          I agree to the terms and conditions and privacy policy
        </Label>
      </div>

      <p className="text-sm text-gray-400 mt-4">
        All fields are required to help us find the best payment solutions for your business
      </p>
    </motion.div>
  );

  const renderStep = () => {
    switch (step) {
      case 1:
        return renderBusinessInfo();
      case 2:
        return renderVolumeAndFees();
      case 3:
        return renderRequirements();
      case 4:
        return renderContactDetails();
      default:
        return null;
    }
  };

  const handleNext = () => {
    if (step === 4) {
      const { name, email, phone, termsAccepted } = formData.contactDetails;
      if (!name || !email || !phone) {
        toast.error('Please fill in all required fields');
        return;
      }
      if (!termsAccepted) {
        toast.error('Please accept the terms and conditions');
        return;
      }

      // Navigate to providers with all necessary data
      navigate('/providers', { 
        state: { 
          fromOnboarding: true,
          onboardingData: {
            businessData: {
              name: formData.businessName,
              type: formData.businessType,
              volume: formData.volume,
              location: formData.location,
              currency: formData.currency,
              paymentTypes: formData.paymentTypes,
              requirements: formData.requirements
            },
            contactDetails: formData.contactDetails
          }
        }
      });
    } else {
      setStep(step + 1);
    }
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  return (
    <div className="min-h-screen py-4 md:py-8 bg-gray-950">
      <div className="container max-w-2xl mx-auto px-4">
        <Card className="bg-gray-900 border-gray-800">
          <CardHeader className="space-y-1">
            <CardTitle className="text-xl md:text-2xl text-white text-center">
              Find Your Perfect Payment Provider
            </CardTitle>
            <CardDescription className="text-gray-400 text-center">
              Help us understand your needs to match you with the best payment providers and secure exclusive rates
            </CardDescription>
          </CardHeader>
          <CardContent>
            {/* ADDED: Step labels */}
            <div className="text-center mb-4 text-gray-300 text-sm">
              <strong>{stepLabels[step - 1]}</strong>
            </div>

            <div className="mb-8">
              <div className="flex justify-between mb-2">
                {[1, 2, 3, 4].map((s) => (
                  <div
                    key={s}
                    className={`w-1/5 h-1 rounded-full ${
                      s <= step ? 'bg-blue-600' : 'bg-gray-700'
                    }`}
                  />
                ))}
              </div>
              <div className="text-sm text-gray-400 text-center">
                Step {step} of 4
              </div>
            </div>

            {renderStep()}

            <div className="flex justify-between mt-8">
              {step > 1 && (
                <Button 
                  variant="outline" 
                  onClick={handleBack}
                  className="bg-gray-800 text-white border-gray-700 hover:bg-gray-700"
                >
                  <ChevronLeft className="w-4 h-4 mr-2" />
                  Back
                </Button>
              )}
              <Button 
                className={`${step === 1 ? 'w-full' : 'ml-auto'} bg-blue-600 hover:bg-blue-700`}
                onClick={handleNext}
              >
                {step === 4 ? 'Complete' : 'Next'}
                <ChevronRight className="w-4 h-4 ml-2" />
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};
