import React from 'react';
import { motion } from 'framer-motion';
import { Star, Quote } from 'lucide-react';
import { Card, CardContent } from './ui/card';

const testimonials = [
  {
    name: 'Sarah Johnson',
    role: 'CEO, TechStart',
    image: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=400',
    content: 'PaySouq helped us save over 40% on transaction fees. Their comparison tool made it easy to find the perfect provider for our needs.',
    rating: 5,
    company: 'TechStart',
    logo: 'https://images.unsplash.com/photo-1611162617474-5b21e879e113?w=400'
  },
  {
    name: 'Michael Chen',
    role: 'Founder, EcoStore',
    image: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?w=400',
    content: 'The integration process was seamless, and their support team was incredibly helpful. Highly recommend their services!',
    rating: 5,
    company: 'EcoStore',
    logo: 'https://images.unsplash.com/photo-1611162616305-c69b3037c7ff?w=400'
  },
  {
    name: 'Emily Rodriguez',
    role: 'CFO, GrowthCo',
    image: 'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?w=400',
    content: 'We were able to negotiate better rates through PaySouq than we could get directly. The savings have been substantial.',
    rating: 5,
    company: 'GrowthCo',
    logo: 'https://images.unsplash.com/photo-1611162618071-b39a2ec055fb?w=400'
  }
];

export const Testimonials: React.FC = () => {
  return (
    <section className="py-16 relative overflow-hidden">
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-gradient-to-b from-gray-950 via-gray-900/20 to-gray-950" />
        <div className="absolute inset-0 bg-grid-white/[0.02]" />
      </div>

      <div className="container mx-auto px-4 relative">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center mb-12"
        >
          <h2 className="text-3xl md:text-4xl font-bold mb-4 text-white">
            Trusted by Industry Leaders
          </h2>
          <p className="text-gray-300 max-w-2xl mx-auto text-lg">
            Join thousands of businesses that have found their perfect payment solution through our platform.
          </p>
        </motion.div>

        <div className="grid md:grid-cols-3 gap-6 lg:gap-8">
          {testimonials.map((testimonial, index) => (
            <motion.div
              key={testimonial.name}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.2 }}
            >
              <Card className="h-full bg-gray-900/50 border-gray-800 backdrop-blur-sm hover:bg-gray-900/70 transition-all">
                <CardContent className="p-6 relative">
                  <Quote className="absolute top-6 right-6 w-8 h-8 text-blue-500/20" />
                  
                  <div className="flex gap-1 mb-4">
                    {[...Array(testimonial.rating)].map((_, i) => (
                      <Star key={i} className="w-5 h-5 text-yellow-500 fill-current" />
                    ))}
                  </div>

                  <p className="text-gray-200 text-lg mb-6 relative z-10 leading-relaxed">
                    "{testimonial.content}"
                  </p>

                  <div className="flex items-center gap-4">
                    <img
                      src={testimonial.image}
                      alt={testimonial.name}
                      className="w-12 h-12 rounded-full object-cover ring-2 ring-blue-500/20"
                      loading="lazy"
                    />
                    <div>
                      <h4 className="font-semibold text-white">{testimonial.name}</h4>
                      <p className="text-gray-400">{testimonial.role}</p>
                    </div>
                  </div>

                  <div className="mt-6 pt-6 border-t border-gray-800">
                    <div className="flex items-center justify-between">
                      <img
                        src={testimonial.logo}
                        alt={testimonial.company}
                        className="h-8 w-auto grayscale opacity-50 hover:opacity-75 transition-opacity"
                        loading="lazy"
                      />
                      <span className="text-sm text-gray-400">Verified Customer</span>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </motion.div>
          ))}
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="mt-12 text-center"
        >
          <div className="inline-flex items-center gap-2 bg-gray-800/50 rounded-full px-6 py-3 backdrop-blur-sm">
            <div className="flex -space-x-2">
              {[1, 2, 3, 4].map((i) => (
                <div
                  key={i}
                  className="w-8 h-8 rounded-full bg-gradient-to-r from-blue-500 to-purple-500 border-2 border-gray-900 ring-2 ring-gray-900"
                />
              ))}
            </div>
            <span className="text-gray-300">Join 2000+ satisfied customers</span>
          </div>
        </motion.div>
      </div>
    </section>
  );
};