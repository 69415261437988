import React from 'react';
import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { CreditCard, Briefcase, Menu, X, Newspaper, HelpCircle, LogIn } from 'lucide-react';
import { Button } from './ui/button';
import { useAuth } from '@/hooks/useAuth';
import { toast } from 'react-hot-toast';


const menuItems = [
  { 
    title: 'Partners', 
    items: [
      { name: 'Payment Providers', icon: CreditCard, href: '/payment-providers' },
      { name: 'Become a Partner', icon: Briefcase, href: '/become-partner' }
    ]
  },
  { 
    title: 'Resources', 
    items: [
      { name: 'Blog', icon: Newspaper, href: '/blog' },
      { name: 'Help Center', icon: HelpCircle, href: '/help' }
    ]
  }
];

export const Header: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [hoveredMenu, setHoveredMenu] = useState<string | null>(null);
  const navigate = useNavigate();
  const { user, signOut } = useAuth();

  const handleAuthAction = async () => {
    if (user) {
      try {
        await signOut();
        navigate('/');
      } catch (error) {
        toast.error('Failed to sign out. Please try again.');
      }
    } else {
      navigate('/login');
    }
  };

  // Close mobile menu when screen size changes to desktop
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setIsOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <header className="relative z-50">
      <nav className="container mx-auto px-4 py-4">
        <div className="flex items-center justify-between">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="flex items-center gap-8"
          >
            <Link to="/" className="relative flex items-center justify-center scale-150">
              <img
                src="/images/paysouqlogo.png"
                alt="PaySouq"
                className="h-12 w-auto object-contain"
              />
            </Link>
            
            {/* Desktop Menu */}
            <div className="hidden md:flex items-center gap-6">
              {menuItems.map((item) => (
                <div
                  key={item.title}
                  className="relative"
                  onMouseEnter={() => setHoveredMenu(item.title)}
                  onMouseLeave={() => setHoveredMenu(null)}
                >
                  <button 
                    className="text-gray-300 hover:text-white font-medium px-2 py-1"
                    aria-expanded={hoveredMenu === item.title}
                    aria-haspopup="true"
                  >
                    {item.title}
                  </button>
                  <AnimatePresence>
                    {hoveredMenu === item.title && (
                      <motion.div
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 10 }}
                        className="absolute left-0 mt-2 w-56 bg-black/90 backdrop-blur-sm border border-white/10 rounded-lg shadow-lg py-2"
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby={`${item.title}-menu`}
                      >
                        {item.items.map((subItem) => (
                          <motion.button
                            key={subItem.name}
                            whileHover={{ x: 4 }}
                            onClick={() => {
                              navigate(subItem.href);
                              setHoveredMenu(null);
                            }}
                            className="w-full flex items-center gap-2 px-4 py-2 text-sm text-gray-300 hover:text-white hover:bg-white/10 cursor-pointer"
                            role="menuitem"
                          >
                            <subItem.icon className="w-4 h-4" aria-hidden="true" />
                            {subItem.name}
                          </motion.button>
                        ))}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              ))}
              <button 
                className="text-gray-300 hover:text-white font-medium px-2 py-1"
                onClick={() => navigate('/pricing')}
              >
                Pricing
              </button>
            </div>
          </motion.div>

          {/* Desktop CTA */}
          <div className="hidden md:flex items-center gap-4">
            <Button 
              className="bg-gradient-to-r from-blue-500 to-purple-500 hover:from-blue-600 hover:to-purple-600"
              onClick={handleAuthAction}
            >
              {user ? (
                'Sign Out'
              ) : (
                <>
                  <LogIn className="w-4 h-4 mr-2" />
                  Sign In
                </>
              )}
            </Button>
          </div>

          {/* Mobile Menu Button */}
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="md:hidden text-gray-300 hover:text-white p-2"
            aria-expanded={isOpen}
            aria-label={isOpen ? 'Close menu' : 'Open menu'}
          >
            {isOpen ? <X size={24} aria-hidden="true" /> : <Menu size={24} aria-hidden="true" />}
          </button>
        </div>
      </nav>

      {/* Mobile Menu */}
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            className="md:hidden fixed inset-x-0 top-[72px] bg-black/90 backdrop-blur-sm border-t border-white/10 max-h-[calc(100vh-72px)] overflow-y-auto"
            role="dialog"
            aria-modal="true"
            aria-label="Mobile menu"
          >
            <div className="container mx-auto px-4 py-4">
              {menuItems.map((item) => (
                <div key={item.title} className="mb-4">
                  <h3 className="font-medium text-white mb-2">{item.title}</h3>
                  <div className="space-y-2">
                    {item.items.map((subItem) => (
                      <motion.div
                        key={subItem.name}
                        whileHover={{ x: 4 }}
                        onClick={() => {
                          navigate(subItem.href);
                          setIsOpen(false);
                        }}
                        className="flex items-center gap-2 text-gray-300 hover:text-white cursor-pointer"
                      >
                        <subItem.icon className="w-4 h-4" />
                        {subItem.name}
                      </motion.div>
                    ))}
                  </div>
                </div>
              ))}

              <div className="mb-4">
                <button 
                  className="text-gray-300 hover:text-white font-medium"
                  onClick={() => {
                    setIsOpen(false);
                    navigate('/pricing');
                  }}
                >
                  Pricing
                </button>
              </div>

              <div className="space-y-2 mt-6">
                <Button 
                  className="w-full justify-center bg-gradient-to-r from-blue-500 to-purple-500 hover:from-blue-600 hover:to-purple-600"
                  onClick={() => {
                    setIsOpen(false);
                    handleAuthAction();
                  }}
                >
                  {user ? 'Sign Out' : 'Sign In'}
                </Button>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </header>
  );
};