import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { providersData } from '../data/ProvidersData';
import { ComparisonTable } from '../components/ComparisonTable';
import { FilterBar } from '../components/FilterBar';
import { Plus, X, HelpCircle } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from '@/components/ui/card';
import { ProviderList } from '../types';

interface Filters {
  volume: string;
  industry: string;
  features: string[];
  paymentMethods: string[];
}

export const Compare: React.FC = () => {
  const [selectedProviders, setSelectedProviders] = useState<ProviderList[]>([]);
  const [filters, setFilters] = useState<Filters>({
    volume: '',
    industry: '',
    features: [],
    paymentMethods: []
  });

  const handleToggleProvider = (provider: ProviderList) => {
    if (selectedProviders.find(p => p.id === provider.id)) {
      setSelectedProviders(selectedProviders.filter(p => p.id !== provider.id));
    } else if (selectedProviders.length < 3) {
      setSelectedProviders([...selectedProviders, provider]);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-950 via-gray-900 to-gray-950 py-8 md:py-16">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="max-w-4xl mx-auto text-center mb-12"
        >
          <div className="flex flex-col items-center gap-2">
            <h1 className="text-3xl md:text-4xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400">
              Compare Payment Providers
            </h1>
            <div className="text-lg text-gray-300 flex items-center gap-2">
              Select up to 3 providers to compare features, rates, and services
              <div className="relative" title="Check the boxes on each provider to add it to the comparison table below.">
                <HelpCircle className="w-5 h-5 text-gray-300" />
                <span className="sr-only">How to compare providers</span>
              </div>
            </div>
          </div>
        </motion.div>

        {/* Selected Providers */}
        {selectedProviders.length > 0 && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="mb-8"
          >
            <Card className="border-gray-800 bg-gray-900/50 backdrop-blur-sm">
              <CardHeader>
                <div className="flex items-center justify-between">
                  <CardTitle className="text-white">Selected for Comparison</CardTitle>
                  <Button 
                    variant="outline"
                    onClick={() => setSelectedProviders([])}
                    className="text-gray-300 hover:text-white border-gray-700 hover:bg-gray-800"
                  >
                    Clear All
                  </Button>
                </div>
                <CardDescription className="text-gray-400">
                  {selectedProviders.length === 3 
                    ? "Maximum providers selected" 
                    : `Select up to ${3 - selectedProviders.length} more providers to compare`}
                </CardDescription>
              </CardHeader>
              <CardContent>
                <div className="flex flex-wrap gap-4 mb-6">
                  {selectedProviders.map((provider) => (
                    <div
                      key={provider.id}
                      className="flex items-center gap-2 bg-gray-800/50 rounded-lg px-3 py-2"
                    >
                      <span className="text-gray-200">{provider.name}</span>
                      <button
                        onClick={() => handleToggleProvider(provider)}
                        className="text-gray-400 hover:text-white transition-colors"
                      >
                        <X className="w-4 h-4" />
                      </button>
                    </div>
                  ))}
                </div>

                {selectedProviders.length >= 2 && (
                  <ComparisonTable providers={selectedProviders} />
                )}
              </CardContent>
            </Card>
          </motion.div>
        )}

        <div className="grid md:grid-cols-4 gap-8">
          <div>
            <FilterBar filters={filters} onFilterChange={setFilters} />
          </div>
          
          <div className="md:col-span-3">
            <div className="grid gap-6">
              {providersData.map((provider: ProviderList) => (
                <Card key={provider.id} className="overflow-hidden border-gray-800 bg-gray-900/50 backdrop-blur-sm hover:bg-gray-900/70 transition-colors">
                  <CardContent className="p-6">
                    <div className="grid md:grid-cols-4 gap-6 items-center">
                      {/* Provider Info */}
                      <div className="md:col-span-2">
                        <div className="flex items-center gap-4">
                          <div>
                            <h3 className="text-lg font-semibold text-white">{provider.name}</h3>
                            <p className="text-sm text-gray-400">Best for {provider.idealFor}</p>
                          </div>
                        </div>
                      </div>

                      {/* Quick Stats */}
                      <div className="space-y-2">
                        <div className="text-sm">
                          <span className="text-gray-400">Standard Rate: </span>
                          <span className="font-medium text-gray-200">{provider.transactionFee.standard}%</span>
                        </div>
                        <div className="text-sm">
                          <span className="text-gray-400">Currencies: </span>
                          <span className="font-medium text-gray-200">{provider.currencies}+</span>
                        </div>
                      </div>

                      {/* Actions */}
                      <div>
                        <Button 
                          variant={selectedProviders.find(p => p.id === provider.id) ? "outline" : "default"}
                          className={`w-full ${
                            selectedProviders.find(p => p.id === provider.id)
                              ? "text-gray-300 hover:text-white border-gray-700 hover:bg-gray-800"
                              : "bg-blue-600 hover:bg-blue-700 text-white"
                          }`}
                          onClick={() => handleToggleProvider(provider)}
                          disabled={selectedProviders.length >= 3 && !selectedProviders.find(p => p.id === provider.id)}
                        >
                          {selectedProviders.find(p => p.id === provider.id) ? (
                            <>
                              <X className="w-4 h-4 mr-2" />
                              Remove
                            </>
                          ) : (
                            <>
                              <Plus className="w-4 h-4 mr-2" />
                              Add to Compare
                            </>
                          )}
                        </Button>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};