import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { BadgePercent, CheckCircle2, ArrowRightCircle, Star, Globe2, Shield } from 'lucide-react';
import { Button } from './ui/button';
import { Card, CardHeader, CardContent, CardFooter, CardTitle, CardDescription } from './ui/card';
import { ProviderList } from '../types/index';
import { cn } from '@/lib/utils';

interface ProviderCardProps {
  provider: ProviderList;
  onSelect: (provider: ProviderList) => void;
  recommended?: boolean;
}

export const ProviderCard: React.FC<ProviderCardProps> = ({ provider, recommended }) => {
  const navigate = useNavigate();

  const handleGetStarted = () => {
    // Store selected provider in localStorage or state management
    localStorage.setItem('selectedProvider', JSON.stringify(provider));
    // Navigate to onboarding
    navigate('/onboarding');
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      whileHover={{ y: -5, transition: { duration: 0.2 } }}
      className="h-full"
    >
      <Card className={cn(
        "relative overflow-hidden h-full border-gray-800 bg-gray-900/50 backdrop-blur-sm",
        recommended && "ring-2 ring-blue-500/20"
      )}>
        {recommended && (
          <div className="absolute -right-12 top-4 rotate-45 bg-gradient-to-r from-blue-600 to-purple-600 px-12 py-1 text-sm font-medium text-white shadow-lg">
            Recommended
          </div>
        )}
        
        <CardHeader>
          <div className="flex justify-between items-start">
            <div className="space-y-2">
              <CardTitle className="text-xl text-white">{provider.name}</CardTitle>
              <CardDescription className="text-gray-400">{provider.idealFor}</CardDescription>
            </div>
            <div className="p-2 rounded-lg bg-blue-500/10">
              <BadgePercent className="text-blue-400 w-6 h-6" />
            </div>
          </div>
        </CardHeader>

        <CardContent>
          <div className="space-y-6">
            <div className="space-y-2">
              <div className="text-sm text-gray-400">Standard fee</div>
              <div className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400">
                {provider.transactionFee.standard}%
              </div>
            </div>
            
            <div className="grid grid-cols-2 gap-4">
              <div className="bg-gradient-to-r from-blue-500/10 to-purple-500/10 rounded-lg p-3">
                <Globe2 className="w-5 h-5 text-blue-400 mb-2" />
                <div className="text-sm text-gray-400">Countries</div>
                <div className="text-lg font-semibold text-white">{provider.countries}+</div>
              </div>

              <div className="bg-gradient-to-r from-blue-500/10 to-purple-500/10 rounded-lg p-3">
                <Shield className="w-5 h-5 text-purple-400 mb-2" />
                <div className="text-sm text-gray-400">Currencies</div>
                <div className="text-lg font-semibold text-white">{provider.currencies}+</div>
              </div>
            </div>

            <div className="space-y-3">
              {provider.features.included.slice(0, 3).map((feature, index) => (
                <motion.div
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: index * 0.1 }}
                  key={feature}
                  className="flex items-center gap-2 text-sm"
                >
                  <CheckCircle2 className="text-green-400 w-4 h-4 flex-shrink-0" />
                  <span className="text-gray-300">{feature}</span>
                </motion.div>
              ))}
              {provider.features.included.length > 3 && (
                <div className="text-sm text-gray-400 pl-6">
                  +{provider.features.included.length - 3} more features
                </div>
              )}
            </div>

            <div className="flex items-center gap-1">
              <div className="flex">
                {[1, 2, 3, 4, 5].map((star) => (
                  <Star 
                    key={star} 
                    className="w-4 h-4 fill-yellow-400 text-yellow-400" 
                  />
                ))}
              </div>
              <span className="text-sm text-gray-400 ml-2">5.0 (2k+ reviews)</span>
            </div>
          </div>
        </CardContent>

        <CardFooter>
          <Button 
            className="w-full bg-gradient-to-r from-blue-600 to-purple-600 hover:from-blue-700 hover:to-purple-700 text-white shadow-lg group"
            onClick={handleGetStarted}
          >
            Get Started 
            <ArrowRightCircle className="ml-2 w-4 h-4 transition-transform group-hover:translate-x-1" />
          </Button>
        </CardFooter>

        {recommended && (
          <div className="absolute inset-0 rounded-lg ring-1 ring-blue-500/20" />
        )}
      </Card>
    </motion.div>
  );
};