import React from 'react';
import { motion } from 'framer-motion';
import { 
  Filter, 
  DollarSign, 
  Store, 
  Code,
  CreditCard, 
  Wallet, 
  Globe,
  ShoppingBag,
  Building,
  Shield,
  RefreshCcw
} from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from './ui/card';
import { Label } from './ui/label';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from './ui/select';
import { Checkbox } from './ui/checkbox';
import { Button } from './ui/button';
import { useState, useEffect } from 'react';

interface FilterState {
  volume: string;
  industry: string;
  features: string[];
  paymentMethods: string[];
}

interface FilterBarProps {
  filters: FilterState;
  onFilterChange: (filters: FilterState) => void;
}

const volumeRanges = [
  { id: 'low', label: '$0 - $10,000', icon: DollarSign },
  { id: 'medium', label: '$10,000 - $50,000', icon: DollarSign },
  { id: 'high', label: '$50,000+', icon: DollarSign }
];

const industries = [
  { id: 'ecommerce', label: 'E-commerce', icon: ShoppingBag },
  { id: 'saas', label: 'SaaS', icon: Code },
  { id: 'retail', label: 'Retail', icon: Store },
  { id: 'enterprise', label: 'Enterprise', icon: Building }
];

const features = [
  { id: 'api', label: 'API Access', icon: Code },
  { id: 'recurring', label: 'Recurring Billing', icon: RefreshCcw },
  { id: 'fraud', label: 'Fraud Protection', icon: Shield },
  { id: 'multicurrency', label: 'Multi-currency', icon: Globe }
];

const paymentMethods = [
  { id: 'cards', icon: CreditCard, label: 'Cards' },
  { id: 'digital_wallets', icon: Wallet, label: 'Digital Wallets' },
  { id: 'bank_transfer', icon: Building, label: 'Bank Transfer' },
  { id: 'international', icon: Globe, label: 'International' },
];

export const FilterBar: React.FC<FilterBarProps> = ({ filters, onFilterChange }) => {
  const [tempFilters, setTempFilters] = useState<FilterState>(filters);

  const handleVolumeChange = (value: string) => {
    const volume = value === 'all' ? '' : value;
    setTempFilters({ ...tempFilters, volume });
  };

  const handleIndustryChange = (value: string) => {
    const industry = value === 'all' ? '' : value;
    setTempFilters({ ...tempFilters, industry });
  };

  const handleFeatureChange = (featureId: string, checked: boolean) => {
    const newFeatures = checked
      ? [...tempFilters.features, featureId]
      : tempFilters.features.filter((f: string) => f !== featureId);
    setTempFilters({ ...tempFilters, features: newFeatures });
  };

  const handlePaymentMethodChange = (methodId: string) => {
    const currentlySelected = tempFilters.paymentMethods.includes(methodId);
    const newPaymentMethods = currentlySelected
      ? tempFilters.paymentMethods.filter((m: string) => m !== methodId)
      : [...tempFilters.paymentMethods, methodId];
    setTempFilters({ ...tempFilters, paymentMethods: newPaymentMethods });
  };

  const resetFilters = () => {
    const emptyFilters = { volume: '', industry: '', features: [], paymentMethods: [] };
    setTempFilters(emptyFilters);
    onFilterChange(emptyFilters);
  };

  const applyFilters = () => {
    onFilterChange(tempFilters);
  };

  useEffect(() => {
    setTempFilters(filters);
  }, [filters]);

  return (
    <Card className="bg-gray-900/50 border-gray-800 backdrop-blur-sm">
      <CardHeader>
        <CardTitle className="flex items-center gap-2 text-white">
          <Filter className="w-5 h-5" />
          Filters
        </CardTitle>
      </CardHeader>
      <CardContent className="space-y-6">
        {/* Volume Filter */}
        <div className="space-y-2">
          <div className="flex items-center gap-2">
            <Label className="text-gray-200">Monthly Volume</Label>
            <span title="Select your approximate monthly transaction volume">
              <Globe className="w-4 h-4 text-gray-400" />
            </span>
          </div>
          <Select 
            value={tempFilters.volume || 'all'} 
            onValueChange={handleVolumeChange}
          >
            <SelectTrigger className="bg-gray-800 border-gray-700 text-gray-200">
              <SelectValue placeholder="Select volume range" />
            </SelectTrigger>
            <SelectContent className="bg-gray-800 border-gray-700">
              <SelectItem value="all" className="text-gray-200">All Volumes</SelectItem>
              {volumeRanges.map((range) => (
                <SelectItem key={range.id} value={range.id} className="text-gray-200">
                  <div className="flex items-center gap-2">
                    <range.icon className="w-4 h-4 text-gray-300" />
                    {range.label}
                  </div>
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

        {/* Industry Filter */}
        <div className="space-y-2">
          <div className="flex items-center gap-2">
            <Label className="text-gray-200">Industry</Label>
            <span title="Choose the industry that best matches your business.">
              <Globe className="w-4 h-4 text-gray-400" />
            </span>
          </div>
          <Select 
            value={tempFilters.industry || 'all'} 
            onValueChange={handleIndustryChange}
          >
            <SelectTrigger className="bg-gray-800 border-gray-700 text-gray-200">
              <SelectValue placeholder="Select industry" />
            </SelectTrigger>
            <SelectContent className="bg-gray-800 border-gray-700">
              <SelectItem value="all" className="text-gray-200">All Industries</SelectItem>
              {industries.map((industry) => (
                <SelectItem key={industry.id} value={industry.id} className="text-gray-200">
                  <div className="flex items-center gap-2">
                    <industry.icon className="w-4 h-4 text-gray-300" />
                    {industry.label}
                  </div>
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

        {/* Features Filter */}
        <div className="space-y-2">
          <div className="flex items-center gap-2">
            <Label className="text-gray-200">Features</Label>
            <span title="Filter providers by specific capabilities and services.">
              <Globe className="w-4 h-4 text-gray-400" />
            </span>
          </div>
          <div className="space-y-2">
            {features.map((feature) => (
              <div key={feature.id} className="flex items-center gap-2">
                <Checkbox
                  id={feature.id}
                  checked={tempFilters.features.includes(feature.id)}
                  onCheckedChange={(checked) => handleFeatureChange(feature.id, !!checked)}
                  className="border-gray-700 data-[state=checked]:bg-blue-600 data-[state=checked]:border-blue-600"
                />
                <Label 
                  htmlFor={feature.id} 
                  className="flex items-center gap-2 cursor-pointer text-gray-200 hover:text-gray-100"
                >
                  <feature.icon className="w-4 h-4 text-gray-300" />
                  {feature.label}
                </Label>
              </div>
            ))}
          </div>
        </div>

        {/* Payment Methods Filter */}
        <div className="space-y-2">
          <div className="flex items-center gap-2">
            <Label className="text-gray-200">Payment Methods</Label>
            <div className="relative" title="Select payment methods you need">
              <Globe className="w-4 h-4 text-gray-400" />
              <span className="sr-only">Payment methods help</span>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-2">
            {paymentMethods.map(({ id, icon: Icon, label }) => {
              const isSelected = tempFilters.paymentMethods.includes(id);
              return (
                <Button
                  key={id}
                  variant="ghost"
                  onClick={() => handlePaymentMethodChange(id)}
                  className={`flex items-center justify-start gap-2 p-2 h-auto w-full
                    ${isSelected 
                      ? 'bg-blue-600/20 text-blue-100 hover:bg-blue-600/30' 
                      : 'bg-gray-800/50 hover:bg-gray-800 text-gray-200'
                    } 
                    border border-gray-800 hover:border-gray-700 transition-all duration-200`}
                >
                  <Icon className={`w-4 h-4 ${isSelected ? 'text-blue-300' : 'text-gray-300'}`} />
                  <span className="text-sm">{label}</span>
                  {isSelected && (
                    <motion.div
                      initial={{ scale: 0 }}
                      animate={{ scale: 1 }}
                      className="w-1.5 h-1.5 rounded-full bg-blue-400 ml-auto"
                    />
                  )}
                </Button>
              );
            })}
          </div>
        </div>

        {/* Filter Buttons */}
        <div className="flex gap-2">
          <Button
            variant="outline"
            onClick={resetFilters}
            className="w-1/2 bg-gray-800 text-white border-gray-700 hover:bg-gray-700"
          >
            Reset
          </Button>
          <Button
            onClick={applyFilters}
            className="w-1/2 bg-blue-600 hover:bg-blue-700 text-white"
          >
            Apply Filters
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};