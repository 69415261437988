import { supabase } from './supabase';
import { PaymentError } from './errors';
import { foloosiPayment } from './foloosi';

interface PaymentRequest {
  amount: number;
  currency: string;
  customerName: string;
  customerEmail: string;
  customerPhone?: string;
}

export async function initializePayment(data: PaymentRequest) {
  try {
    // Create payment intent using Foloosi
    const paymentIntent = await foloosiPayment.createPaymentIntent({
      amount: data.amount,
      currency: data.currency,
      customer_name: data.customerName,
      customer_email: data.customerEmail,
      customer_phone: data.customerPhone,
      redirect_url: `${window.location.origin}/payment/callback`
    });

    // Insert payment record in Supabase
    const { data: paymentData, error } = await supabase
      .from('merchant_payments')
      .insert({
        amount: data.amount,
        currency: data.currency,
        customer_name: data.customerName,
        customer_email: data.customerEmail,
        customer_phone: data.customerPhone,
        status: 'pending',
        reference_token: paymentIntent.reference_token
      })
      .select()
      .single();

    if (error) {
      throw new PaymentError(
        error.message || 'Failed to initialize payment',
        'initialization_failed',
        error
      );
    }

    return paymentData;
  } catch (error) {
    console.error('Payment initialization failed:', error);
    throw error;
  }
}

export async function handlePaymentCallback(params: URLSearchParams) {
  const status = params.get('status');
  const referenceToken = params.get('reference_token');

  if (!status || !referenceToken) {
    throw new Error('Missing status or reference token parameter');
  }

  try {
    // Verify payment with Foloosi
    const verificationResult = await foloosiPayment.verifyPayment(referenceToken);

    if (!verificationResult.success) {
      throw new Error('Payment verification failed');
    }

    // Update payment record in Supabase
    const { data: payment, error: paymentError } = await supabase
      .from('merchant_payments')
      .update({
        status: status,
        payment_date: new Date().toISOString()
      })
      .eq('reference_token', referenceToken)
      .select()
      .single();

    if (paymentError) throw paymentError;

    if (status === 'completed' && payment) {
      // Send welcome email with magic link
      const { error: authError } = await supabase.auth.signInWithOtp({
        email: payment.customer_email,
        options: {
          emailRedirectTo: 'https://pay-souq.com/dashboard',
          data: {
            payment_id: payment.id,
            customer_name: payment.customer_name
          }
        }
      });

      if (authError) throw authError;
    }

    return {
      status: status
    };
  } catch (error) {
    console.error('Payment callback handling failed:', error);
    throw error;
  }
}