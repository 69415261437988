import React from 'react';
import { useForm } from 'react-hook-form';
import { X, Image as ImageIcon, Tag, Save, Loader2, Eye } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Textarea } from '@/components/ui/textarea';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { BlogPost } from '@/store/api/rootApi';
import ReactMarkdown from 'react-markdown';
import { useState, useEffect } from 'react';
import { slugify } from '@/lib/utils';
import { Link } from 'react-router-dom';


interface BlogEditorProps {
  post?: BlogPost;
  onSubmit: (data: Partial<BlogPost>) => Promise<void>;
  onCancel: () => void;
}

type FormData = Omit<BlogPost, 'id' | 'created_at' | 'updated_at' | 'author_id'>;

const CATEGORIES = [
  'Industry News',
  'Tutorials',
  'Case Studies',
  'Product Updates',
  'Company News',
  'Tips & Tricks'
];

export const BlogEditor: React.FC<BlogEditorProps> = ({
  post,
  onSubmit,
  onCancel,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentTag, setCurrentTag] = useState('');
  
  const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm<FormData>({
    defaultValues: post || {
      title: '',
      content: '',
      excerpt: '',
      cover_image: '',
      category: '',
      tags: [],
      status: 'draft',
      slug: ''
    },
  });

  const content = watch('content');
  const tags = watch('tags') || [];
  const title = watch('title');

  useEffect(() => {
    if (!post && title) {
      setValue('slug', slugify(title));
    }
  }, [title, setValue, post]);

  const handleAddTag = () => {
    if (currentTag.trim()) {
      const newTags = [...tags, currentTag.trim()];
      setValue('tags', newTags);
      setCurrentTag('');
    }
  };

  const handleRemoveTag = (indexToRemove: number) => {
    setValue('tags', tags.filter((_, index) => index !== indexToRemove));
  };

  const handleFormSubmit = async (data: FormData) => {
    try {
      setIsSubmitting(true);
      await onSubmit(data);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} className="space-y-6">
      <div className="flex justify-between items-center text-white">
        <h2 className="text-2xl font-bold">
          {post ? 'Edit Blog Post' : 'Create New Blog Post'}
        </h2>
        <div className="flex items-center gap-4">
          <Button 
            type="button" 
            variant="outline" 
            className="bg-[#1F1F1F] text-white border-[#2D2D2D]"
            onClick={onCancel}
          >
            <X className="w-4 h-4 mr-2" />
            Cancel
          </Button>
          {watch('slug') && (
            <Button
              type="button"
              variant="outline"
              className="bg-[#1F1F1F] text-white border-[#2D2D2D]"
              asChild
            >
              <Link to={`/blog/${watch('slug')}`} target="_blank">
                <Eye className="w-4 h-4 mr-2" />
                Preview
              </Link>
            </Button>
          )}
          <Button 
            type="submit" 
            disabled={isSubmitting} 
            className="bg-[#1F1F1F] text-white border-[#2D2D2D]"
          >
            {isSubmitting ? (
              <>
                <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                Saving...
              </>
            ) : (
              <>
                <Save className="w-4 h-4 mr-2" />
                Save
              </>
            )}
          </Button>
        </div>
      </div>

      <div className="grid md:grid-cols-2 gap-6">
        <div className="space-y-6">
          <div>
            <Label className="text-white">Title</Label>
            <Input
              className="bg-[#1F1F1F] border-[#2D2D2D] text-white"
              {...register('title', { required: 'Title is required' })}
              placeholder="Enter post title"
            />
            {errors.title && (
              <p className="text-red-400 text-sm mt-1">{errors.title.message}</p>
            )}
          </div>

          <div>
            <Label className="text-white">URL Slug</Label>
            <div className="flex gap-2">
              <Input
                className="bg-[#1F1F1F] border-[#2D2D2D] text-white font-mono"
                {...register('slug', { 
                  required: 'URL slug is required',
                  pattern: {
                    value: /^[a-z0-9]+(?:-[a-z0-9]+)*$/,
                    message: 'Slug must contain only lowercase letters, numbers, and hyphens'
                  }
                })}
                placeholder="url-friendly-title"
              />
            </div>
            {errors.slug && (
              <p className="text-red-400 text-sm mt-1">{errors.slug.message}</p>
            )}
          </div>

          <div>
            <Label className="text-white">Excerpt</Label>
            <Textarea
              className="bg-[#1F1F1F] border-[#2D2D2D] text-white"
              {...register('excerpt', { required: 'Excerpt is required' })}
              placeholder="Brief description of the post"
            />
            {errors.excerpt && (
              <p className="text-red-400 text-sm mt-1">{errors.excerpt.message}</p>
            )}
          </div>

          <div>
            <Label className="text-white">Cover Image URL</Label>
            <div className="flex gap-2">
              <Input
                className="bg-[#1F1F1F] border-[#2D2D2D] text-white"
                {...register('cover_image', { required: 'Cover image URL is required' })}
                placeholder="https://example.com/image.jpg"
              />
              <Button type="button" variant="outline" className="bg-[#1F1F1F] text-white border-[#2D2D2D]">
                <ImageIcon className="w-4 h-4" />
              </Button>
            </div>
          </div>

          <div>
            <Label className="text-white">Category</Label>
            <Select
              value={watch('category')}
              onValueChange={(value) => setValue('category', value)}
            >
              <SelectTrigger className="bg-[#1F1F1F] border-[#2D2D2D] text-white">
                <SelectValue placeholder="Select category" />
              </SelectTrigger>
              <SelectContent className="bg-[#1F1F1F] border-[#2D2D2D] text-white">
                {CATEGORIES.map((category) => (
                  <SelectItem key={category} value={category}>
                    {category}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          <div>
            <Label className="text-white">Tags</Label>
            <div className="flex gap-2">
              <Input
                className="bg-[#1F1F1F] border-[#2D2D2D] text-white"
                value={currentTag}
                onChange={(e) => setCurrentTag(e.target.value)}
                placeholder="Add tags..."
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    handleAddTag();
                  }
                }}
              />
              <Button type="button" variant="outline" className="bg-[#1F1F1F] text-white border-[#2D2D2D]">
                <Tag className="w-4 h-4" />
              </Button>
            </div>
            <div className="flex flex-wrap gap-2 mt-2">
              {tags.map((tag, index) => (
                <span
                  key={index}
                  className="bg-[#1F1F1F] text-white px-2 py-1 rounded-full text-sm flex items-center gap-1 border border-[#2D2D2D]"
                >
                  {tag}
                  <button
                    type="button"
                    onClick={() => handleRemoveTag(index)}
                    className="hover:text-gray-300"
                  >
                    <X className="w-3 h-3" />
                  </button>
                </span>
              ))}
            </div>
          </div>

          <div>
            <Label className="text-white">Status</Label>
            <Select
              value={watch('status')}
              onValueChange={(value: 'draft' | 'published') => setValue('status', value)}
            >
              <SelectTrigger className="bg-[#1F1F1F] border-[#2D2D2D] text-white">
                <SelectValue placeholder="Select status" />
              </SelectTrigger>
              <SelectContent className="bg-[#1F1F1F] border-[#2D2D2D] text-white">
                <SelectItem value="draft">Draft</SelectItem>
                <SelectItem value="published">Published</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>

        <div className="space-y-6">
          <div>
            <Label className="text-white">Content (Markdown)</Label>
            <Textarea
              className="min-h-[300px] font-mono bg-[#1F1F1F] border-[#2D2D2D] text-white"
              {...register('content', { required: 'Content is required' })}
              placeholder="Write your blog post content in Markdown..."
            />
            {errors.content && (
              <p className="text-red-400 text-sm mt-1">{errors.content.message}</p>
            )}
          </div>

          <Card className="bg-[#1F1F1F] border-[#2D2D2D]">
            <CardHeader>
              <CardTitle className="text-white">Preview</CardTitle>
            </CardHeader>
            <CardContent className="prose prose-invert max-w-none text-white">
              <ReactMarkdown>{content || ''}</ReactMarkdown>
            </CardContent>
          </Card>
        </div>
      </div>
    </form>
  );
};