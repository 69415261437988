export interface Currency {
  code: string;
  symbol: string;
  name: string;
  exchangeRate: number; // Base rate against USD
}

export const currencies: Currency[] = [
  { code: 'USD', symbol: '$', name: 'US Dollar', exchangeRate: 1 },
  { code: 'GBP', symbol: '£', name: 'British Pound', exchangeRate: 0.79 },
  { code: 'EUR', symbol: '€', name: 'Euro', exchangeRate: 0.92 },
  { code: 'AED', symbol: 'د.إ', name: 'UAE Dirham', exchangeRate: 3.67 },
  { code: 'SAR', symbol: '﷼', name: 'Saudi Riyal', exchangeRate: 3.75 },
  { code: 'CNY', symbol: '¥', name: 'Chinese Yuan', exchangeRate: 7.19 }
];

export const formatCurrency = (amount: number, currencyCode: string): string => {
  const currency = currencies.find(c => c.code === currencyCode);
  if (!currency) return `$${amount.toFixed(2)}`;

  const convertedAmount = amount * currency.exchangeRate;
  
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency.code,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(convertedAmount);
};

export const convertAmount = (amount: number, fromCurrency: string, toCurrency: string): number => {
  const from = currencies.find(c => c.code === fromCurrency);
  const to = currencies.find(c => c.code === toCurrency);
  
  if (!from || !to) return amount;
  
  // Convert to USD first, then to target currency
  const amountInUSD = amount / from.exchangeRate;
  return amountInUSD * to.exchangeRate;
};